<template>
    <transition v-if="open" name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="confirm-delete-modal">
                    <div class="modal-header">
                        <slot name="header">
                        </slot>
                    </div>
                    <div class="modal-body">
                        <slot name="body"></slot>
                    </div>
                    <div class="modal-footer">
                        <slot name="footer">
                            <button class="btn btn-default" @click="open = false">
                                Close
                            </button>
                            <button class="btn btn-primary" @click="$emit('confirm')">
                                Confirm
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ConfirmDelete',
    props: {
        value: Boolean,
    },
    computed: {
        open: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
};
</script>

<style>
.confirm-delete-modal {
    margin: 0 auto;
    background-color: white;
    width: 25rem !important;
    height: min-content !important;
}
</style>
