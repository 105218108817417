<template>
    <Proposals
        :condition="condition"
        :proposal-groups="getConditionProposals"
        :all-conditions="allConditions"
        @rejectProposal="(conditionId, proposalId, callback, notifyUser) => $emit('rejectProposal', conditionId, proposalId, callback, notifyUser)"
        @acceptProposal="(conditionId, proposalId, callback, notifyUser) => $emit('acceptProposal', conditionId, proposalId, callback, notifyUser)"
        @createProposal="(conditionId, proposalId, callback, notifyUser) => $emit('createProposal', conditionId, proposalId, callback, notifyUser)"
    />
</template>

<script>
import Proposals from './Proposals';
export default {
    name: 'SingleCondition', components: { Proposals }, props: {
        condition: {
            type: Object,
            required: true,
        },
        allConditions: {
            type: Array,
            required: true,
        },
    },
    computed: {
        getConditionProposals() {
            const changes = {};
            const proposals = this.condition?.text_change_proposals;
            if(proposals?.length) {
                proposals.forEach(proposal => {
                    changes[proposal.language_id] = [ proposal, ...(changes[proposal.language_id] || []) ];
                });
            }
            return changes;
        },
    }
};
</script>

<style scoped>

</style>
