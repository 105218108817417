<template>
    <tr>
        <td>{{ group.id }}</td>
        <td v-if="!editing">{{ group.name }}</td>
        <td v-if="editing">
            <input
                v-model="form.name"
                v-validate="'required'"
                type="text"
                :class="{
                    'form-control': true,
                    'is-invalid': errors.has('name')
                }"
                data-vv-name="name"
            />
        </td>
        <td v-if="!editing">{{ group.description }}</td>
        <td v-if="editing">
            <input
                v-model="form.description"
                v-validate="'required'"
                type="text"
                :class="{
                    'form-control': true,
                    'is-invalid': errors.has('description')
                }"
                data-vv-name="description"
            />
        </td>
        <td v-if="!editing">
            <p v-for="place in group.places" class="mb-0">
                {{ place.name }}
            </p>
        </td>
        <td v-if="editing">
            <app-multiselect
                v-model="group.placeIds"
                :options="places.map(p => p.id)"
                multiple
                :close-on-select="false"
                open-direction="bottom"
                placeholder="Type to search"
                :custom-label="
                    id =>
                        places.some(p => p.id === id)
                            ? `${places.find(p => p.id === id).name} ${
                                places.find(p => p.id === id).company
                                    ? `- ${places.find(p => p.id === id).company.name}`
                                    : ''
                            }`
                            : null
                "
            />
        </td>
        <td>
            <div class="float-right">
                <a v-if="editing" class="pointer" @click="update(group.id)">Update | </a>
                <a v-if="editing" class="pointer" @click="cancelEdit(group)">Cancel</a>
                <a v-if="!editing" class="pointer" @click="edit(group)">Edit | </a>
                <a v-if="!editing" class="pointer" @click="destroy(group.id)">Delete</a>
            </div>
        </td>
    </tr>
</template>

<script>
import appMultiselect from 'vue-multiselect';
import appCondition from '../../fsp_conditions/conditions/FspConditionTableRow';
import axios from 'axios';

export default {
    components: {
        appMultiselect
    },
    props: ['group', 'index', 'places'],
    data() {
        return {
            form: {
                name: '',
                description: ''
            },
            original: {},
            editing: false
        };
    },
    methods: {
        edit(group) {
            this.form = group;
            this.original = Object.assign({}, group);
            this.editing = true;
            this.$validator.resume();
        },
        cancelEdit(group) {
            this.$validator.pause();
            Object.assign(group, this.original);
            this.form = this.original = null;
            this.editing = false;
        },
        update(id) {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    axios
                        .put('/api/superadmin/place/groups/' + id + '/update', this.form)
                        .then(res => {
                            this.$toastr.i(res.data.message);
                            this.$emit('updateGroup', res.data.group);
                            this.editing = false;
                        })
                        .catch(error => {
                            this.$toastr.e(error.response.data.message);
                        });
                }
            });
        },
        destroy(id) {
            if (!window.confirm('Delete?')) {
                return false;
            }

            axios
                .delete('/api/superadmin/place/groups/' + id + '/destroy')
                .then(res => {
                    this.$toastr.i(res.data.message);
                    this.$emit('removeGroup', this.index);
                })
                .catch(error => {
                    this.$toastr.e(error.response.data.message);
                });
        }
    }
};
</script>
