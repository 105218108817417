<template>
    <tr :id="baed.id">
        <td>{{ index + 1 }}</td>
        <td>{{ baed.name }}</td>
        <td v-if="!editing">
            <span v-for="(data, i) in baed.extra_datas" class="badge badge-pill badge-success" :key="i">
                {{ data.name }}
            </span>
        </td>
        <td v-else>
            <v-select
                v-model="form.extra_datas"
                :options="extra_data"
                label="name"
                :close-on-select="false"
                placeholder="- choose -"
                multiple
            >
                <span slot="no-options">Nothing found</span>
            </v-select>
        </td>
        <td>
            <span class="pull-right">
                <a v-if="!editing" href="javascript:void(0)" @click="edit(baed.extra_datas)">Edit</a>
                <a v-else href="javascript:void(0)" @click="update(baed.id)">Update</a>
            </span>
        </td>
    </tr>
</template>

<script>
import axios from 'axios';

export default {
    props: ['baed', 'index'],
    data() {
        return {
            form: {},
            editing: false,
            extra_data: []
        };
    },
    methods: {
        edit(eds) {
            this.form.extra_datas = eds;
            this.fetchExtraData();
            this.editing = true;
        },
        update(id) {
            axios
                .put('/api/superadmin/business-activity/extra-data/update/' + id, this.form)
                .then(res => {
                    this.$toastr.i(res.data.message);
                    this.baed.extra_datas = this.form.extra_datas;
                    this.editing = false;
                })
                .catch(error => console.error(error));
        },
        fetchExtraData() {
            axios
                .get('/api/classifiers/extra-data')
                .then(res => {
                    this.extra_data = res.data;
                })
                .catch(error => console.error(error));
        }
    }
};
</script>
