<template>
    <b-modal
        id="confirm_admin_delete_company_modal"
        ref="confirm_admin_delete_company_modal"
        hide-header
        @ok="deleteConfirmed"
    >
        <div class="text-center font-weight-bold">
            <div>Are you sure?</div>
            <div class="text-danger mt-2">You are trying to delete a company</div>

            <div v-if="company && company.name">
                <b-card class="mt-1 mb-2">
                    <b-card-text>{{ company.name }}</b-card-text>
                </b-card>

                <b-input
                    id="confirmed-name"
                    v-model="confirmedName"
                    required
                    :state="validation"
                    :placeholder="'Please enter name of the company to delete it'"
                />
                <b-form-invalid-feedback :state="validation">
                    Entered name should be the same as the companys which you are trying to delete
                </b-form-invalid-feedback>
            </div>
        </div>

        <template #modal-footer>
            <div class="w-100 d-flex justify-content-end">
                <b-button variant="success" class="mr-2" @click="close">Cancel</b-button>

                <b-button variant="outline-danger" @click="deleteConfirmed">Yes</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import axios from 'axios';

export default {
    props: ['company'],
    data() {
        return {
            confirmedName: ''
        };
    },
    computed: {
        validation() {
            if (this.company && this.company.name && this.confirmedName === this.company.name) {
                return true;
            } else if (!this.confirmedName) {
                return null;
            } else {
                return false;
            }
        }
    },
    methods: {
        deleteConfirmed() {
            if (this.company && this.company.name && this.confirmedName === this.company.name) {
                axios
                    .delete(`/api/superadmin/companies/${this.company.id}`)
                    .then(res => {
                        this.$toastr.i(res.data.message);
                        this.close();
                        this.$router.push({ name: 'companies' });
                    })
                    .catch(error => console.error(error));
            }
        },
        close() {
            this.$bvModal.hide('confirm_admin_delete_company_modal');
            this.confirmedName = '';
        }
    }
};
</script>
