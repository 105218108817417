<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <span class="pull-right">
                    <router-link
                        tag="a"
                        :to="{ name: 'company.invoice.create', params: { company_id: $route.params.company_id } }"
                    >
                        New Invoice
                    </router-link>
                </span>
                <div class="clearfix mb-3"></div>
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th class="pointer" @click="orderBy('created_at')">
                                    Created
                                    <span v-html="order_field == 'created_at' ? active_sort_icon : sort_icon"></span>
                                </th>
                                <th>Description</th>
                                <th class="pointer" @click="orderBy('amount')">
                                    Total
                                    <span v-html="order_field == 'amount' ? active_sort_icon : sort_icon"></span>
                                </th>
                                <th class="pointer" @click="orderBy('currency_id')">
                                    Currency
                                    <span v-html="order_field == 'currency_id' ? active_sort_icon : sort_icon"></span>
                                </th>
                                <th class="pointer" @click="orderBy('paid')">
                                    Paid
                                    <span v-html="order_field == 'paid' ? active_sort_icon : sort_icon"></span>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <app-invoice
                                v-for="(invoice, index) in orderedInvoices"
                                :key="invoice.id"
                                :index="index"
                                :invoice="invoice"
                                @removeInvoice="removeInvoice"
                            >
                            </app-invoice>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Invoice from './InvoiceTableRow';

export default {
    components: {
        appInvoice: Invoice
    },
    data() {
        return {
            order_field: 'created_at',
            order_dir: 'desc',
            sort_icon: '<i class="fa fa-sort"></i>',
        };
    },
    computed: {
        company() {
            return this.$store.getters.getCompany;
        },
        orderedInvoices() {
            return _.orderBy(this.company.invoices, this.order_field, this.order_dir);
        },
        active_sort_icon() {
            if (this.order_dir == 'desc') {
                return '<i class="fa fa-sort-down"></i>';
            } else {
                return '<i class="fa fa-sort-up"></i>';
            }
        }
    },
    methods: {
        orderBy(field) {
            this.order_field = field;
            if (this.order_dir == 'desc') {
                this.order_dir = 'asc';
            } else {
                this.order_dir = 'desc';
            }
        },
        removeInvoice(index) {
            this.company.invoices.splice(index, 1);
        },
    }
};
</script>
