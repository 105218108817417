import axios from 'axios';

const state = {
    place: {}
};

const mutations = {
    SET_PLACE(state, place) {
        state.place = place;
    }
};

const actions = {
    fetchPlace({ commit }, placeId) {
        axios
            .get('/api/superadmin/place/' + placeId + '/show')
            .then(res => {
                commit('SET_PLACE', res.data);
            })
            .catch(error => console.error(error));
    }
};

const getters = {
    getPlace: state => {
        return state.place;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
