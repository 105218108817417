<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-md-3">
                        <select v-model="from_lang" class="form-control" @change="emptyTranslation">
                            <option v-for="(lang, i) in langs" :key="`${i}_${lang}`" :value="lang.code">
                                {{ lang.code | toUpper }}
                            </option>
                        </select>
                    </div>
                    <i class="fa fa-arrow-right mt-2"></i>
                    <div class="col-md-3">
                        <select v-model="to_lang" class="form-control" @change="emptyTranslation">
                            <option v-for="(lang, i) in langs" :key="`${i}_${lang}`" :value="lang.code">
                                {{ lang.code | toUpper }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <input v-model="filter_term" type="text" class="form-control" placeholder="Filter by Name" />
                    </div>
                    <div class="col-md-1">
                        <button v-if="needs_saving" class="btn btn-success" @click="store">Save</button>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="translation-wrap">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Source [Name, Alt Names]</th>
                                    <th>Translation [Name, Alt Names]</th>
                                </tr>
                            </thead>
                            <tbody>
                                <app-table-row
                                    v-for="(ing, index) in filteredIngredientData"
                                    :key="ing.id"
                                    :index="index"
                                    :ing="ing"
                                    :selected_ing_data="selected_ing_data"
                                    :from_lang="from_lang"
                                    :to_lang="to_lang"
                                    @selectIngData="selectIngData"
                                >
                                </app-table-row>
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-12">
                        <h5>Source</h5>
                        <div v-if="source">
                            <div class="row">
                                <div class="col">
                                    <small>Name</small>
                                    <p>{{ source.name[from_lang] | striphtml }}</p>
                                </div>
                                <div class="col">
                                    <small>Alt Names</small>
                                    <p>{{ source.alt_names[from_lang] | striphtml }}</p>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <p class="text-red">- empty or needs translation -</p>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-12">
                        <h5>Translation</h5>
                        <div class="row">
                            <div class="col">
                                <small>Name</small>
                                <input
                                    v-model="form.name"
                                    type="text"
                                    class="form-control-sm form-control"
                                    @change="translationChanged"
                                />
                            </div>
                            <div class="col">
                                <small>Alt Names</small>
                                <input
                                    v-model="form.alt_names"
                                    type="text"
                                    class="form-control-sm form-control"
                                    @change="translationChanged"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appTableRow from './TableRow';
import axios from 'axios';

export default {
    components: {
        appTableRow
    },
    data() {
        return {
            ing_data: [],
            filter_term: '',
            langs: [],
            user_langs: [],
            source: null,
            from_lang: null,
            to_lang: null,
            needs_saving: false,
            form: {},
            selected_ing_data: null,
            selected_ing_data_index: null
        };
    },
    computed: {
        filteredIngredientData() {
            return this.ing_data.filter(el => {
                if (!el.name[this.from_lang]) {
                    return false;
                }
                return el.name[this.from_lang].toLowerCase().match(this.filter_term.toLowerCase());
            });
        }
    },
    created() {
        this.getLanguages();
        this.fetchIngredientData();
    },
    methods: {
        emptyTranslation() {
            this.selected_ing_data = null;
            this.selected_ing_data_index = null;
            this.source = null;
            this.form = {};
            this.needs_saving = false;
            if (!this.user_langs.includes(this.to_lang)) {
                this.needs_saving = false;
            }
        },
        translationChanged() {
            this.needs_saving = true;
        },
        selectIngData(data) {
            this.selected_ing_data = data.id;
            this.selected_ing_data_index = data.index;
            this.source = this.filteredIngredientData[data.index];
            this.form.name = this.filteredIngredientData[data.index].name[this.to_lang];
            this.form.alt_names = this.filteredIngredientData[data.index].alt_names[this.to_lang];
        },
        store() {
            if (!this.selected_ing_data) {
                this.$toastr.e('No ingrediant data selected');
                return false;
            }
            if (!this.form.name) {
                this.$toastr.e('Name is required');
                return false;
            }
            if (!this.form.alt_names) {
                this.$toastr.e('Alt Names is required');
                return false;
            }
            this.prepareForm();
            axios
                .put('/api/superadmin/translate/ingredient-data/' + this.selected_ing_data + '/update', this.form)
                .then(res => {
                    this.$toastr.i(res.data.message);
                    this.filteredIngredientData[this.selected_ing_data_index].name = res.data.ing_data.name;
                    this.filteredIngredientData[this.selected_ing_data_index].alt_names = res.data.ing_data.alt_names;
                })
                .then(() => {
                    this.emptyTranslation();
                })
                .catch(error => {
                    this.$toastr.e(error.response.data.message);
                });
        },
        prepareForm() {
            this.form = {
                name: this.form.name,
                alt_names: this.form.alt_names,
                from_lang: this.from_lang,
                to_lang: this.to_lang
            };
        },
        fetchIngredientData() {
            axios
                .get('/api/superadmin/translate/ingredient-data')
                .then(res => {
                    this.ing_data = res.data.ing_data;
                    this.from_lang = res.data.langs[0];
                    this.to_lang = res.data.langs[0];
                    this.user_langs = res.data.langs;
                })
                .catch(error => console.error(error));
        },
        getLanguages() {
            axios
                .get('/api/superadmin/food-safety-plan-conditions/languages')
                .then(res => {
                    this.langs = res.data;
                })
                .catch(error => console.error(error));
        }
    }
};
</script>
<style scoped>
.translation-wrap {
    max-height: 250px;
    overflow-y: scroll;
}
</style>
