<template>
    <header class="topbar">
        <nav class="navbar top-navbar navbar-expand-md navbar-dark">
            <div class="navbar-header">
                <a class="navbar-brand" href="javascript:void(0)">
                    <b class="hidden-sm-up">
                        <img src="/assets/img/fooddocs_logo_white_icon_40px.png" alt="homepage" class="dark-logo" />
                    </b>
                    <span>
                        <img src="/assets/img/fooddocs_logo_white_26px.png" class="dark-logo" />
                        <img src="/assets/img/fooddocs_logo_white_26px.png" class="light-logo" />
                    </span>
                </a>
            </div>
            <div class="navbar-collapse">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item d-sm-none">
                        <a class="nav-link nav-toggler waves-effect waves-light" href="javascript:void(0)">
                            <i class="ti-menu text-gray"></i>
                        </a>
                    </li>
                    <li class="nav-item dropdown">
                        <a
                            v-tooltip.top-start="'Company Notes Reminders'"
                            class="nav-link dropdown-toggle waves-effect waves-dark"
                            href=""
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i class="icon-note"></i>
                            <div v-if="reminders.length > 0" class="notify">
                                <span class="heartbit"></span> <span class="point"></span>
                            </div>
                        </a>
                        <div class="dropdown-menu mailbox animated bounceInDown">
                            <span class="with-arrow"><span class="bg-success"></span></span>
                            <ul>
                                <li>
                                    <div class="drop-title bg-success text-white">
                                        <h4 class="m-b-0 m-t-5">Todays reminders</h4>
                                    </div>
                                </li>
                                <li>
                                    <div class="message-center">
                                        <div
                                            v-for="(reminder, index) in reminders"
                                            :key="reminder.id"
                                        >
                                            <div class="btn btn-info btn-circle">
                                                <i class="icon-note"></i>
                                            </div>
                                            <div class="mail-contnet">
                                                <h5>{{ reminder.company.name }}</h5>
                                                <span class="mail-desc">
                                                    {{ reminder.notes }}
                                                </span>
                                                <span class="time">Note saved on {{ reminder.note_date | formatDate }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item dropdown">
                        <a
                            v-tooltip.top-start="'Tasks'"
                            class="nav-link dropdown-toggle waves-effect waves-dark"
                            href=""
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i class="icon-list"></i>
                            <div v-if="tasks.length > 0" class="notify">
                                <span class="heartbit"></span> <span class="point"></span>
                            </div>
                        </a>
                        <div class="dropdown-menu mailbox animated bounceInDown">
                            <span class="with-arrow"><span class="bg-success"></span></span>
                            <ul>
                                <li>
                                    <div class="drop-title bg-success text-white">
                                        <h4 class="m-b-0 m-t-5">Todays tasks</h4>
                                    </div>
                                </li>
                                <li>
                                    <div class="message-center">
                                        <div
                                            v-for="(task, index) in tasks"
                                            :key="task.id"
                                        >
                                            <div class="btn btn-info btn-circle">
                                                <i class="icon-note"></i>
                                            </div>
                                            <div class="mail-contnet">
                                                <h5>{{ task.company.name }}</h5>
                                                <span class="mail-desc">
                                                    {{ task.notes }}
                                                </span>
                                                <span class="time">Note saved on {{ task.note_date | formatDate }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <ul class="navbar-nav my-lg-0">
                    <li class="nav-item dropdown">
                        <a
                            v-click-outside="closeUserTip"
                            class="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            @click="showUserTip = !showUserTip"
                        >
                            <img src="/assets/img/user.jpg" alt="user" class="img-circle" width="30" />
                        </a>
                        <div class="dropdown-menu dropdown-menu-right user-dd animated flipInY" :class="{ showUserTip }">
                            <span class="with-arrow"><span class="bg-success"></span></span>
                            <div class="d-flex no-block align-items-center p-15 bg-success text-white m-b-10">
                                <div class="">
                                    <img src="/assets/img/user.jpg" alt="user" class="img-circle" width="60" />
                                </div>
                                <div class="m-l-10">
                                    <h4 class="m-b-0">{{ user.firstname }} {{ user.lastname }}</h4>
                                    <p class=" m-b-0">{{ user.username }}</p>
                                </div>
                            </div>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item pointer" @click="logout">
                                <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
</template>

<script>
import AuthService from '@/services/AuthService';

export default {
    computed: {
        reminders() {
            return this.$store.getters.getReminders;
        },
        tasks() {
            return this.$store.getters.getTasks;
        },
        user() {
            return this.$store.getters.getUser;
        }
    },
    data() {
        return {
            showUserTip: false,
        };
    },
    created() {
        this.$store.dispatch('fetchUser');
    },
    methods: {
        closeUserTip() {
            if(this.showUserTip) {
                this.showUserTip = false;
            }
        },
        logout() {
            AuthService.logout();
        },
    }
};
</script>

<style>
.showUserTip {
    display: block !important;
}
.topbar {
    background: -webkit-linear-gradient(left,#318f94 0,#5ec58c 100%) !important;
    background: linear-gradient(to right,#318f94 0,#5ec58c 100%) !important;
}
</style>