<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <input
                        v-model="filter_ba_pp"
                        type="text"
                        class="form-control mb-3 col-7"
                        placeholder="Filter by Process"
                    />
                    <div class="col-5 mt-2">
                        <span class="pull-right">
                            <router-link tag="a" :to="{ name: 'smartfilter.bapp.create' }">
                                New Process
                            </router-link>
                        </span>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="table-responsive">
                    <table v-if="filteredPPs.length > 0" class="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Process</th>
                                <th>Business Activity</th>
                                <th>Extra data</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <app-prod-process
                                v-for="(bapp, index) in filteredPPs"
                                :key="bapp.id"
                                :index="index"
                                :bapp="bapp"
                                @removeBaProdProcess="removeBaProdProcess"
                            >
                            </app-prod-process>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import ProdProcess from './ProdProcessTableRow';

export default {
    components: {
        appProdProcess: ProdProcess
    },
    data() {
        return {
            prod_processes: [],
            filter_ba_pp: ''
        };
    },
    computed: {
        filteredPPs() {
            return this.prod_processes.filter(el => {
                if (!el.production_process.name) {
                    return false;
                }
                return el.production_process.name.toLowerCase().match(this.filter_ba_pp.toLowerCase());
            });
        }
    },
    created() {
        this.fetchProductionProcesses();
    },
    methods: {
        fetchProductionProcesses() {
            axios
                .get('/api/superadmin/business-activity/production-process')
                .then(res => {
                    this.prod_processes = res.data;
                })
                .catch(error => console.error(error));
        },
        removeBaProdProcess(index) {
            this.prod_processes.splice(index, 1);
        }
    }
};
</script>

<style scoped></style>
