<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <input v-model="filter_ba_ed" type="text" class="form-control mb-3" placeholder="Filter by Activity" />
                <div v-if="filter_ba_ed" class="alert alert-primary">
                    To enable reordering of rows, please clear the filter.
                </div>
                <div class="clearfix"></div>
                <table v-if="filteredEDs.length > 0" class="table table-striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Business Activity</th>
                            <th>Extra Data</th>
                            <th></th>
                        </tr>
                    </thead>
                    <app-draggable v-model="reorderedStructures" v-bind="{ animation: 200, disabled: !!filter_ba_ed }" tag="tbody">
                        <app-baed v-for="(baed, index) in filteredEDs" :key="baed.id" :index="index" :baed="baed"></app-baed>
                    </app-draggable>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import BAED from './ExtraDataTableRow';
import axios from 'axios';
import appDraggable from 'vuedraggable';

export default {
    components: {
        appBaed: BAED,
        appDraggable,
    },
    data() {
        return {
            business_activity_eds: [],
            filter_ba_ed: ''
        };
    },
    computed: {
        reorderedStructures: {
            get() {
                return this.business_activity_eds;
            },
            set(tables) {
                axios.put('/api/superadmin/business-activities/reorder', tables).then(() => {
                    this.$toastr.i('Sections reordered');
                    this.fetchBusinessActivities();
                }).catch(() => {
                    this.$toastr.i('Error, sections were not reordered, please refresh!');
                });
            }
        },
        filteredEDs() {
            return this.business_activity_eds.filter(el => {
                if (!el.name) {
                    return false;
                }
                return el.name.toLowerCase().match(this.filter_ba_ed.toLowerCase());
            });
        }
    },
    created() {
        this.fetchBusinessActivities();
    },
    methods: {
        fetchBusinessActivities() {
            axios
                .get('/api/classifiers/business-activities')
                .then(res => {
                    this.business_activity_eds = res.data;
                })
                .catch(error => console.error(error));
        }
    }
};
</script>
