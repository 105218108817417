const state = {
    loading: 0
};

const mutations = {
    START_LOADER(state) {
        state.loading++;
    },

    STOP_LOADER(state) {
        state.loading--;
        if (state.loading < 0) {
            state.loading = 0;
        }
    }
};

const actions = {
    startLoader({ commit }) {
        commit('START_LOADER');
    },
    stopLoader({ commit }) {
        commit('STOP_LOADER');
    }
};

const getters = {
    isLoading: state => {
        return state.loading > 0;
    }
};

export default {
    state,
    mutations,
    getters,
    actions
};
