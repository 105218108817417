<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <input
                        v-model="filter_term_product_groups"
                        type="text"
                        class="form-control mb-3 col-7"
                        placeholder="Filter by Product Group"
                    />
                </div>
                <div class="clearfix"></div>
                <div class="table-responsive">
                    <table v-if="filteredProductGroups.length > 0" class="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <app-product-group
                                v-for="(productGroup, index) in filteredProductGroups"
                                :key="productGroup.id"
                                :index="index"
                                :product-group="productGroup"
                            >
                            </app-product-group>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import ProductGroup from './ProductGroupTableRow';

export default {
    components: {
        appProductGroup: ProductGroup
    },
    data() {
        return {
            product_groups: [],
            filter_term_product_groups: ''
        };
    },
    computed: {
        filteredProductGroups() {
            return this.product_groups.filter(el => {
                if (!el.name) {
                    return false;
                }
                return el.name.toLowerCase().match(this.filter_term_product_groups.toLowerCase());
            });
        }
    },
    created() {
        this.fetchProductGroup();
    },
    methods: {
        fetchProductGroup() {
            axios
                .get('/api/superadmin/lab-report/product-group')
                .then(res => {
                    this.product_groups = res.data;
                })
                .catch(error => console.error(error));
        }
    }
};
</script>
