import axios from 'axios';

const state = {
    reminders: [],
    tasks: []
};

const mutations = {
    SET_REMINDERS(state, reminders) {
        state.reminders = reminders;
    },
    SET_TASKS(state, tasks) {
        state.tasks = tasks;
    }
};

const actions = {
    fetchUserReminders({ commit }, userId) {
        axios
            .get('/api/superadmin/companies/notes/getReminders/' + userId)
            .then(res => {
                commit('SET_REMINDERS', res.data.reminders);
                commit('SET_TASKS', res.data.tasks);
            })
            .catch(error => console.error(error));
    }
};

const getters = {
    getReminders: state => {
        return state.reminders;
    },
    getTasks: state => {
        return state.tasks;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
