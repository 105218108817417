import VueRouter from 'vue-router';

// import store from '@/store';
// import authService from '@services/auth.service';

import Dashboard from '@/components/Dashboard';
import CompaniesWrapper from '@/components/companies/Wrapper';
import Companies from '@/components/companies/Companies';
import PlaceGroups from '@/components/companies/place_groups/Groups';
import CompanyShow from '@/components/companies/Show';

import CompanyInvoices from '@/components/companies/invoices/Invoices';
import CompanyInvoiceCreate from '@/components/companies/invoices/Create';

import CompanyPlaces from '@/components/companies/places/Places';

import ProductsImport from '@/components/companies/ProductsImport';

import SmartFilter from '@/components/smartfilter/SmartFiler';
import BAProdProcess from '@/components/smartfilter/business_activity/production_process/ProductionProcess';
import BAExtraData from '@/components/smartfilter/business_activity/extra_data/ExtraData';
import BALocationType from '@/components/smartfilter/business_activity/location_type/LocationType';
import BAFoodGroup from '@/components/smartfilter/business_activity/food_group/FoodGroup';
import BAProductGroup from '@/components/smartfilter/business_activity/product_group/ProductGroup';
import SmartFilterBappCreate from '@/components/smartfilter/business_activity/production_process/Create';
import SmartFilterBafgCreate from '@/components/smartfilter/business_activity/food_group/Create';
import SmartFilterBapgCreate from '@/components/smartfilter/business_activity/product_group/Create';

import ProductionProcesses from '@/components/smartfilter/production_process/ProductionProcesses';
import ProductionProcessCreate from '@/components/smartfilter/production_process/Create';
import ProductionProcessCopy from '@/components/smartfilter/production_process/Copy';
import ExtraDatas from '@/components/smartfilter/extra_data/ExtraDatas';
import ExtraDataCreate from '@/components/smartfilter/extra_data/Create';
import ProductGroupsAll from '@/components/smartfilter/product_group/ProductGroups';
import ProductGroupCreate from '@/components/smartfilter/product_group/Create';

import Config from '@/components/config/Config';
import ConfigShow from '@/components/config/config/Config';
import ConfigIngredientDatas from '@/components/config/ingredient_datas/IngredientDatas';
import IngredientDataCreate from '@/components/config/ingredient_datas/Create';
import IngredientDataEdit from '@/components/config/ingredient_datas/Edit';

import PageManagement from '@/components/page_management/PageManagement';
import Changelogs from '@/components/page_management/changelog/Changelogs';
import ChangelogsCreate from '@/components/page_management/changelog/Create';
import ChangelogsEdit from '@/components/page_management/changelog/Edit';

import Users from '@/components/users/Users';

import FspConditions from '@/components/fsp_conditions/FspConditions';
import FspConditionsCheckFSP from '@/components/fsp_conditions/conditions/FspConditionsCheckFSP';
import FspConditionsList from '@/components/fsp_conditions/conditions/FspConditions';
import FspConditionsEdit from '@/components/fsp_conditions/conditions/Edit';
import FspConditionsFlowChartDefaults from '@/components/fsp_conditions/flow_chart_processes/FspConditionsFlowChartDefaults';
import EditFlowChartProcess from '@/components/fsp_conditions/flow_chart_processes/EditFlowChartProcess';

import FspConditionsStucture from '@/components/fsp_conditions/structure/FspConditionsStructure';
import FspConditionsStuctureEdit from '@/components/fsp_conditions/structure/Edit';
import FspConditionsStuctureRowEdit from '@/components/fsp_conditions/structure/row/Edit';
import FspConditionsStuctureColumnEdit from '@/components/fsp_conditions/structure/column/Edit';

import FspStuctureGroups from '@/components/fsp_conditions/structure_groups/FspStructureGroups';
import FspStuctureGroupsEdit from '@/components/fsp_conditions/structure_groups/Edit';

import LabAnalysis from '@/components/lab_analysis/LabAnalysis';
import ProductGroups from '@/components/lab_analysis/product-groups/ProductGroup';
import ProductGroupsCreate from '@/components/lab_analysis/product-groups/Create';
import ProductGroupsEdit from '@/components/lab_analysis/product-groups/Edit';

import PlaceShow from '@/components/companies/places/Show';

import Translate from '@/components/translations/Translations';
import TransConditions from '@/components/translations/conditions/Conditions';
import TransIngredientData from '@/components/translations/ingredient_data/IngredientData';

import CompanyModule from '@/components/companies/subscription/Table';
import CompanySubscriptionEditor from '@/components/companies/subscription/Create';

import Login from '@/components/Login';
import AuthGuard from '@/guards/AuthGuard';

// Will catch all useless error messages from VueRouter
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject); }
    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            // resolve err
            return err;
        }
        // rethrow error
        return Promise.reject(err);
    });
};

const routes = [
    {
        path: '/login',
        component: Login,
        name: 'login',
    },
    {
        path: '/',
        component: Dashboard,
        name: 'dashboard',
    },
    {
        path: '/companies',
        component: CompaniesWrapper,
        children: [
            { path: '', component: Companies, name: 'companies' },
            { path: 'place-groups', component: PlaceGroups, name: 'place.groups' }
        ]
    },
    {
        path: '/company/:company_id',
        component: CompanyShow,
        children: [
            {
                path: 'invoices',
                component: CompanyInvoices,
                name: 'company.invoices'
            },
            {
                path: 'invoices/create',
                component: CompanyInvoiceCreate,
                name: 'company.invoice.create'
            },
            {  
                path: 'places',
                component: CompanyPlaces,
                name: 'company.places'
            },
            { 
                path: 'module',
                component: CompanyModule,
                name: 'company.subscription'
            },
            {
                path: 'module/create',
                component: CompanySubscriptionEditor,
                name: 'company.subscription.create'
            },
            {
                path: 'module/:subscription_id/edit',
                component: CompanySubscriptionEditor,
                name: 'company.subscription.edit'
            },
            {
                path: 'products/import',
                component: ProductsImport,
                name: 'company.products.import'
            }
        ]
    },
    {
        path: '/place/:place_id',
        component: PlaceShow,
        children: [
            {
                path: 'products/import',
                component: ProductsImport,
                name: 'place.products.import'
            },
        ],
    },
    {
        path: '/translate',
        component: Translate,
        children: [
            {
                path: 'conditions',
                component: TransConditions,
                name: 'trans.conditions'
            },
            {
                path: 'ingredient-data',
                component: TransIngredientData,
                name: 'trans.ingredient.data'
            },
        ]
    },
    {
        path: '/smart-filtering',
        component: SmartFilter,
        children: [
            {
                path: 'business-activity/production-processes',
                component: BAProdProcess,
                name: 'smartfilter.ba.pp'
            },
            {
                path: 'business-activity/production-process/create',
                component: SmartFilterBappCreate,
                name: 'smartfilter.bapp.create'
            },
            {
                path: 'business-activity/food-group/create',
                component: SmartFilterBafgCreate,
                name: 'smartfilter.bafg.create'
            },
            {
                path: 'business-activity/product-group/create',
                component: SmartFilterBapgCreate,
                name: 'smartfilter.bapg.create'
            },
            {
                path: 'business-activity/extra-data',
                component: BAExtraData,
                name: 'smartfilter.ba.ed'
            },
            {
                path: 'business-activity/location-type',
                component: BALocationType,
                name: 'smartfilter.ba.lt'
            },
            {
                path: 'business-activity/food-group',
                component: BAFoodGroup,
                name: 'smartfilter.ba.fg'
            },
            {
                path: 'business-activity/product-group',
                component: BAProductGroup,
                name: 'smartfilter.ba.pg'
            },
            { path: 'extra-datas', component: ExtraDatas, name: 'smartfilter.ed' },
            {
                path: 'extra-data/create',
                component: ExtraDataCreate,
                name: 'smartfilter.ed.create'
            },
            {
                path: 'production-processes',
                component: ProductionProcesses,
                name: 'smartfilter.pp'
            },
            {
                path: 'production-process/create',
                component: ProductionProcessCreate,
                name: 'smartfilter.pp.create'
            },
            {
                path: 'production-process/:id/copy',
                component: ProductionProcessCopy,
                name: 'smartfilter.pp.copy'
            },
            {
                path: 'product-group',
                component: ProductGroupsAll,
                name: 'smartfilter.pg'
            },
            {
                path: 'product-group/create',
                component: ProductGroupCreate,
                name: 'smartfilter.pg.create'
            }
        ]
    },
    {
        path: '/food-safety-plan-conditions',
        component: FspConditions,
        children: [
            { path: '', component: FspConditionsList, name: 'fsp_conditions' },
            {
                path: 'check-fsp',
                component: FspConditionsCheckFSP,
                name: 'fsp_conditions.check.fsp'
            },
            {
                path: 'flow-chart-defaults',
                component: FspConditionsFlowChartDefaults,
                name: 'fsp_conditions.flow.chart.defaults'
            },
            {
                path: 'flow-chart-defaults-edit',
                component: EditFlowChartProcess,
                name: 'fsp_conditions.flow.chart.defaults.edit'
            },
            {
                path: 'create',
                component: FspConditionsEdit,
                name: 'fsp_conditions.create'
            },
            {
                path: ':fsp_condition_id/edit',
                component: FspConditionsEdit,
                name: 'fsp_conditions.edit'
            },
            {
                path: ':fsp_condition_id/show',
                component: FspConditionsEdit,
                name: 'fsp_conditions.show'
            },
            {
                path: 'structure',
                component: FspConditionsStucture,
                name: 'fsp_cond.structure'
            },
            {
                path: 'structure/create',
                component: FspConditionsStuctureEdit,
                name: 'fsp_cond.structure.create'
            },
            {
                path: 'structure/:fsp_cond_structure_id/edit',
                component: FspConditionsStuctureEdit,
                name: 'fsp_cond.structure.edit'
            },
            {
                path: 'structure/row/:fsp_cond_structure_id/create',
                component: FspConditionsStuctureRowEdit,
                name: 'fsp_cond.structure.row.create'
            },
            {
                path: 'structure/row/:fsp_cond_structure_row_id/edit',
                component: FspConditionsStuctureRowEdit,
                name: 'fsp_cond.structure.row.edit'
            },
            {
                path: 'structure/column/:fsp_cond_structure_row_id/create',
                component: FspConditionsStuctureColumnEdit,
                name: 'fsp_cond.structure.column.create'
            },
            {
                path: 'structure/column/:fsp_cond_structure_column_id/edit',
                component: FspConditionsStuctureColumnEdit,
                name: 'fsp_cond.structure.column.edit'
            },
            {
                path: 'structure/group',
                component: FspStuctureGroups,
                name: 'structure.groups'
            },
            {
                path: 'structure/group/create',
                component: FspStuctureGroupsEdit,
                name: 'structure.groups.create'
            },
            {
                path: 'structure/group/:structure_group_id/edit',
                component: FspStuctureGroupsEdit,
                name: 'structure.groups.edit'
            }
        ]
    },
    {
        path: '/config',
        component: Config,
        children: [
            {
                path: '/',
                component: ConfigShow,
                name: 'config.config'
            },
            {
                path: '/config/ingredient-datas',
                component: ConfigIngredientDatas,
                name: 'config.ingred.datas'
            },
            {
                path: '/config/ingredient-data/create',
                component: IngredientDataCreate,
                name: 'config.ingred.data.create'
            },
            {
                path: '/config/ingredient-data/:ing_id/edit',
                component: IngredientDataEdit,
                name: 'config.ingred.data.edit'
            }
        ]
    },
    { path: '/users', component: Users, name: 'users' },
    {
        path: '/page-management',
        component: PageManagement,
        children: [

            { path: 'changelog', component: Changelogs, name: 'pm.changelog' },
            {
                path: 'changelog/create',
                component: ChangelogsCreate,
                name: 'pm.changelog.create'
            },
            {
                path: 'changelog/:changelog_id/edit',
                component: ChangelogsEdit,
                name: 'pm.changelog.edit'
            }
        ]
    },
    {
        path: '/lab-analysis',
        component: LabAnalysis,
        children: [
            {
                path: 'product-groups',
                component: ProductGroups,
                name: 'product-groups'
            },
            {
                path: 'product-groups/create',
                component: ProductGroupsCreate,
                name: 'product-groups.create'
            },
            {
                path: 'product-groups/:product_groups/edit',
                component: ProductGroupsEdit,
                name: 'product-groups.edit'
            }
        ]
    },
    { path: '*', redirect: '/' }
];

const router = new VueRouter({
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes,
    linkActiveClass: 'active',
});

router.beforeEach((to, from, next) => {
    AuthGuard(to, from, next);
});

export default router;