<template>
    <vue-tags-input v-model="tag" :disabled="disabled" :tags="displayTags" placeholder="Add legislation" :max-tags="1" :autocomplete-items="filteredLegislations" @tags-changed="newTags => tagsChanged(newTags)">
    </vue-tags-input>
</template>

<script>
export default {
    name: 'SelectLegislation',
    props: {
        legislationOptions: {
            type: Array,
            required: true
        },
        legislation: {
            type: Object,
            required: true
        },
        disabled: Boolean
    },
    data() {
        return {
            tag: ''
        };
    },
    computed: {
        filteredLegislations() {
            return this.legislationOptions.filter(leg => {
                return leg.text.toLowerCase().indexOf(this.tag.toLocaleLowerCase()) !== -1;
            });
        },
        displayTags() {
            if(this.legislation.name){
                return [{...this.legislation, text: this.legislation && this.legislation.name ? this.legislation.name : null}];
            }
            return [];
        }
    },
    methods: {
        tagsChanged(newTags) {
            const newTag = newTags[0];
            this.legislation.id = newTag && newTag.id ? newTag.id : null;
            this.legislation.name = newTag && newTag.text ? newTag.text : null;
            this.legislation.text = newTag && newTag.text ? newTag.text : null;
        }
    }
};
</script>

<style scoped>

</style>
