<template>
    <tr>
        <td>{{ index + 1 }}</td>
        <td>{{ group.static_name }}</td>
        <td>
            <span class="pull-right">
                <router-link :to="{ name: 'structure.groups.edit', params: { structure_group_id: group.id } }" tag="a">
                    Edit
                </router-link>
                |
                <a href="javascript:void(0)" @click="destroyGroup(group.id)">Delete</a>
            </span>
        </td>
    </tr>
</template>

<script>
import axios from 'axios';

export default {
    props: ['group', 'index'],
    methods: {
        destroyGroup(id) {
            if (!window.confirm('Delete group?')) {
                return false;
            }

            axios
                .delete('/api/superadmin/fsp-table-groups/' + id)
                .then(() => {
                    this.$toastr.i(`Table group ${id} deleted!`);
                    this.$emit('removeGroup', this.index);
                })
                .catch(error => {
                    this.$toastr.e(error.response.data.message);
                });
        }
    }
};
</script>
