<template>
    <div>
        <div class="row page-titles hidden-sm-up">
            <div class="col-md-5 align-self-center">
                <h4 class="text-themecolor">Edit Product - {{ productGroup.name }}</h4>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title hidden-xs-down">
                    Edit Product Groups - {{ productGroup.name }}
                    <button type="button" class="btn btn-success" @click="update">
                        Save
                    </button>
                </h4>

                <div class="row">
                    <div class="col">
                        <label>MicroOrganisms</label>
                        <v-select
                            v-model="microOrganismSelected"
                            :options="microOrganisms"
                            label="name"
                            placeholder="- choose -"
                        >
                            <span slot="no-options">Nothing found</span>
                        </v-select>
                    </div>
                    <div class="col-md-1 pt-4">
                        <button type="button" class="btn btn-success" @click="addMicroOrganism">
                            Add
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div v-for="(item, index) in microOrganismsSelectedArr" :key="index" class="col-md-6 mt-4">
                        <div class="border p-4">
                            <div>
                                <label class="font-weight-bold">{{ item.micro_organism.name }}</label>
                                <span class="btn d-inline" @click="removeSelectedMicoOrganism(index)"><i class="icon-close float-right font-weight-bold"></i></span>
                            </div>
                            <div class="row m-0 w-100">
                                <div class="col p-0">
                                    <div class="form-group">
                                        <label>Frequency</label>
                                        <v-select
                                            v-model="item.frequency"
                                            :options="frequencyOptions"
                                            label="name"
                                            placeholder="- choose -"
                                        >
                                            <span slot="no-options">Nothing found</span>
                                        </v-select>
                                        <input type="hidden" class="form-control is-invalid" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Minimum allowed limit</label>
                                        <input
                                            :id="'minAllowedLimit' + index"
                                            v-model="item.min_limit"
                                            v-validate="'required'"
                                            type="text"
                                            :data-vv-name="'minAllowedLimit' + index"
                                            :data-vv-as="'minAllowedLimit' + index"
                                            :class="{
                                                'form-control': true,
                                                'is-invalid': errors.has('minAllowedLimit' + index)
                                            }"
                                        />
                                        <span
                                            v-show="errors.has('minAllowedLimit' + index)"
                                            class="invalid-feedback"
                                            role="alert"
                                        >
                                            <strong>The Minimum allowed limit field is required.</strong>
                                        </span>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Acceptable limit</label>
                                        <input
                                            :id="'acceptableAllowedLimit' + index"
                                            v-model="item.acceptable_limit"
                                            v-validate="'required'"
                                            type="text"
                                            :data-vv-name="'acceptableAllowedLimit' + index"
                                            :data-vv-as="'acceptableAllowedLimit' + index"
                                            :class="{
                                                'form-control': true,
                                                'is-invalid': errors.has('acceptableAllowedLimit' + index)
                                            }"
                                        />
                                        <span
                                            v-show="errors.has('minAllowedLimit' + index)"
                                            class="invalid-feedback"
                                            role="alert"
                                        >
                                            <strong>The Acceptable limit field is required.</strong>
                                        </span>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Critical limit</label>
                                        <input
                                            :id="'criticalAllowedLimit' + index"
                                            v-model="item.max_limit"
                                            v-validate="'required'"
                                            type="text"
                                            :data-vv-name="'criticalAllowedLimit' + index"
                                            :data-vv-as="'criticalAllowedLimit' + index"
                                            :class="{
                                                'form-control': true,
                                                'is-invalid': errors.has('criticalAllowedLimit' + index)
                                            }"
                                        />
                                        <span
                                            v-show="errors.has('criticalAllowedLimit' + index)"
                                            class="invalid-feedback"
                                            role="alert"
                                        >
                                            <strong>The Critical limit field is required.</strong>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Correcting Action</label>
                                        <v-select
                                            v-model="item.correcting_action"
                                            :options="correctingActionOptions"
                                            label="name"
                                            multiple
                                            placeholder="- choose -"
                                        >
                                            <span slot="no-options">Nothing found</span>
                                        </v-select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Minimum production amount</label>
                                        <input
                                            id="minProductionAmount"
                                            v-model="item.min_amount"
                                            type="text"
                                            data-vv-name="minProductionAmount"
                                            data-vv-as="minProductionAmount"
                                            class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Minimum Production Unit</label>
                                        <v-select
                                            v-model="item.min_unit_id"
                                            :options="productionUnit"
                                            label="name"
                                            placeholder="- choose -"
                                        >
                                            <span slot="no-options">Nothing found</span>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Minimum Time Unit</label>
                                        <v-select
                                            v-model="item.min_time_unit_id"
                                            :options="timeUnits"
                                            label="name"
                                            placeholder="- choose -"
                                        >
                                            <span slot="no-options">Nothing found</span>
                                        </v-select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Maximum production amount</label>
                                        <input
                                            id="maxProductionAmount"
                                            v-model="item.max_amount"
                                            type="text"
                                            data-vv-name="maxProductionAmount"
                                            data-vv-as="maxProductionAmount"
                                            class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Maximum Production Unit</label>
                                        <v-select
                                            v-model="item.max_unit_id"
                                            :options="productionUnit"
                                            label="name"
                                            placeholder="- choose -"
                                        >
                                            <span slot="no-options">Nothing found</span>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Maximum Time Unit</label>
                                        <v-select
                                            v-model="item.max_time_unit_id"
                                            :options="timeUnits"
                                            label="name"
                                            placeholder="- choose -"
                                        >
                                            <span slot="no-options">Nothing found</span>
                                        </v-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            microOrganisms: [],
            microOrganismSelected: {},
            microOrganismsSelectedArr: [],
            productionUnit: [],
            productGroup: {},
            frequencyOptions: ['every batch', 'daily', 'weekly', 'quarterly', 'yearly'],
            correctingActionOptions: [
                'Improving production hygiene',
                'Changing raw material',
                'Shorten the shelf-life of the product'
            ],
            deletedMicroOrganismsArr: [],
            timeUnits: []
        };
    },
    created() {
        this.getMicroOrganisms();
        this.getTimeUnits();
    },
    methods: {
        getTimeUnits() {
            axios
                .get('/api/classifiers/time-units')
                .then(res => {
                    this.timeUnits = res.data;
                })
                .catch(error => console.error(error));
        },
        getMicroOrganisms() {
            axios
                .get('/api/superadmin/lab-report/micro-organisms/' + this.$route.params.product_groups)
                .then(res => {
                    this.microOrganisms = res.data.microorganism;
                    this.productionUnit = res.data.productionUnit;
                    this.productGroup = res.data.productGroup;
                    let arr = [];
                    for (const item of res.data.microOrganismsSelectedArr) {
                        let temp = item;
                        item.correcting_action = temp.correcting_action.split(',');
                        arr.push(temp);
                    }
                    this.microOrganismsSelectedArr = arr;
                })
                .catch(error => console.error(error));
        },
        addMicroOrganism() {
            if (!this.microOrganismSelected.id) {
                return;
            }
            this.microOrganismsSelectedArr.push({
                micro_organism: this.microOrganismSelected,
                frequency: 'every batch',
                correcting_action: ['Improving production hygiene']
            });
            this.microOrganismSelected = {};
        },
        removeSelectedMicoOrganism(index) {
            if (this.microOrganismsSelectedArr[index].id) {
                this.deletedMicroOrganismsArr.push(this.microOrganismsSelectedArr[index].id);
            }
            this.microOrganismsSelectedArr.splice(index, 1);
        },
        update() {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    this.form = {
                        microorganisms: this.microOrganismsSelectedArr,
                        deletedMicroOrganisms: this.deletedMicroOrganismsArr
                    };
                    axios
                        .put(
                            '/api/superadmin/lab-report/product-group/' + this.$route.params.product_groups + '/update',
                            this.form
                        )
                        .then(res => {
                            if (res.status == 200) {
                                this.$toastr.i('Product group updated');
                                this.$router.push({ name: 'product-groups' });
                            }
                        })
                        .catch(error => console.error(error));
                }
            });
        }
    }
};
</script>
