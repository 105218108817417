import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import router from './routes.js';
import store from './store';
import axios from 'axios';
import VTooltip from 'v-tooltip';
import { filters } from '@/filters';
import VeeValidate from 'vee-validate';
import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.min';
import Toastr from 'vue-toastr';
import ToggleButton from 'vue-js-toggle-button';
import Multiselect from 'vue-multiselect';
import VueBase64FileUpload from 'vue-base64-file-upload';
import Spinner from 'vue-simple-spinner';
import Datepicker from 'vuejs-datepicker';
import VueTagsInput from '@johmun/vue-tags-input';
import vSelect from 'vue-select';
import clickOutsideDirective from '@/directives/click-outside.js';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(Toastr);
Vue.use(VueRouter);
Vue.use(VTooltip);
Vue.use(ToggleButton);
Vue.use(VeeValidate, { fieldsBagName: 'formFields' });

let toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ['link', 'image', 'video']
];
Vue.use(VueQuillEditor, {
    modules: {
        toolbar: {
            container: toolbarOptions
        }
    }
});

if (process.env.API_URL) {
    //added this to connect to remote backend
    axios.defaults.baseURL = process.env.API_URL;
} else {
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
}

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        const status = error?.response?.status;
        if (status && (status === 401 || status === 429)) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('expires_in');
            window.location.replace('/');
        }
        return new Promise((resolve, reject) => {
            reject(error);
        });
    }
);

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');

Vue.component('quill-custom-tags', require('./components/common/QuillCustomTags').default);
Vue.component('v-select', vSelect);
Vue.component('datepicker', Datepicker);
Vue.component('app-loader', Spinner);
Vue.component('vue-tags-input', VueTagsInput);
Vue.component('vue-base64-file-upload', VueBase64FileUpload);
Vue.component('vue-multiselect', Multiselect);
Vue.directive('click-outside', clickOutsideDirective);
Vue.directive('focus', {
    inserted: function(el, binding, vNode) {
        // @ts-ignore
        if (vNode && vNode.componentInstance && !vNode.componentInstance.val) {
            el.getElementsByTagName('input')[binding.value || 0].focus();
        }
    }
});

export let vm = new Vue({
    router,
    filters,
    store,
    render: h => h(App),
}).$mount('#app');
