<template>
    <tr :id="'invoice_' + invoice.id">
        <td>{{ invoice.id }}</td>
        <td>{{ invoice.created_at | formatDate }}</td>
        <td>
            <span v-for="(invoiceRow, index) in invoice.description">{{ invoiceRow.quantity }}x{{ invoiceRow.description }}
                <span v-if="index < invoice.description.length - 1">; </span></span>
        </td>
        <td>{{ invoice.amount }}</td>
        <td>{{ getCurrentCurrency }}</td>
        <td>{{ invoice.paid ? 'Yes' : 'No' }}</td>
        <td>
            <span class="pull-right">
                <template v-if="!invoice.paid">
                    <a @click="markPaid(invoice.id)">Mark paid</a>
                    |
                </template>
                <a @click="download(invoice.id)">Download</a>
                <template v-if="!invoice.paid">
                    |
                    <a @click="destroy(invoice.id)">Delete</a>
                </template>
            </span>
        </td>
    </tr>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        invoice: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        currencies() {
            return this.$store.getters.getCurrencies;
        },
        getCurrentCurrency() {
            if(!this.currencies.length) {return '-';}
            const currency = this.currencies.filter(c => c.id === this.invoice.currency_id);
            return  currency.length ? currency[0].iso3 : '-';
        },
    },
    methods: {
        download(invoiceId) {
            axios.get(`/api/superadmin/companies/${this.$route.params.company_id}/invoices/${invoiceId}/pdf`, {
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `invoice ${invoiceId}.pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        },
        markPaid(id) {
            if (!window.confirm(`Mark Invoice ${id} paid?`)) {
                return false;
            }
            axios
                .put(`/api/superadmin/companies/${this.$route.params.company_id}/invoices/${id}/mark-paid`, {})
                .then(res => {
                    this.$store.dispatch('fetchCompany', this.$route.params.company_id);
                    this.$toastr.i(res.data.message);
                    this.invoice.paid = true;
                })
                .catch(error => console.error(error));
        },
        destroy(id) {
            if (!window.confirm('Delete?')) {
                return false;
            }

            axios
                .delete(`/api/superadmin/companies/${this.$route.params.company_id}/invoices/${id}/destroy`)
                .then(res => {
                    this.$toastr.i(res.data.message);
                    this.$emit('removeInvoice', this.index);
                })
                .catch(error => {
                    this.$toastr.e(error.response.data.message);
                });
        }
    }
};
</script>
