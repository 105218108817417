<template>
    <transition v-if="open" name="modal">
        <div class="modal-mask">
            <div class="modal-container">
                <div class="modal-wrapper">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-3 offset-9">
                                <button class="btn btn-primary" @click="close">
                                    close
                                </button>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12 text-center">
                                Change password for <strong>{{ value.username }}</strong>:
                            </div>
                            <div class="col-12 text-center">
                                <input v-model="password" class="form-control password-input" type="text">
                                <div class="d-flex">
                                    <button :disabled="password.length < 4" class="btn btn-primary mx-auto mt-2" @click="save">
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from 'axios';

export default {
    name: 'UserPasswordModal',
    props: {
        value: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            password: '',
        };
    },
    computed: {
        open: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(id) {
                this.getPermissions(id);
            },
        },
    },
    methods: {
        async save() {
            if(!this.password) {
                return;
            }

            const payload = {
                password: this.password,
            };

            try {
                await axios.put(`/api/superadmin/users/${this.value.id}/set-password`, payload).then(() => {
                    this.$toastr.i(`Password changed`);
                });
            } catch (error) {
                console.error(error);
                this.$toastr.e(error.response.data.message);
            }

            this.close();
        },
        close() {
            this.$emit('input', null);
            this.password = '';
            this.open = false;
        },
    },
};
</script>

<style scoped>
.modal-container {
    margin: 0 auto;
    background-color: white;
    width: fit-content !important;
    height: fit-content !important;
    overflow: auto;
}

.password-input {
    width: 16rem;
}
</style>
