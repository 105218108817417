<template>
    <div>
        <div v-for="(proposals, langKey) in showProposalGroups" class="row proposal-text">
            <div class="col-12">
                <hr>
                <span class="font-weight-bold">{{ getLanguageName(langKey) }}</span>
            </div>
            <div class="col-6 mt-4">
                {{ langKey }}
                <span v-html="newTranslations[langKey] || getConditionName(langKey)"></span>
                <Images :files="getConditionImages" />
            </div>
            <div class="col-6">
                <div class="row">
                    <div v-for="(proposal, translationIndex) in proposals" class="col-12">
                        <hr v-if="translationIndex !== 0">
                        <span class="text-muted font-weight-bold">{{ proposal.place.company.name }}({{ proposal.place.company.id }}) / {{ proposal.place.name }}({{ proposal.place.id }}) [{{ getCountryName(proposal.place.country_id) }}]</span>
                        <span v-html="proposal.highlighted_changed_text"></span>
                        <div class="text-right text-dark">Updated at: {{ proposal.updated_at }}</div>
                        <div class="text-right text-dark">Notify user? <input v-model="notifyUser[`${langKey}-${translationIndex}`]" type="checkbox"> </div>
                        <div class="text-right">
                            <button class="btn btn-danger" @click="proposalAction('rejectProposal', condition.id, proposal, langKey, notifyUser[`${langKey}-${translationIndex}`])">Reject</button>
                            <button class="btn btn-success mr-2" @click="proposalAction('acceptProposal', condition.id, proposal, langKey, notifyUser[`${langKey}-${translationIndex}`])">Approve</button>
                            <button class="btn btn-warning mr-2" @click="proposalAction('createProposal', condition.id, proposal, langKey, notifyUser[`${langKey}-${translationIndex}`])">Create New</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Images from '../Images';
import { mapGetters } from 'vuex';

export default {
    name: 'Proposals',
    components: {
        Images,
    },
    props: {
        proposalGroups: {
            type: Object,
            required: true,
        },
        condition: {
            type: Object,
            required: true,
        },
        allConditions: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            languages: 'getLanguages',
            countries: 'getCountries',
        }),
        showProposalGroups() {
            const proposals = {};
            Object.entries(this.proposalGroups).forEach(([key, value]) => {
                proposals[key] = value.filter((proposal) => !this.removedProposals.includes(proposal.id));
                if(!proposals[key]?.length) {
                    delete proposals[key];
                }
            });
            return proposals;
        },
        getConditionImages() {
            const condition = this.allConditions.filter(con => con.id === this.condition.id);
            return condition && condition.length ?  condition[0].file_list : [];
        },
    },
    data() {
        return {
            notifyUser: {},
            removedProposals: [],
            newTranslations: {},
        };
    },
    methods: {
        getCountryName(countryId) {
            return this.countries.find((country) => parseInt(country.id, 0) === parseInt(countryId))?.name;
        },
        getLanguageName(langId) {
            return this.languages.find((lang) => parseInt(lang.id, 0) === parseInt(langId, 0))?.name;
        },
        proposalAction(action, conditionId, proposal, langKey, notifyUser) {
            if(typeof this[action] !== 'undefined') {
                this[action](conditionId, proposal, langKey);
            }
            this.$emit(action, conditionId, proposal.id, () => {
                this.removedProposals.push(proposal.id);
            }, notifyUser || false);
        },
        acceptProposal(conditionId, proposal, langKey) {
            this.newTranslations[langKey] = proposal.changed_text;
        },
        getConditionName(langId) {
            const condition = this.allConditions.filter(con => con.id === this.condition.id);
            return condition && condition.length ?  this.decipherAPIString(condition[0], langId): 'translation not found';
        },
        decipherAPIString(string, langId) {
            try {
                return string.section_translations.filter(s => s.language_id === parseInt(langId, 0))[0].translation_text;
            } catch (e) {
                try {
                    return string.section_translations.filter(s => s.language_id === 43)[0].translation_text;
                } catch (e) {
                    try {
                        return string.static_name;
                    } catch (e) {
                        return 'translation not found';
                    }
                }
            }
        },
    },
};
</script>

<style scoped>
.proposal-text {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    font-family: 'Rubik', sans-serif !important;
}
</style>
