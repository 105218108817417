<template>
    <tr>
        <td>{{ index + 1 }}</td>
        <td>{{ ingredient.name.en }}</td>
        <td>
            <toggle-button
                :value="ingredient.is_allergen == 1 ? true : false"
                color="#5ec58c"
                :labels="{ checked: 'yes', unchecked: 'no' }"
                @change="toggleIsAllergen(ingredient.id)"
            />
        </td>
        <td>{{ ingredient.code }}</td>
        <td v-if="ingredient.alt_names !== null">
            {{ ingredient.alt_names.et | truncate(15) }}
        </td>
        <td v-else>-</td>
        <td v-tooltip.top-start="ingredient.food_group">
            {{ ingredient.food_group | truncate(15) }}
        </td>
        <td>{{ ingredient.energy_kj | round(2) }}</td>
        <td>{{ ingredient.energy_kcal | round(2) }}</td>
        <td>{{ ingredient.carbs | round(2) }}</td>
        <td>{{ ingredient.fat | round(2) }}</td>
        <td>{{ ingredient.protein | round(2) }}</td>
        <td>{{ ingredient.alcohol | round(2) }}</td>
        <td>
            <span class="pull-right">
                <a href="javascript:void(0)" @click="edit()">Edit</a> |
                <a href="javascript:void(0)" @click="destroy(ingredient.id)">Delete</a>
            </span>
        </td>
    </tr>
</template>

<script>
import axios from 'axios';

export default {
    props: ['ingredient', 'index'],
    methods: {
        edit() {
            this.$router.push({
                name: 'config.ingred.data.edit',
                params: {
                    ing_id: this.ingredient.id,
                    is_allergen: this.ingredient.is_allergen ? true : false
                }
            });
        },
        toggleIsAllergen(id) {
            axios
                .put('/api/superadmin/config/ingredient-datas/' + id + '/toggle-is-allergen/', {})
                .then(res => {
                    this.$toastr.i(res.data.message);
                })
                .catch(error => console.error(error));
        },
        destroy(id) {
            if (!window.confirm('Delete?')) {
                return false;
            }

            axios
                .delete('/api/superadmin/config/ingredient-datas/' + id + '/destroy')
                .then(res => {
                    this.$toastr.i(res.data.message);
                    this.$emit('removeIngData', this.index);
                })
                .catch(error => {
                    this.$toastr.e(error.response.data.message);
                });
        }
    }
};
</script>
