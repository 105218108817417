<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4 mb-3">
                        <input
                            v-model="filter_term"
                            type="text"
                            class="form-control"
                            placeholder="Filter by group description or place"
                        />
                    </div>
                    <div class="col-md-1">
                        <div v-if="filter_term" class="pull-right">
                            <a class="btn btn-warning" @click="cancelFilter">Cancel filter</a>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <input
                                v-model="form.name"
                                v-validate="'required'"
                                type="text"
                                :class="{
                                    'form-control': true,
                                    'is-invalid': errors.has('name')
                                }"
                                placeholder="New group name"
                                data-vv-name="name"
                            />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <input
                                v-model="form.description"
                                v-validate="'required'"
                                type="text"
                                :class="{
                                    'form-control': true,
                                    'is-invalid': errors.has('description')
                                }"
                                placeholder="New group description"
                                data-vv-name="description"
                            />
                        </div>
                    </div>
                    <div class="col-md-1">
                        <a class="btn btn-success" @click="store">Add group</a>
                    </div>
                </div>
                <app-loader
                    v-if="loading && places.length == 0"
                    size="medium"
                    line-fg-color="#5ec58c"
                    message="Loading..."
                />
                <table v-if="places.length > 0" class="table table-striped">
                    <thead>
                        <tr>
                            <th>Group ID</th>
                            <th class="pointer" @click="orderBy('name')">
                                Name
                                <span v-html="order_field == 'name' ? active_sort_icon : sort_icon"></span>
                            </th>
                            <th class="pointer" @click="orderBy('description')">
                                Description
                                <span v-html="order_field == 'description' ? active_sort_icon : sort_icon"></span>
                            </th>
                            <th>Places</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <app-group
                            v-for="(group, index) in orderedGroups"
                            :key="group.id"
                            :index="index"
                            :group="group"
                            :places="places"
                            @removeGroup="removeGroup"
                            @updateGroup="updateGroup"
                        />
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import appGroup from './GroupTableRow';
import axios from 'axios';
import Vue from 'vue';

export default {
    components: {
        appGroup
    },
    data() {
        return {
            form: {
                name: '',
                description: ''
            },
            groups: [],
            places: [],
            loading: true,
            filter_term: '',
            order_field: 'created_at',
            order_dir: 'desc',
            sort_icon: '<i class="fa fa-sort"/>'
        };
    },
    computed: {
        filteredGroups() {
            localStorage.setItem('place_groups_table_filter_term', this.filter_term);
            return this.groups.filter(el => {
                if (!el.name) {
                    return false;
                }
                return el.name.toLowerCase().match(this.filter_term.toLowerCase());
            });
        },
        orderedGroups() {
            return _.orderBy(this.filteredGroups, this.order_field, this.order_dir);
        },
        active_sort_icon() {
            if (this.order_dir == 'desc') {
                return '<i class="fa fa-sort-down"/>';
            } else {
                return '<i class="fa fa-sort-up"/>';
            }
        }
    },
    created() {
        this.placeGroups();
        if (localStorage.getItem('place_groups_table_filter_term')) {
            this.filter_term = localStorage.getItem('place_groups_table_filter_term');
        }
    },
    methods: {
        store() {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    axios
                        .post('/api/superadmin/place/groups/store', this.form)
                        .then(res => {
                            this.groups.push(res.data.group);
                            this.$toastr.i(res.data.message);
                            this.form = {
                                name: '',
                                description: ''
                            };
                            this.$validator.reset();
                        })
                        .catch(error => {
                            console.error(error);
                        });
                }
            });
        },
        updateGroup(group) {
            let index = this.groups.findIndex(gr => gr.id === group.id);
            Vue.set(this.groups, index, group);
        },
        removeGroup(index) {
            Vue.delete(this.groups, index);
            // this.groups = this.groups.filter(gr => gr.id !== id);
        },
        placeGroups() {
            axios
                .get('/api/superadmin/place/groups')
                .then(res => {
                    this.groups = res.data;
                    this.getPlaces();
                })
                .catch(error => {
                    if (error.response.status === 403) {
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('expires_in');
                        window.location.replace('/sisemine');
                    }
                });
        },
        getPlaces() {
            axios
                .get('/api/superadmin/place/all')
                .then(res => {
                    this.places = res.data;
                    this.loading = false;
                })
                .catch(error => {
                    if (error.response.status === 403) {
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('expires_in');
                        window.location.replace('/sisemine');
                    }
                });
        },
        orderBy(field) {
            this.order_field = field;
            if (this.order_dir == 'desc') {
                this.order_dir = 'asc';
            } else {
                this.order_dir = 'desc';
            }
        },
        cancelFilter() {
            this.filter_term = '';
            localStorage.removeItem('place_groups_table_filter_term');
        }
    }
};
</script>
