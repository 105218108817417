<template>
    <tr>
        <td>{{ index + 1 }}</td>
        <td>{{ productGroup.name }}</td>
        <td>
            <span class="pull-right">
                <router-link :to="{ name: 'product-groups.edit', params: { product_groups: productGroup.id } }" tag="a">
                    Edit
                </router-link>
            </span>
        </td>
    </tr>
</template>

<script>
export default {
    filters: {
        truncate: function(value) {
            if (!value) {return '';}
            value = value.toString();
            return value.substring(0, 150);
        }
    },
    props: ['productGroup', 'index'],
    methods: {}
};
</script>
