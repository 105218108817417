<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <input
                            v-model="filter_term_ing"
                            type="text"
                            class="form-control mb-3"
                            placeholder="Filter by Name"
                        />
                    </div>
                    <div class="col-1 mt-2">
                        <span class="pull-right">
                            <router-link tag="a" :to="{ name: 'config.ingred.data.create' }">
                                New Ingredient
                            </router-link>
                        </span>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="table-responsive">
                    <app-loader v-if="loading" size="medium" line-fg-color="#5ec58c" message="Loading..."></app-loader>
                    <table v-if="filteredIngredientDatas.length > 0" class="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Is Allergen</th>
                                <th>Code</th>
                                <th>Alt Names</th>
                                <th>Food Group</th>
                                <th>Kj</th>
                                <th>Kcal</th>
                                <th>Carbs</th>
                                <th>Fat</th>
                                <th>Protein</th>
                                <th>Alcohol</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <app-ingredient-data
                                v-for="(ingredient, index) in filteredIngredientDatas"
                                :key="ingredient.id"
                                :index="index"
                                :ingredient="ingredient"
                                @removeIngData="removeIngData"
                            >
                            </app-ingredient-data>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import IngredientData from './IngredientDataTableRow';

export default {
    components: {
        appIngredientData: IngredientData
    },
    data() {
        return {
            ingredient_datas: [],
            filter_term_ing: '',
            loading: true
        };
    },
    computed: {
        filteredIngredientDatas() {
            return this.ingredient_datas.filter(el => {
                if (!el.name.en) {
                    return false;
                }
                if (el.name.en.toLowerCase().match(this.filter_term_ing.toLowerCase())) {
                    return true;
                }
                return false;
            });
        }
    },
    created() {
        this.fetchIngredientDatas();
    },
    methods: {
        fetchIngredientDatas() {
            axios
                .get('/api/superadmin/config/ingredient-datas')
                .then(res => {
                    this.ingredient_datas = res.data;
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => console.error(error));
        },
        removeIngData(index) {
            this.ingredient_datas.splice(index, 1);
        }
    }
};
</script>
