var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',[_vm._v(_vm._s(_vm.index + 1))]),_c('td',[_vm._v(_vm._s(_vm.table.process_name || '- not stored -'))]),_c('td',[_vm._v(_vm._s(_vm.table.cnt || 'null'))]),_c('td',[_c('router-link',{attrs:{"to":{
                name: 'fsp_conditions.flow.chart.defaults.edit',
                params: {
                    tableName: _vm.table.process_name,
                    countryId: _vm.countryId,
                    countryName: _vm.countryName,
                },
            },"tag":"a"}},[_vm._v(" Details ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }