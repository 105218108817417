<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <button v-if="isEdit" class="btn btn-success" type="button" @click="update">
                            Update
                        </button>
                        <button v-if="isCreate" type="button" class="btn btn-success" @click="update(false)">
                            Save
                        </button>
                        <button v-if="isCreate" type="button" class="btn btn-success" @click="update(true)">
                            Save and Add New Location
                        </button>
                        <button v-if="isShow" type="button" class="btn btn-success" @click="edit(true)">
                            Edit
                        </button>
                    </div>
                    <div class="col">
                        <h4 class="card-title hidden-xs-down">
                            Edit Food Safety Plan Condition
                        </h4>
                    </div>
                </div>
                <hr />
                <div v-if="loadError" class="alert alert-danger" role="alert">
                    <button class="btn btn-sm btn-danger" @click="reloadData">
                        Reload
                    </button>
                    Error loading data, please reload
                </div>
                <div class="row">
                    <div class="col-6">
                        <!-- countries -->
                        <div v-if="!isShow" class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Countries</label>
                            <div class="col-sm-9">
                                <app-multiselect
                                    v-model="selectedCountries"
                                    :close-on-select="false"
                                    :group-select="true"
                                    :multiple="true"
                                    :options="countries"
                                    group-label="continent"
                                    group-values="countries"
                                    label="name"
                                    placeholder="Type to search"
                                    track-by="name"
                                >
                                    <template slot="beforeList">
                                        <li @click="selectedCountries = getAllCountries()">
                                            <span
                                                class="multiselect__option"
                                                @mouseover="$event.target.classList.add('multiselect__option--highlight')"
                                                @mouseleave="$event.target.classList.remove('multiselect__option--highlight')"
                                            >
                                                Select all
                                            </span>
                                        </li>
                                        <li @click="selectedCountries = []">
                                            <span
                                                class="multiselect__option"
                                                @mouseover="$event.target.classList.add('multiselect__option--highlight')"
                                                @mouseleave="$event.target.classList.remove('multiselect__option--highlight')"
                                            >
                                                Deselect all
                                            </span>
                                        </li>
                                    </template>
                                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                                        <span v-if="values.length && !isOpen" class="multiselect__single">
                                            {{ values.length }}
                                            {{ values.length > 1 ? 'countries' : 'country' }} selected
                                        </span>
                                    </template>
                                    <span slot="noResult">Nothing found</span>
                                </app-multiselect>
                            </div>
                        </div>
                        <div v-else class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Countries</label>
                            <div class="col-sm-9">
                                <span v-for="(country, countryIndex) in selectedCountries" :key="countryIndex">
                                    {{ country.name }}{{ countryIndex !== selectedCountries.length - 1 ? ',' : '' }}
                                </span>
                            </div>
                        </div>
                        <!-- location type -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Location Type</label>
                            <div class="col-sm-9">
                                <v-select
                                    :value="rowData.food_handling_place_types"
                                    :disabled="isShow"
                                    :close-on-select="false"
                                    :options="location_types"
                                    label="name"
                                    multiple
                                    placeholder="- choose -"
                                    @input="(items) => changeRowDataItems(items, 'food_handling_place_types')"
                                    @change="(items) => changeRowDataItems(items, 'food_handling_place_types')"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- business activity -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Business Activities</label>
                            <div class="col-sm-9">
                                <v-select
                                    :value="rowData.business_activities"
                                    :disabled="isShow"
                                    :close-on-select="false"
                                    :options="business_activities"
                                    label="name"
                                    multiple
                                    placeholder="- choose -"
                                    @input="businessActivityChange"
                                    @change="businessActivityChange"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- eco label-->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Organic</label>
                            <div class="col-sm-9">
                                <b-form-select v-model="rowData.is_organic" :options="yesNo">
                                    <b-form-select-option :value="null" disabled>-- Choose --</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>
                        <!-- business activity extra data -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Extra Data</label>
                            <div class="col-sm-9">
                                <v-select
                                    :value="rowData.extra_business_activities"
                                    :disabled="isShow"
                                    :close-on-select="false"
                                    :options="extraBusinessActivitiesOptions"
                                    label="name"
                                    multiple
                                    placeholder="- choose -"
                                    @input="(items) => changeRowDataItems(items, 'extra_business_activities')"
                                    @change="(items) => changeRowDataItems(items, 'extra_business_activities')"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- production process -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Production Processes</label>
                            <div class="col-sm-9">
                                <v-select
                                    :value="rowData.production_processes"
                                    :disabled="isShow"
                                    :close-on-select="false"
                                    :options="production_processes"
                                    label="name"
                                    multiple
                                    placeholder="- choose -"
                                    @input="productionProcessChange"
                                    @change="productionProcessChange"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- production process subprocesses -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Production Sub Processes</label>
                            <div class="col-sm-9">
                                <v-select
                                    :value="rowData.production_subprocesses"
                                    :disabled="isShow"
                                    :close-on-select="false"
                                    :options="production_sub_processes"
                                    label="name"
                                    multiple
                                    placeholder="- choose -"
                                    @input="(items) => changeRowDataItems(items, 'production_subprocesses')"
                                    @change="(items) => changeRowDataItems(items, 'production_subprocesses')"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- food group cats -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Food Group Categories</label>
                            <div class="col-sm-9">
                                <v-select
                                    :value="rowData.food_group_categories"
                                    :disabled="isShow"
                                    :close-on-select="false"
                                    :options="food_group_categories"
                                    label="name"
                                    multiple
                                    placeholder="- choose -"
                                    @input="foodGroupCategoryChange"
                                    @change="foodGroupCategoryChange"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- food groups -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Food Groups</label>
                            <div class="col-sm-9">
                                <v-select
                                    :value="rowData.food_groups"
                                    :disabled="isShow"
                                    :close-on-select="false"
                                    :options="food_groups"
                                    label="name"
                                    multiple
                                    placeholder="- choose -"
                                    @input="(items) => changeRowDataItems(items, 'food_groups')"
                                    @change="(items) => changeRowDataItems(items, 'food_groups')"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- food group temp -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Food Group Temp</label>
                            <div class="col-sm-9">
                                <v-select
                                    :value="rowData.food_group_temps"
                                    :disabled="isShow"
                                    :close-on-select="false"
                                    multiple
                                    :options="food_group_temp_opts"
                                    label="name"
                                    placeholder="- choose -"
                                    @input="(items) => changeRowDataItems(items, 'food_group_temps')"
                                    @change="(items) => changeRowDataItems(items, 'food_group_temps')"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- customer groups -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Customer Groups</label>
                            <div class="col-sm-9">
                                <v-select
                                    :value="rowData.customer_groups"
                                    :disabled="isShow"
                                    :close-on-select="false"
                                    :options="customer_groups"
                                    label="name"
                                    multiple
                                    placeholder="- choose -"
                                    @input="(items) => changeRowDataItems(items, 'customer_groups')"
                                    @change="(items) => changeRowDataItems(items, 'customer_groups')"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- product groups -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Product Groups</label>
                            <div class="col-sm-9">
                                <v-select
                                    :value="rowData.product_groups"
                                    :disabled="isShow"
                                    :close-on-select="false"
                                    :options="product_groups"
                                    label="name"
                                    multiple
                                    placeholder="- choose -"
                                    @input="(items) => changeRowDataItems(items, 'product_groups')"
                                    @change="(items) => changeRowDataItems(items, 'product_groups')"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- threat -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Threat Analysis</label>
                            <div class="col-sm-9">
                                <v-select
                                    :value="rowData.fhp_features"
                                    :disabled="isShow"
                                    :close-on-select="false"
                                    :options="threat_opts"
                                    multiple
                                    label="name"
                                    placeholder="- choose -"
                                    @input="(items) => changeRowDataItems(items, 'fhp_features')"
                                    @change="(items) => changeRowDataItems(items, 'fhp_features')"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- health cert -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Health Cert Validity</label>
                            <div class="col-sm-9">
                                <v-select
                                    :value="rowData.health_cert_freqs"
                                    :disabled="isShow"
                                    :close-on-select="false"
                                    :options="health_cert_opts"
                                    label="name"
                                    multiple
                                    placeholder="- choose -"
                                    @input="(items) => changeRowDataItems(items, 'health_cert_freqs')"
                                    @change="(items) => changeRowDataItems(items, 'health_cert_freqs')"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- personnel hygiene -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Personnel Hygiene Training</label>
                            <div class="col-sm-9">
                                <v-select
                                    :value="rowData.personnel_hygiene_freqs"
                                    :disabled="isShow"
                                    :close-on-select="false"
                                    :options="hygiene_opts"
                                    multiple
                                    placeholder="- choose -"
                                    label="name"
                                    @input="(items) => changeRowDataItems(items, 'personnel_hygiene_freqs')"
                                    @change="(items) => changeRowDataItems(items, 'personnel_hygiene_freqs')"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- coli -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Coli bacteria analysis</label>
                            <div class="col-sm-9">
                                <v-select
                                    :value="rowData.coli_analysis_freqs"
                                    :disabled="isShow"
                                    :close-on-select="false"
                                    :options="bacteria_opts"
                                    multiple
                                    placeholder="- choose -"
                                    label="name"
                                    @input="(items) => changeRowDataItems(items, 'coli_analysis_freqs')"
                                    @change="(items) => changeRowDataItems(items, 'coli_analysis_freqs')"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- listeria -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Listeria bacteria analysis</label>
                            <div class="col-sm-9">
                                <v-select
                                    :value="rowData.listeria_analysis_freqs"
                                    :disabled="isShow"
                                    :close-on-select="false"
                                    :options="bacteria_opts"
                                    multiple
                                    placeholder="- choose -"
                                    label="name"
                                    @input="(items) => changeRowDataItems(items, 'listeria_analysis_freqs')"
                                    @change="(items) => changeRowDataItems(items, 'listeria_analysis_freqs')"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>

                        <!-- Taking water analyses -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Taking water analyses</label>
                            <div class="col-sm-9">
                                <v-select
                                    :value="rowData.water_analysis_freqs"
                                    :disabled="isShow"
                                    multiple
                                    :close-on-select="false"
                                    :options="water_analyses_opts"
                                    placeholder="- choose -"
                                    label="name"
                                    @input="(items) => changeRowDataItems(items, 'water_analysis_freqs')"
                                    @change="(items) => changeRowDataItems(items, 'water_analysis_freqs')"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>

                        <!-- Analyses of extending shelf-life -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Analyses of extending shelf-life</label>
                            <div class="col-sm-9">
                                <v-select
                                    :value="rowData.shelf_life_freqs"
                                    :disabled="isShow"
                                    :options="extending_shelf_life_opts"
                                    placeholder="- choose -"
                                    multiple
                                    label="name"
                                    @input="(items) => changeRowDataItems(items, 'shelf_life_freqs')"
                                    @change="(items) => changeRowDataItems(items, 'shelf_life_freqs')"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>

                        <!-- Responsible for food safety plan update -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Responsible for food safety plan update</label>
                            <div class="col-sm-9">
                                <b-form-select v-model="rowData.fsp_updating_outsourced" :disabled="isShow" :options="workOpts">
                                    <b-form-select-option :value="null" disabled>-- Choose --</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>

                        <!-- Personnel training -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Personnel training</label>
                            <div class="col-sm-9">
                                <b-form-select v-model="rowData.personnel_training_outsourced" :disabled="isShow" :options="workOpts">
                                    <b-form-select-option :value="null" disabled>-- Choose --</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>

                        <!-- Personnel health -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Personnel health</label>
                            <div class="col-sm-9">
                                <b-form-select v-model="rowData.personnel_health_outsourced" :disabled="isShow" :options="workOpts">
                                    <b-form-select-option :value="null" disabled>-- Choose --</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>

                        <!-- Maintenance of rooms and devices -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Maintenance of rooms and devices</label>
                            <div class="col-sm-9">
                                <b-form-select v-model="rowData.maintenance_outsourced" :disabled="isShow" :options="workOpts">
                                    <b-form-select-option :value="null" disabled>-- Choose --</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>

                        <!-- Waste management -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Waste management</label>
                            <div class="col-sm-9">
                                <b-form-select v-model="rowData.waste_management_outsourced" :disabled="isShow" :options="workOpts">
                                    <b-form-select-option :value="null" disabled>-- Choose --</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>

                        <!-- Pest control -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Pest control</label>
                            <div class="col-sm-9">
                                <b-form-select v-model="rowData.pest_control_outsourced" :disabled="isShow" :options="workOpts">
                                    <b-form-select-option :value="null" disabled>-- Choose --</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>

                        <!-- General cleaning and disinfecting -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">General cleaning and disinfecting</label>
                            <div class="col-sm-9">
                                <b-form-select v-model="rowData.cleaning_outsourced" :disabled="isShow" :options="workOpts">
                                    <b-form-select-option :value="null" disabled>-- Choose --</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <!--condition-->
                        <div v-for="lang in langs" class="form-group row mb-1">
                            <label class="col-sm-2 col-form-label">{{ lang && lang.name ? lang.name : 'Not found' }}</label>
                            <div v-if="lang && lang.code" class="col-sm-10">
                                <div v-if="lang.code == 'en'">
                                    <BaseQuill
                                        v-model="rowData.section_translations.en"
                                        on-paste-hide-h-t-m-l
                                        :disabled="isShow"
                                        @change="enChange($event)"
                                    />
                                    <div>
                                        <span class="mr-2">Notify user?</span> <input v-model="rowData.section_translations['notify-en']" type="checkbox">
                                    </div>
                                    <input
                                        v-model="en_required"
                                        :class="{
                                            'form-control': true,
                                            'is-invalid': errors.has('en_required')
                                        }"
                                        data-vv-as="English"
                                        data-vv-name="en_required"
                                        type="hidden"
                                    />
                                    <span v-show="errors.has('en_required')" class="invalid-feedback" role="alert">
                                        <strong>{{ errors.first('en_required') }}</strong>
                                    </span>
                                </div>
                                <div v-else>
                                    <BaseQuill
                                        v-model="rowData.section_translations[lang.code]"
                                        :disabled="isShow"
                                        class="condition-quill"
                                        on-paste-hide-h-t-m-l
                                    />
                                    <div>
                                        <span class="mr-2">Notify user?</span> <input v-model="rowData.section_translations['notify-' + lang.code]" type="checkbox">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- location block-->
                        <div class="form-group row mb-1">
                            <label class="col-sm-2 col-form-label">Location</label>
                            <div class="col-sm-10">
                                <div class="form-group row mb-1">
                                    <label class="col-sm-2 col-form-label">Table</label>
                                    <div class="col-sm-10">
                                        <v-select
                                            v-model="location.table"
                                            v-validate="'required'"
                                            label="static_name"
                                            :options="condition_tables"
                                            :disabled="isShow"
                                            :clearable="false"
                                            :class="{ 'is-invalid': errors.has('cond_table') }"
                                            data-vv-as="table"
                                            data-vv-name="cond_table"
                                            @change="(table) => locationTableChange(table.id)"
                                        >
                                        </v-select>
                                        <span v-show="errors.has('cond_table')" :disabled="isShow" class="invalid-feedback" role="alert">
                                            <strong>{{ errors.first('cond_table') }}</strong>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="location_rows.length || location.row" class="form-group row mb-1">
                                    <label class="col-sm-2 col-form-label">Row</label>
                                    <div class="col-sm-10">
                                        <select
                                            v-model="location.row"
                                            v-validate="'required'"
                                            :disabled="isShow"
                                            :class="{ 'is-invalid': errors.has('cond_row') }"
                                            class="form-control form-control-sm"
                                            data-vv-as="row"
                                            data-vv-name="cond_row"
                                            @change="locationRowChange"
                                        >
                                            <option
                                                v-for="(row, i) in location_rows"
                                                :key="i"
                                                :data-row="i"
                                                :value="row.id"
                                            >
                                                {{ decipherAPIString(row) }}
                                            </option>
                                        </select>
                                        <span v-show="errors.has('cond_row')" class="invalid-feedback" role="alert">
                                            <strong>{{ errors.first('cond_row') }}</strong>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="location_columns.length || location.column" class="form-group row mb-1">
                                    <label class="col-sm-2 col-form-label">Column</label>
                                    <div class="col-sm-10">
                                        <select
                                            v-model="location.column"
                                            v-validate="'required'"
                                            :disabled="isShow"
                                            :class="{ 'is-invalid': errors.has('cond_column') }"
                                            class="form-control form-control-sm"
                                            data-vv-as="column"
                                            data-vv-name="cond_column"
                                            @input="selectColumn"
                                        >
                                            <option v-for="(column, i) in location_columns" :key="i" :value="column.id">
                                                {{ decipherAPIString(column) }}
                                            </option>
                                        </select>
                                        <span v-show="errors.has('cond_column')" class="invalid-feedback" role="alert">
                                            <strong>{{ errors.first('cond_column') }}</strong>
                                        </span>
                                    </div>
                                </div>
                                <!-- Standard types -->
                                <div class="form-group row mb-1">
                                    <label class="col-sm-2 col-form-label">Standard type</label>
                                    <div class="col-sm-10">
                                        <v-select
                                            :value="standards"
                                            v-validate="'required'"
                                            :disabled="isShow"
                                            :close-on-select="false"
                                            multiple
                                            :options="standardOptions"
                                            label="name"
                                            placeholder="- choose -"
                                            data-vv-name="standard_options"
                                            @input="(items) => standards = items"
                                            @change="(items) => standards = items"
                                        >
                                            <span slot="no-options">Nothing found</span>
                                        </v-select>
                                        <span v-show="errors.has('standard_options')" class="text-danger" role="alert">
                                            <strong>{{ errors.first('standard_options') }}</strong>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- tags -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-2 col-form-label">Tags</label>
                            <div class="col-sm-10">
                                <vue-tags-input v-model="tag" :disabled="isShow" :tags="tags" :autocomplete-items="filteredTags" @tags-changed="newTags => (tags = newTags)">
                                </vue-tags-input>
                            </div>
                        </div>
                        <!-- condition type -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-2 col-form-label">Condition type</label>
                            <div class="col-sm-10">
                                <select v-model="rowData.fsp_document_type_id" :disabled="isShow" class="form-control" @change="conditionTypeChange">
                                    <option :value="1">Food Safety Plan</option>
                                </select>
                            </div>
                        </div>
                        <!-- legislations -->
                        <h4>
                            Legislations
                            <button :disabled="isShow" class="btn btn-success float-right" type="button" @click="addLegislation">
                                Add
                            </button>
                        </h4>
                        <div class="clearfix"></div>
                        <div v-for="(leg, i) in rowData.legislations" class="row mt-2">
                            <div class="col-md-6">
                                <label class="mb-2">Countries: </label>
                                <span v-if="isShow">
                                    <span v-for="(country, legislationCountryIndex) in leg.countries" :key="legislationCountryIndex">
                                        {{ country.name }}{{ legislationCountryIndex !== leg.countries.length - 1 ? ',' : '' }}
                                    </span>
                                </span>
                                <app-multiselect
                                    v-else
                                    v-model="leg.countries"
                                    :close-on-select="false"
                                    :group-select="true"
                                    :multiple="true"
                                    :options="countries"
                                    group-label="continent"
                                    group-values="countries"
                                    label="name"
                                    placeholder="Type to search"
                                    track-by="name"
                                >
                                    <template slot="beforeList">
                                        <li @click="leg.countries = getAllCountries()">
                                            <span
                                                class="multiselect__option"
                                                @mouseover="$event.target.classList.add('multiselect__option--highlight')"
                                                @mouseleave="$event.target.classList.remove('multiselect__option--highlight')"
                                            >
                                                Select all
                                            </span>
                                        </li>
                                        <li @click="leg.countries = []">
                                            <span
                                                class="multiselect__option"
                                                @mouseover="$event.target.classList.add('multiselect__option--highlight')"
                                                @mouseleave="$event.target.classList.remove('multiselect__option--highlight')"
                                            >
                                                Deselect all
                                            </span>
                                        </li>
                                    </template>
                                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                                        <span v-if="values.length && !isOpen" class="multiselect__single">
                                            {{ values.length }}
                                            {{ values.length > 1 ? 'countries' : 'country' }} selected
                                        </span>
                                    </template>
                                    <span slot="noResult">Nothing found</span>
                                </app-multiselect>
                            </div>
                            <div class="col-md-6">
                                <label>Legislation</label>
                                <button
                                    :disabled="isShow"
                                    class="btn btn-danger btn-sm float-right mb-1"
                                    type="button"
                                    @click="removeLegislation(i)"
                                >
                                    Remove
                                </button>
                                <div class="clearfix"></div>
                                <SelectLegislation :disabled="isShow" :legislation-options="legislationOptions" :legislation="leg ? leg : {}" />
                            </div>
                        </div>

                        <!-- images -->
                        <div v-if="rowData.file_list && rowData.file_list.length" class="form-group row mb-1 mt-2">
                            <label class="col-sm-2 col-form-label">Condition images</label>
                            <div class="col-sm-10">
                                <Images :disabled="isShow" :files="rowData.file_list" :remove-image="(id) => removeImage(id)" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appMultiselect from 'vue-multiselect';
import structure from '../../../utils/condition_table_structure';
import SelectLegislation from './SelectLegislation';
import Images from './Images';
import axios from 'axios';
import BaseQuill from '@/components/common/form/BaseQuill';

export default {
    components: {
        BaseQuill,
        Images,
        SelectLegislation,
        appMultiselect
    },
    data() {
        return {
            standards: [],
            standardOptions: [],
            selectedCountries: [],
            applicationType: null,
            yesNo: [{ value: 1, text: 'yes' }, { value: 0, text: 'no' }],
            workOpts: [{value: null, text: '- choose -' }, { value: 1, text: 'service' }, { value: 0, text: 'employee' }],
            workOptsTypes: [ 'fsp_updating_outsourced', 'personnel_training_outsourced', 'personnel_health_outsourced',
                'maintenance_outsourced', 'waste_management_outsourced', 'pest_control_outsourced',
                'cleaning_outsourced',
            ],
            legislations: [],
            health_cert_opts: null,
            hygiene_opts: null,
            bacteria_opts: null,
            temp_opts: null,
            threat_opts: null,
            water_analyses_opts: null,
            extending_shelf_life_opts: null,
            null_Employee_Service_opts: structure.nullEmployeeServiceOpts,
            food_group_temp_opts: structure.foodGroupTempOpts,
            eco_label_opts: structure.ecoLabelOpts,

            threat_analysis: [],

            general_cleaning_and_disinfecting: null,

            load_error: false,
            en_required: '',
            condition: null,
            countries: [],
            selected_countries: [],
            business_activities: [],
            location_types: [],
            extra_data: [],
            production_processes: [],
            production_sub_processes: [],
            food_group_categories: [],
            food_groups: [],
            selected_food_group_temp: null,
            customer_groups: [],
            production_units: null,
            time_units: null,
            product_groups: [],
            langs: [],

            tag: '',
            tags: [],
            tagOptions: [],
            legislationOptions: [],

            form: {},
            location_rows: [],
            location_columns: [],
            location: {},
            condition_tables: [],
            condition_type: null,
            condition_table_index: null,
            selected_product_groups: [],
            rowData: {
                parent_fsp_section_id: null,
                fsp_standard_type_id: null, //comes from parent
                fsp_document_type_id: 1,
                section_translations: {},
                countries: [],
                food_handling_place_types: [],
                business_activities: [],
                is_organic: null,
                extra_business_activities: [],
                production_processes: [],
                production_subprocesses: [],
                food_group_categories: [],
                food_groups: [],
                food_group_temps: [],
                customer_groups: [],
                product_groups: [],
                water_analysis_freqs: [],
                production_volume: {
                    time_unit_id: null,
                    measurement_unit_id: null,
                    min_production_volume_amt: null,
                    max_production_volume_amt: null
                },
                fhp_features: [],
                health_cert_freqs: [],
                personnel_hygiene_freqs: [],
                coli_analysis_freqs: [],
                listeria_analysis_freqs: [],
                shelf_life_freqs: [],
                fsp_updating_outsourced: null,
                personnel_training_outsourced: null,
                personnel_health_outsourced: null,
                maintenance_outsourced: null,
                waste_management_outsourced: null,
                pest_control_outsourced: null,
                cleaning_outsourced: null,
                tags: [],
                file_list: [],
                legislations: [],
                food_auth_files_needed_ind: null
            }

        };
    },
    computed: {
        isCreate() {
            return this.applicationType === 'create';
        },
        isEdit() {
            return this.applicationType === 'edit';
        },
        isShow() {
            return this.applicationType === 'show';
        },
        filteredTags() {
            return this.tagOptions.filter(t => {
                return t.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
        },
        getColumnRow() {
            return null;
        },
        getRowTable() {
            return null;
        },
        extraBusinessActivitiesOptions() {
            let result = [];
            if(this.rowData && this.rowData.business_activities) {
                this.rowData.business_activities.forEach(ba => {
                    if(ba && ba.extra_datas && ba.extra_datas.length){
                        ba.extra_datas.forEach(ed => {
                            result.push(ed);
                        });
                    }
                });
            }
            return result;
        },
        loadError() {
            return this.load_error;
        }
    },
    watch: {
        '$route.name': {
            handler: function () {
                this.getApplicationType();
            }
        }
    },
    created() {
        this.setData();
        this.getApplicationType();
        this.getLanguages();
        this.getCondition();
    },
    mounted() {
        // At start always scroll to top, but wait until content is loaded
        setTimeout(() => {
            window.scroll({
                top: 0,
            });
        }, 500);
    },
    methods: {
        changeRowDataItems(items, prop) {
            this.rowData[prop] = items;
        },
        selectColumn(e) {
            this.getTable(parseInt(e.target.value, 0));
        },
        removeImage(id) {
            this.rowData.file_list = this.rowData.file_list.filter(f => f.id !== id);
        },
        decipherAPIString(string) {
            try {
                return string.section_translations.filter(s => s.language_id === 43)[0].translation_text;
            } catch (e) {
                try {
                    return string.static_name;
                } catch (e) {
                    return 'translation not found';
                }
            }
        },
        getAllCountries() {
            let countries = [];
            this.countries.map(continent => {
                continent.countries.map(country => {
                    countries.push(country);
                });
            });
            return countries;
        },
        addLegislation() {
            this.rowData.legislations.push({ name: '', countries: [] });
        },
        removeLegislation(index) {
            this.rowData.legislations.splice(index, 1);
        },
        getExistingTags() {
            axios
                .get('/api/classifiers/fsp-document-tags')
                .then(res => {
                    this.tagOptions = res.data;
                    this.tagOptions.forEach(tag => {
                        tag.text = tag.name;
                    });
                })
                .catch(error => {
                    this.load_error = true;
                    console.error(error);
                });
        },
        getExistingLegislations() {
            axios
                .get('/api/classifiers/legislations')
                .then(res => {
                    this.legislationOptions = res.data;
                    this.legislationOptions.forEach(tag => {
                        tag.text = tag.name;
                    });
                })
                .catch(error => {
                    this.load_error = true;
                    console.error(error);
                });
        },
        reloadData() {
            this.load_error = false;
            this.getCondition();
            this.getLocationStructure();
            this.getCountries();
            this.getBusinessActivities();
            this.getLocationTypes();
            this.getProductionProcesses();
            this.getFoodGroupCategories();
            this.getFoodGroups();
            this.getExtraData();
            this.getCustomerGroups();
            this.getProductionUnits();
            this.getProductGroups();
            this.getTimeUnits();
            this.getExistingTags();
            this.getExistingLegislations();
            this.getCurrentLocation();
            this.getWaterAnalysesOptions();
            this.getHealthCertificatesOpts();
            this.getHygieneOpts();
            this.getBacteriaOpts();
            this.getThreatOpts();
            this.getFoodGroupTempOpts();
            this.getShelfAnalyzesOpts();
        },
        enChange({ text }) {
            this.en_required = text;
        },
        edit() {
            this.$router.push({
                name: 'fsp_conditions.edit',
                params: { fsp_condition_id: this.$route.params.fsp_condition_id }
            });
        },
        update(newPlace) {
            if(this.rowData.section_translations.en == null || this.rowData.section_translations.en === '') {
                this.$toastr.e('English translation must be added!');
                return;
            }
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    let out = {...this.rowData};
                    let translations = [];
                    Object.keys(this.rowData.section_translations).forEach(key => {
                        if(!key.includes('notify-') && this.rowData.section_translations[key]){
                            translations.push({language: key, translation_text: this.rowData.section_translations[key], notify_users: this.rowData.section_translations['notify-' + key]});
                        }
                    });
                    out.section_translations = translations;
                    out.parent_fsp_section_id = this.location.column;
                    out.tags = this.tags;
                    let lengthOfAllCountries = 0;
                    this.countries.forEach(continent => {
                        lengthOfAllCountries += continent && continent.countries ? continent.countries.length : 0;
                    });
                    if(this.selectedCountries && this.selectedCountries.length === lengthOfAllCountries){
                        out.countries = [];
                    } else {
                        out.countries = this.selectedCountries;
                    };
                    out.legislations.forEach(leg => {
                        if(leg.countries && leg.countries.length === lengthOfAllCountries){
                            leg.countries = [];
                        }
                    });
                    out.tags.forEach(tag => {
                        tag.name = tag.text;
                    });
                    out.food_auth_files_needed_ind = out.food_auth_files_needed_ind ? 1 : 0;
                    out.standards = this.standards;
                    if(this.isCreate){
                        axios
                            .post(
                                '/api/superadmin/food-safety-plan-conditions/', out
                            )
                            .then(() => {
                                this.$toastr.i('Condition created!');
                                if(!newPlace){
                                    this.onUpdateRedirect();
                                }
                            })
                            .catch(error => console.error(error));
                    } else {
                        axios
                            .put(
                                '/api/superadmin/food-safety-plan-conditions/' +
                                this.$route.params.fsp_condition_id,
                                out
                            )
                            .then(() => {
                                this.$toastr.i('Condition edited!');
                                this.onUpdateRedirect();
                            })
                            .catch(error => console.error(error));
                    }
                }
            });
        },
        onUpdateRedirect() {
            if(this.$route.query.selectedParentTableId) {
                this.$router.push({
                    name: 'fsp_conditions.check.fsp',
                    params: {
                        scrollY: this.$route.query.scrollY,
                        selectedParentTableId: this.$route.query.selectedParentTableId,
                    },
                });
            } else {
                this.$router.push({ name: 'fsp_conditions' });
            }
        },
        assignValuesToObject(assignee, assigner) {
            Object.keys(assignee).forEach(key => {
                if(assigner[key] || assigner[key] === 0){
                    if(assignee[key] && typeof(assignee[key]) === 'object'){
                        Object.assign(assignee[key], assigner[key]);
                    } else {
                        assignee[key] = assigner[key];
                    }
                }
            });
        },
        getWaterAnalysesOptions() {
            axios.get('/api/classifiers/task-subtypes/16/frequencies').then(res => {
                this.water_analyses_opts = res.data;
            });
        },
        getShelfAnalyzesOpts() {
            axios.get('/api/classifiers/task-subtypes/19/frequencies').then(res => {
                this.extending_shelf_life_opts = res.data;
            });
        },
        getHygieneOpts() {
            axios.get('/api/classifiers/task-subtypes/14/frequencies').then(res => {
                this.hygiene_opts = res.data;
            });
        },
        getHealthCertificatesOpts() {
            axios.get('/api/classifiers/task-subtypes/13/frequencies').then(res => {
                this.health_cert_opts = res.data;
            });
        },
        getThreatOpts() {
            axios.get('/api/classifiers/fhp-feature-types').then(res => {
                this.threat_opts = res.data;
            });
        },
        getFoodGroupTempOpts() {
            axios.get('/api/classifiers/food-group-temp-types').then(res => {
                this.food_group_temp_opts = res.data;
            });
        },
        getBacteriaOpts() {
            axios.get('/api/classifiers/task-subtypes/18/frequencies').then(res => {
                this.bacteria_opts = res.data;
            });
        },
        getCondition() {
            if(this.isCreate){
                this.getLocationStructure();
                this.getCountries();
                this.getBusinessActivities();
                this.getLocationTypes();
                this.getProductionProcesses();
                this.getFoodGroupCategories();
                this.getFoodGroups();
                this.getExtraData();
                this.getCustomerGroups();
                this.getProductionUnits();
                this.getProductGroups();
                this.getTimeUnits();
                this.getExistingTags();
                this.getExistingLegislations();
                this.getCurrentLocation();
                this.getWaterAnalysesOptions();
                this.getHealthCertificatesOpts();
                this.getHygieneOpts();
                this.getBacteriaOpts();
                this.getThreatOpts();
                this.getFoodGroupTempOpts();
                this.getShelfAnalyzesOpts();
                this.productionProcessChange(this.rowData.production_processes);
            } else {
                axios
                    .get(`/api/superadmin/food-safety-plan-conditions/${this.$route.params.fsp_condition_id}`)
                    .then(res => {
                        if(res.data){
                            this.selectedCountries = res.data.countries;
                            this.standards = res.data.standards;
                            let translations = {};
                            if (res.data.section_translations){
                                res.data.section_translations.forEach(st => {
                                    translations[st.language] = st && st.translation_text ? st.translation_text : '';
                                });
                            }
                            this.assignValuesToObject(this.rowData, res.data);
                            this.rowData.section_translations = translations;
                            this.tags = [...this.rowData.tags];
                            this.tags.forEach(tag => {tag.text = tag.name;});
                            this.rowData.legislations = res.data.legislations;
                            Object.keys(this.rowData).forEach(key => {
                                if (this.rowData[key] === null && !this.workOptsTypes.includes(key) ){
                                    this.rowData[key] = 0;
                                }
                            });
                        }
                    })
                    .then(() => {
                        this.getLocationStructure();
                        this.getCountries();
                        this.getBusinessActivities();
                        this.getLocationTypes();
                        this.getProductionProcesses();
                        this.getFoodGroupCategories();
                        this.getFoodGroups();
                        this.getExtraData();
                        this.getCustomerGroups();
                        this.getProductionUnits();
                        this.getProductGroups();
                        this.getTimeUnits();
                        this.getExistingTags();
                        this.getExistingLegislations();
                        this.getCurrentLocation();
                        this.getWaterAnalysesOptions();
                        this.getHealthCertificatesOpts();
                        this.getHygieneOpts();
                        this.getBacteriaOpts();
                        this.getThreatOpts();
                        this.getFoodGroupTempOpts();
                        this.getShelfAnalyzesOpts();
                        this.productionProcessChange(this.rowData.production_processes);
                    })
                    .catch(error => {
                        this.load_error = true;
                        console.error(error);
                    });
            }
        },
        getProductionProcesses() {
            axios
                .get('/api/classifiers/production-processes')
                .then(res => {
                    this.production_processes = res.data;
                })
                .catch(error => {
                    this.load_error = true;
                    console.error(error);
                });
        },
        getProductionSubProcesses(processes) {
            let pps;
            if (typeof processes === 'string' || processes instanceof String) {
                pps = JSON.parse(processes);
            } else {
                pps = processes;
            }

            if(!pps.length) {
                this.rowData.production_subprocesses = [];
                this.production_sub_processes = [];
                return;
            }

            axios
                .get(`/api/classifiers/production-sub-processes/${pps}`)
                .then(res => {
                    this.production_sub_processes = res.data.map(el => ({...el, name: `(${el.id}) ${el.name} ${el.tooltip ? `(${el.tooltip})` : ''}`}));
                    const subProcessIds = this.rowData.production_subprocesses.map(item => item.id);
                    this.rowData.production_subprocesses = this.production_sub_processes.filter(psp => subProcessIds.includes(psp.id));

                })
                .catch(error => console.error(error));
        },
        productionProcessChange(items) {
            this.rowData.production_processes = items;
            this.getProductionSubProcesses(items.map(item => item.id));
        },
        getProductGroups() {
            axios
                .get('/api/classifiers/product-groups')
                .then(res => {
                    this.product_groups = res.data;
                })
                .catch(error => {
                    this.load_error = true;
                    console.error(error);
                });
        },
        getCountries() {
            axios
                .get('/api/classifiers/countries?simple-format=true')
                .then(res => {
                    this.countries = res.data;
                })
                .catch(error => {
                    this.load_error = true;
                    console.error(error);
                });
        },
        getBusinessActivities() {
            axios
                .get('/api/classifiers/business-activities')
                .then(res => {
                    this.rowData.business_activities = res.data.filter(val =>
                        this.rowData.business_activities.some(ba => ba['id'] === val.id)
                    );
                    this.business_activities = res.data;
                })
                .catch(error => {
                    this.load_error = true;
                    console.error(error);
                });
        },
        getLocationTypes() {
            axios
                .get('/api/classifiers/location_types')
                .then(res => {
                    this.location_types = res.data;
                })
                .catch(error => {
                    this.load_error = true;
                    console.error(error);
                });
        },
        businessActivityChange(items) {
            this.rowData.business_activities = items;
            this.extra_data = [];
            items.forEach(ba => {
                this.getBusinessActivityExtraData(ba.id);
            });
        },
        getExtraData() {
            axios
                .get('/api/classifiers/extra-data')
                .catch(error => {
                    this.load_error = true;
                    console.error(error);
                });
        },
        getBusinessActivityExtraData(ba) {
            axios
                .get('/api/classifiers/business-activity/' + ba + '/extra-data')
                .then(res => {
                    this.extra_data = this.extra_data.concat(res.data);
                })
                .catch(error => console.error(error));
        },
        getFoodGroupCategories() {
            axios
                .get('/api/classifiers/food-group-categories')
                .then(res => {
                    this.food_group_categories = res.data;
                })
                .catch(error => {
                    this.load_error = true;
                    console.error(error);
                });
        },
        foodGroupCategoryChange(items) {
            this.rowData.food_group_categories = items;
            this.getFoodGroups();
        },
        getFoodGroups() {
            const categories = this.rowData.food_group_categories.map(cat => cat.id);

            if(categories.length <= 0) {
                this.food_groups = [];
                return;
            }

            axios
                .get('/api/classifiers/food-groups/' + categories)
                .then(res => {
                    this.food_groups = res.data;
                })
                .catch(error => console.error(error));
        },
        getCustomerGroups() {
            axios
                .get('/api/classifiers/customer-groups')
                .then(res => {
                    this.customer_groups = res.data;
                })
                .catch(error => {
                    this.load_error = true;
                    console.error(error);
                });
        },
        getProductionUnits() {
            axios
                .get('/api/classifiers/production-units')
                .then(res => {
                    this.production_units = res.data;
                })
                .catch(error => {
                    this.load_error = true;
                    console.error(error);
                });
        },
        getTimeUnits() {
            axios
                .get('/api/classifiers/new-time-units')
                .then(res => {
                    this.time_units = res.data;
                })
                .catch(error => {
                    this.load_error = true;
                    console.error(error);
                });
        },
        getLanguages() {
            axios
                .get('/api/superadmin/food-safety-plan-conditions/languages')
                .then(res => {
                    this.langs = res.data;
                })
                .catch(error => {
                    this.load_error = true;
                    console.error(error);
                });
        },
        locationTableChange(val, callback) {
            const id = val && val.target && val.target.value ? val.target.value : val;
            axios.get(`/api/superadmin/fsp-table-rows?tableId=${id}`).then(res => {
                this.location_rows = res.data.data;
                if(callback) {
                    callback();
                }
            }).catch(error => {
                this.load_error = true;
                console.error(error);
            });
        },
        locationRowChange(val) {
            const id = val && val.target && val.target.value ? val.target.value : val;
            const cols = this.location_rows.filter(row => row.id === parseInt(id, 0))[0];
            if(cols && cols.children) {
                this.location_columns = cols.children;
            } else {
                this.location_columns = [];
            }
        },
        getCurrentLocation() {
            const columnId = this.rowData.parent_fsp_section_id;
            if(columnId) {
                this.location.column = columnId;
                axios.get(`/api/superadmin/fsp-table-row-columns/${this.rowData.parent_fsp_section_id}`).then(res => {
                    if(res && res.data && res.data) {
                        this.standardOptions = res.data.standards;
                        this.location.row = res.data.parent_fsp_section_id;
                        axios.get(`/api/superadmin/fsp-table-rows/${res.data.parent_fsp_section_id}`).then(tableRes => {
                            if(tableRes && tableRes.data && tableRes.data.parent_fsp_section_id) {
                                this.location.table = this.condition_tables.find(table => table.id === tableRes.data.parent_fsp_section_id);
                                this.locationTableChange(tableRes.data.parent_fsp_section_id, () => this.locationRowChange(res.data.parent_fsp_section_id));
                            }
                        });
                    }
                });
            }
        },
        conditionTypeChange() {
            this.getLocationStructure();
        },
        async getLocationStructure() {
            axios.get('/api/superadmin/fsp-table-groups').then(res => {
                console.log('fsp-table-groups', res.data.data);
            });

            this.location_rows = [];
            this.location_columns = [];
            try {
                const { data } = await axios.get(`/api/superadmin/fsp-tables?documentTypeId=${this.rowData.fsp_document_type_id}`);
                this.condition_tables = data.data.map(conditionTable => ({...conditionTable, static_name: this.decipherAPIString(conditionTable)}));
            } catch(error) {
                this.load_error = true;
                console.error(error);
            }
        },
        getApplicationType() {
            if(this.$route.name === 'fsp_conditions.create'){
                this.applicationType = 'create';
            } else if(this.$route.name === 'fsp_conditions.edit') {
                this.applicationType = 'edit';
            } else if(this.$route.name === 'fsp_conditions.show') {
                this.applicationType = 'show';
            }
        },
        getTable(id) {
            axios.get(`/api/superadmin/fsp-table-row-columns/${id}`).then(response => {
                if(response.data){
                    this.standardOptions = response.data.standards;
                }
            });
        },
        setData() {
            //Data keeping object
            this.rowData = {
                standards: [],
                standardOptions: [],
                parent_fsp_section_id: null,
                fsp_standard_type_id: null, //comes from parent
                fsp_document_type_id: 1,
                section_translations: {},
                countries: [],
                food_handling_place_types: [],
                business_activities: [],
                is_organic: null,
                extra_business_activities: [],
                production_processes: [],
                production_subprocesses: [],
                food_group_categories: [],
                food_groups: [],
                food_group_temps: [],
                customer_groups: [],
                product_groups: [],
                water_analysis_freqs: [],
                production_volume: {
                    time_unit_id: null,
                    measurement_unit_id: null,
                    min_production_volume_amt: null,
                    max_production_volume_amt: null
                },
                fhp_features: [],
                health_cert_freqs: [],
                personnel_hygiene_freqs: [],
                coli_analysis_freqs: [],
                listeria_analysis_freqs: [],
                shelf_life_freqs: [],
                fsp_updating_outsourced: null,
                personnel_training_outsourced: null,
                personnel_health_outsourced: null,
                maintenance_outsourced: null,
                waste_management_outsourced: null,
                pest_control_outsourced: null,
                cleaning_outsourced: null,
                tags: [],
                file_list: [],
                legislations: [],
                food_auth_files_needed_ind: null
            };
        }
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
