<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <app-loader v-if="loading" line-fg-color="#5ec58c" message="Loading..." size="small" />
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>E-mail</th>
                                <th>Address</th>
                                <th>Priority</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(place, index) in company.places" :key="place.id" :index="index">
                                <td>{{ place.id }}</td>
                                <td>{{ place.name }}</td>
                                <td>{{ place.person_phone }}</td>
                                <td>{{ place.person_email }}</td>
                                <td>{{ place.address }}</td>
                                <td>
                                    <div v-if="editing" class="dropDown">
                                        <v-select v-model="place.priority" :clearable="false" :options="priorities" />
                                    </div>
                                    <div v-else>{{ place.priority }}</div>
                                </td>
                                <td>
                                    <button v-if="editing" class="btn btn-success" @click="save">Save</button>
                                    <button v-else class="btn btn-info" @click="edit">Edit</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { LOW, MEDIUM, HIGH } from '@store/common/constants';
export default {
    data() {
        return {
            editing: false,
            loading: false,
            priorities: [  LOW, MEDIUM, HIGH ],
            company: {},
        };
    },
    computed: {
        getCompany() {
            return this.$store.getters.getCompany;
        },
    },
    watch: {
        getCompany: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.company = cloneDeep(val);
                }
            }
        }
    },
    methods: {
        edit() {
            return this.editing = true;
        },
        async save() {
            this.loading = true;
            try {
                await axios.put(`/api/superadmin/companies/${this.company.id}/update-priority`, this.company);
                this.editing = false;
                this.$store.commit('editCompany', this.company);
                this.$toastr.s('Successfully updated place priority.');
            } catch(error) {
                console.error(error);
                this.$toastr.e(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
    .dropDown {
        height: 7rem;
    }
</style>