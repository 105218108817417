<template>
    <div class="overflow-auto">
        <b-pagination
            v-model="pageNumber"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
            @change="(num) => changeCurrentPageNumber(num)"
        ></b-pagination>
    </div>
</template>

<script>
export default {
    name: 'FspConditionsNavigation',
    props: {
        changeCurrentPageNumber: Function,
        total: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            required: true
        },
        currentPage: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            pageNumber: 0
        };
    },
    watch: {
        currentPage: function (val, oldVal) {
            if(val !== this.pageNumber){
                this.pageNumber = val;
            }
        }
    },
    created() {
        this.pageNumber = this.currentPage;
    }
};
</script>

<style scoped>

</style>
