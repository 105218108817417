<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <v-select
                    v-model="selectedParentTable"
                    :options="parentTables"
                    label="name"
                    placeholder="- choose -"
                    :clearable="false"
                    @input="selectTable"
                >
                    <template #option="{ name, hasProposals }">
                        <div class="p-1 my-2" :class="hasProposals && 'text-white bg-success'">
                            <span class="ml-2">{{ name }} <i v-if="hasProposals" class="fa fa-exclamation-circle ml-1"></i></span>
                        </div>
                    </template>
                    <span slot="no-options">Nothing found</span>
                </v-select>
                <br>
                <template v-if="sortedRowObjects.length && tableRows.length">
                    <h3>{{ selectedParentTable.name }}</h3>
                    <span v-for="(row, rowIndex) in sortedRowObjects" v-if="getRowStaticName(row.row.id) === 'before_table_text'">
                        <p
                            v-for="cond in getSpecificRowColumns(row.row.id)"
                            :key="rowIndex"
                            class="mb-1"
                        >
                            <span :key="cond.id">
                                <span v-html="decipherAPIString(cond)"></span>
                                <span v-if="cond.file_list.length">
                                    <Images :files="cond.file_list" />
                                </span>
                            </span>
                        </p>
                    </span>

                    <table class="table table-bordered">
                        <tbody v-for="(row, rowIndex) in sortedRowObjects" v-if="getRowStaticName(row.row.id) !== 'before_table_text' || getRowStaticName(row.row.id) !== 'after_table_text'">
                            <tr>
                                <th class="light-gray-bg"></th>
                                <th v-for="column in getSpecificRowColumns(row.row.id)" class="light-gray-bg">
                                    {{ column.name }}
                                </th>
                            </tr>
                            <tr>
                                <th class="light-gray-bg">
                                    {{ row && row.row ? decipherAPIString(row.row) : 'translation not found' }}
                                    <p>
                                        <button
                                            class="btn btn-xs btn-success float-right"
                                            @click="addRowGroup(row.row.id)"
                                        >
                                            Add Group
                                        </button>
                                    </p>
                                </th>
                                <td v-for="column in getSpecificRowColumns(row.row.id)">
                                    <app-row-groups
                                        :row="row"
                                        :column="column"
                                        :delete-group="(groupIndex) => removeRowGroup(rowIndex, groupIndex)"
                                        :columns="getSpecificRowColumns(row.row.id)"
                                        :has-double-click="true"
                                        :selected-parent-table-id="selectedParentTable.id"
                                    ></app-row-groups>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span v-for="(row, rowIndex) in sortedRowObjects" v-if="getRowStaticName(row.row.id) === 'after_table_text'">
                        <p
                            v-for="cond in getSpecificRowColumns(row.row.id)"
                            :key="rowIndex"
                            class="mb-1"
                        >
                            <span :key="cond.id">
                                <span v-html="decipherAPIString(cond)"></span>
                                <span v-if="cond.file_list.length">
                                    <Images :files="cond.file_list" />
                                </span>
                            </span>
                        </p>
                    </span>
                    <br />
                    <hr />
                    <br />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import appRowGroups from './FspConditionsRowGroup';
import Images from './Images';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
    data() {
        return {
            selectedParentTable: null,
            tables: [],
            conditions: [],
        };
    },
    computed: {
        ...mapGetters([ 'fspConditionTables', 'getSelectedFspTableId' ]),
        ...mapGetters({
            tableRows: 'getSelectedFspConditionTableRows',
            rowObjects: 'getSelectedFspConditionTableRowObjects',
        }),
        sortedRowObjects() {
            return this.reorderRows(this.rowObjects);
        },
        parentTables() {
            return this.fspConditionTables.map((table) => ({
                id: table.id,
                name: `[${table.static_name}] ` + this.decipherAPIString(table) + ` (${table.id ? table.id : 'no id'})`,
                hasProposals: table?.children_have_change_proposals,
            }));
        },
    },
    created() {
        this.$store.dispatch('fetchFspConditionTables');
        this.$store.dispatch('fetchLanguages');
        this.$store.dispatch('fetchCountries');

        const selectedFspTableId = this.getSelectedFspTableId  || this.$route.params.selectedParentTableId && +this.$route.params.selectedParentTableId;

        if(selectedFspTableId) {
            this.selectTable({id: selectedFspTableId});
            this.selectedParentTable = selectedFspTableId;
            this.$store.commit('setSelectedFspTableId', selectedFspTableId);
        }
    },
    mounted() {
        const scrollY = this.$route.params.scrollY;
        if(scrollY) {
            //Need to wait until table is built before scrolling
            setTimeout(() => {
                window.scroll({
                    top: scrollY,
                });
            }, 750);
        }
    },
    methods: {
        getRowStaticName(id) {
            const row = this.tableRows.filter(row => row.id === id);
            return row && row.length && row[0] && row[0].static_name ? row[0].static_name : '';
        },
        getSpecificRowColumns(rowId) {
            const selectedRow = this.tableRows.filter(row => row.id === rowId);
            let columns = [];
            if(selectedRow && selectedRow.length){
                selectedRow[0].children.forEach(col => {
                    columns.push({
                        id: col.id,
                        name: this.decipherAPIString(col),
                        ...col
                    });
                });
            }
            return columns.sort((a,b) => a.order_number - b.order_number);
        },
        decipherAPIString(string) {
            try {
                return string.section_translations.filter(s => s.language_id === 43)[0].translation_text;
            } catch (e) {
                try {
                    return string.static_name;
                } catch (e) {
                    return 'translation not found';
                }
            }
        },
        addRowGroup(id) {
            this.$store.dispatch('addRowGroup', { id }).then(() => {
                this.$toastr.i('Added new row group!');
            });
        },
        removeRowGroup(rowIndex, groupIndex) {
            this.sortedRowObjects[rowIndex].groups.splice(groupIndex, 1);
        },
        reorderRows(rows) {
            return rows.sort((a, b) => a.row.order_number - b.row.order_number);
        },
        reorderChildren(items) {
            return _.orderBy(items, 'row.order_number', 'asc');
        },
        selectTable(table) {
            this.$store.dispatch('selectFetchFspConditionTables', table);
        },
    },
    components: {
        Images,
        appRowGroups
    }
};
</script>

<style scoped>
ul {
    padding: 0px;
    margin: 0px 0px 0px 20px;
}

.light-gray-bg {
    background: #efefef;
}
</style>
