<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <input
                        v-model="filter_term_ed"
                        type="text"
                        class="form-control mb-3 col-7"
                        placeholder="Filter by Name"
                    />
                    <div class="col-5 mt-2">
                        <span class="pull-right">
                            <router-link tag="a" :to="{ name: 'smartfilter.ed.create' }">
                                New Extra Data
                            </router-link>
                        </span>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="table-responsive">
                    <table v-if="filteredExtraDatas.length > 0" class="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Always show</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <app-extra-data
                                v-for="(extra_data, index) in filteredExtraDatas"
                                :key="extra_data.id"
                                :index="index"
                                :extra_data="extra_data"
                                @removeExtraData="removeExtraData"
                            >
                            </app-extra-data>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ExtraData from './ExtraDataTableRow';
import axios from 'axios';

export default {
    components: {
        appExtraData: ExtraData
    },
    data() {
        return {
            extra_datas: [],
            filter_term_ed: ''
        };
    },
    computed: {
        filteredExtraDatas() {
            return this.extra_datas.filter(el => {
                if (!el.name) {
                    return false;
                }
                return el.name.toLowerCase().match(this.filter_term_ed.toLowerCase());
            });
        }
    },
    created() {
        this.fetchExtraDatas();
    },
    methods: {
        fetchExtraDatas() {
            axios
                .get('/api/classifiers/extra-data')
                .then(res => {
                    this.extra_datas = res.data;
                })
                .catch(error => console.error(error));
        },
        removeExtraData(index) {
            this.extra_datas.splice(index, 1);
        }
    }
};
</script>
