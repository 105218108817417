<template>
    <div class="page-wrapper">
        <div class="container-fluid">
            <div class="row page-titles hidden-sm-up">
                <div class="col-md-5 align-self-center">
                    <h4 class="text-themecolor">FSP and Application Conditions</h4>
                </div>
            </div>
            <div class="card">
                <ul class="nav nav-tabs profile-tab" role="tablist">
                    <li class="nav-item">
                        <router-link :to="{ name: 'fsp_conditions' }" tag="a" class="nav-link" exact>
                            <span class="hidden-sm-up">
                                <i class="ti-layout-media-left"></i>
                            </span>
                            <span class="hidden-xs-down">
                                Conditions
                            </span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'structure.groups' }" tag="a" class="nav-link" exact>
                            <span class="hidden-sm-up">
                                <i class="fa fa-table"></i>
                            </span>
                            <span class="hidden-xs-down">
                                Table Groups
                            </span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'fsp_cond.structure' }" tag="a" class="nav-link" exact>
                            <span class="hidden-sm-up">
                                <i class="fa fa-comments-o"></i>
                            </span>
                            <span class="hidden-xs-down">
                                Tables FSP
                            </span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'fsp_conditions.check.fsp' }" tag="a" class="nav-link" exact>
                            <span class="hidden-sm-up">
                                <i class="fa fa-check"></i>
                            </span>
                            <span class="hidden-xs-down">
                                Check FSP <i v-if="hasProposals" class="fa fa-exclamation-circle text-success"></i>
                            </span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'fsp_conditions.flow.chart.defaults' }" tag="a" class="nav-link" exact>
                            <span class="hidden-sm-up">
                                <i class="fa fa-check"></i>
                            </span>
                            <span class="hidden-xs-down">
                                Flow Chart Defaults
                            </span>
                        </router-link>
                    </li>
                </ul>
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters([ 'hasProposals', ]),
    },
    mounted() {
        this.$store.dispatch('checkIfAppHasProposals');
    },
};
</script>
