<template>
    <section
        id="wrapper"
        class="login-register login-sidebar"
        style="background-image:url('/assets/img/login-register.jpg');"
    >
        <div class="login-box card">
            <div class="card-body">
                <form id="loginform" method="POST" class="form-horizontal form-material" @keyup.enter="login">
                    <a href="javascript:void(0)" class="text-center db">
                        <img src="/assets/img/fooddocs_logo_green_26px.png" alt="Login" />
                    </a>
                    <div class="form-group m-t-40">
                        <div class="col-xs-12">
                            <input
                                id="email"
                                ref="email"
                                v-model="form.username"
                                v-validate="'required|email'"
                                type="email"
                                placeholder="Email"
                                data-vv-name="email"
                                data-vv-as="Email"
                                :class="{ 'form-control': true, }"
                            />
                            <span v-show="errors.has('email')" class="invalid-feedback" role="alert">
                                <strong>{{ errors.first('email') }}</strong>
                            </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-xs-12">
                            <input
                                id="password"
                                v-model="form.password"
                                v-validate="'required'"
                                type="password"
                                placeholder="Password"
                                data-vv-name="password"
                                data-vv-as="Password"
                                :class="{ 'form-control': true }"
                            />
                            <span v-show="errors.has('password')" class="invalid-feedback" role="alert">
                                <strong>{{ errors.first('password') }}</strong>
                            </span>
                        </div>
                    </div>
                    <div class="form-group text-center m-t-20">
                        <div class="col-xs-12">
                            <button
                                type="button"
                                class="btn btn-info btn-lg btn-block text-uppercase btn-rounded"
                                @click="login"
                            >
                                Login
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import AuthService from '@/services/AuthService';

export default {
    data() {
        return {
            form: {
                username: '',
                password: ''
            }
        };
    },
    mounted() {
        this.$refs.email.focus();
    },
    methods: {
        async login() {
            this.$validator.validate().then(async isValid => {
                if (isValid) {
                    if (await AuthService.login(this.form)) {
                        await this.$router.push('/');
                    }
                } else {
                    this.$toastr.e('Form is not valild!');
                }
            });
        }
    }
};
</script>

<style>
.login-register {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
    width: 100%;
    padding: 10% 0;
    position: fixed;
}

.login-box {
    width: 350px;
    margin: 0 auto;
}

.login-box .footer {
    width: 100%;
    left: 0px;
    right: 0px;
}

.login-box .social {
    display: block;
    margin-bottom: 30px;
}

#recoverform {
    display: none;
}

.login-sidebar {
    padding: 0px;
    margin-top: 0px;
}

.login-sidebar .login-box {
    right: 0px;
    position: absolute;
    height: 100%;
}
</style>