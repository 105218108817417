<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <form @submit.prevent="saveMobileAppVersions">
                    <h5>Mobile App</h5>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label for="android-version-min">Minimum Android version</label>
                                        <input
                                            id="android-version-min"
                                            v-model="mobile_app_versions.minimum.android"
                                            type="text"
                                            class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label for="android-version-latest">Latest Android version</label>
                                        <input
                                            id="android-version-latest"
                                            v-model="mobile_app_versions.latest.android"
                                            type="text"
                                            class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label for="ios-version-min">Minimum iOS version</label>
                                        <input
                                            id="ios-version-min"
                                            v-model="mobile_app_versions.minimum.ios"
                                            type="text"
                                            class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label for="ios-version-latest">Latest iOS version</label>
                                        <input
                                            id="ios-version-latest"
                                            v-model="mobile_app_versions.latest.ios"
                                            type="text"
                                            class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-success">{{ updateOldMobileAppVers ? 'Update' : 'Save' }}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            mobile_app_versions: {
                latest: { android: '', ios: '' },
                minimum: { android: '', ios: '' }
            },
            updateMobileAppVers: false
        };
    },
    computed: {
        updateOldMobileAppVers() {
            return this.updateMobileAppVers;
        }
    },
    created() {
        this.fetchConfig();
    },
    methods: {
        saveMobileAppVersions() {
            if (this.updateOldMobileAppVers) {
                this.updateMobileAppVersions();
            } else {
                this.storeMobileAppVersions();
            }
        },
        updateMobileAppVersions() {
            const form = {
                type: 'mobile_app_versions',
                value: {
                    latest: {
                        android: this.mobile_app_versions.latest.android,
                        ios: this.mobile_app_versions.latest.ios
                    },
                    minimum: {
                        android: this.mobile_app_versions.minimum.android,
                        ios: this.mobile_app_versions.minimum.ios
                    }
                }
            };
            axios
                .put(`/api/superadmin/config/update`, form)
                .then(res => {
                    this.$toastr.s(res.data.message);
                })
                .catch(err => {
                    Object.values(err.response.data.errors).map(err => {
                        err.map(er => {
                            this.$toastr.e(er);
                        });
                    });
                });
        },
        storeMobileAppVersions() {
            const form = {
                type: 'mobile_app_versions',
                value: {
                    latest: {
                        android: this.mobile_app_versions.latest.android,
                        ios: this.mobile_app_versions.latest.ios
                    },
                    minimum: {
                        android: this.mobile_app_versions.minimum.android,
                        ios: this.mobile_app_versions.minimum.ios
                    }
                }
            };
            axios.post(`/api/superadmin/config/store`, form).then(res => {
                this.$toastr.s(res.data.message);
                this.updateMobileAppVers = true;
            });
        },
        fetchConfig() {
            axios
                .get('/api/superadmin/config')
                .then(res => {
                    const mobileAppVers = res.data.find(conf => conf.type === 'mobile_app_versions').value;
                    if (mobileAppVers) {
                        this.mobile_app_versions = mobileAppVers;
                        this.updateMobileAppVers = true;
                    }
                })
                .catch(error => console.error(error));
        }
    }
};
</script>
