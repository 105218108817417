<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <app-loader v-if="loading" size="medium" line-fg-color="#5ec58c" message="Loading..."></app-loader>
            <div v-show="!loading" class="modal-container">
                <div class="condition-modal-header">
                    <div>
                        <button class="btn btn-link" @click="close"><span style="font-size: 1.5rem">x</span></button>
                    </div>
                </div>
                <div class="col">
                </div>
                <div class="modal-body mt-5">
                    <slot name="body">
                        <HazardEstimationDropdown
                            :row="row"
                            :selected-group-id="selectedGroupId"
                        />
                        <SelectRelatedHazards
                            :row="row"
                            :selected-group-id="selectedGroupId"
                        />
                        <div class="mt-5">
                            <span>Filter Proposals:</span>
                            <v-select
                                v-model="selectedCountries"
                                :options="countriesOptions"
                                placeholder="- choose country -"
                                multiple
                                :get-option-label="getLabel"
                                label="id"
                                class="mt-3"
                            >
                                <span slot="no-options">Nothing found</span>
                            </v-select>
                            <v-select
                                v-model="selectedBusinessActivities"
                                :options="businessActivitiesOptions"
                                placeholder="- choose business activity -"
                                multiple
                                :get-option-label="getLabel"
                                label="id"
                                class="mt-3"
                            >
                                <span slot="no-options">Nothing found</span>
                            </v-select>
                        </div>
                        <ConditionGroup
                            v-if="selectedGroupId"
                            :row="row"
                            :selected-group-id="selectedGroupId"
                            :all-conditions="allConditions"
                            @rejectProposal="(conditionId, proposalId, callback, notifyUser) => rejectProposal(conditionId, proposalId, callback, notifyUser)"
                            @acceptProposal="(conditionId, proposalId, callback, notifyUser) => acceptProposal(conditionId, proposalId, callback, notifyUser)"
                            @createProposal="(conditionId, proposalId, callback, notifyUser) => createProposal(conditionId, proposalId, callback, notifyUser)"
                        />
                        <SingleCondition
                            v-else
                            :condition="selectedCondition"
                            :all-conditions="allConditions"
                            @rejectProposal="(conditionId, proposalId, callback, notifyUser) => rejectProposal(conditionId, proposalId, callback, notifyUser)"
                            @acceptProposal="(conditionId, proposalId, callback, notifyUser) => acceptProposal(conditionId, proposalId, callback, notifyUser)"
                            @createProposal="(conditionId, proposalId, callback, notifyUser) => createProposal(conditionId, proposalId, callback, notifyUser)"
                        />
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SingleCondition from './SingleCondition';
import ConditionGroup from './ConditionGroup';
import HazardEstimationDropdown from './HazardEstimationDropdown';
import SelectRelatedHazards from './SelectRelatedHazards';
import axios from 'axios';

export default {
    name: 'ConditionChangeModal',
    components: {
        ConditionGroup,
        SingleCondition,
        HazardEstimationDropdown,
        SelectRelatedHazards,
    },
    props: {
        row: {
            type: Object,
            required: true,
        },
        selectedCondition: {
            type: Object,
            default: () => {},
        },
        columns: {
            type: Array,
            required: true,
        },
        selectedGroupId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            form: {},
            id: '',
            url: '',
            currentFiles: [],
            loading: false,
            hasChanges: false,
            countriesOptions: [],
            selectedCountries: [],
            businessActivitiesOptions: [],
            selectedBusinessActivities: [],
        };
    },
    computed: {
        allConditions() {
            let allCons = [];
            let filterCountries = [];
            let filterBusinessActivities = [];

            this.selectedBusinessActivities.forEach(selection => {
                filterBusinessActivities.push(selection.id);
            });
            this.selectedCountries.forEach(selection => {
                filterCountries.push(selection.id);
            });

            if (this.columns?.length) {
                this.columns.forEach(column => {
                    column.children.forEach(cond => {
                        if (cond.level_number === 5) {
                            if (filterCountries.length === 0 && filterBusinessActivities.length === 0){
                                allCons.push({
                                    parent: {
                                        level_number: 4,
                                        id: column.id
                                    }, ...cond
                                });
                            } else {
                                let filteredProposals = [];

                                cond.text_change_proposals.forEach(proposal => {
                                    if (
                                        (filterCountries.length === 0 || filterCountries.includes(proposal.place.country_id)) && 
                                        (filterBusinessActivities.length === 0 || filterBusinessActivities.includes(proposal.place.business_activity_id))
                                    ) {
                                        filteredProposals.push(proposal);
                                    }
                                });

                                allCons.push({
                                    parent: {
                                        level_number: 4,
                                        id: column.id
                                    },
                                    ...cond,
                                    text_change_proposals: filteredProposals
                                });
                            }
                        }
                    });
                });
            }

            return allCons;
        },
    },
    async created() {
        try {
            await axios.get(`/api/classifiers/business-activities`).then(({data}) => {
                this.businessActivitiesOptions = data;
            });

            await axios.get(`/api/classifiers/countries`).then(({data}) => {
                this.countriesOptions = data;
            });
        } catch(error) {
            console.error(error);
            this.$toastr.e(error);
        }
    },
    methods: {
        close() {
            this.loading = this.hasChanges;
            this.$emit('close', this.hasChanges);
        },
        createProposal(conditionId, proposalId, callback, notifyUser) {
            this.processProposal(3, conditionId, proposalId, callback, 'Create new proposal?', notifyUser);
        },
        rejectProposal(conditionId, proposalId, callback, notifyUser) {
            this.processProposal(2, conditionId, proposalId, callback, 'Reject proposal?', notifyUser);
        },
        acceptProposal(conditionId, proposalId, callback, notifyUser) {
            this.processProposal(1, conditionId, proposalId, callback, 'Accept proposal?', notifyUser);
        },
        processProposal(decisionId, conditionId, proposalId, callback, confirmText, notifyUser) {
            if (!window.confirm(confirmText)) {
                return false;
            }

            this.hasChanges = true;
            this.loading = true;
            axios.put(`/api/superadmin/food-safety-plan-conditions/${conditionId}/proposals/${proposalId}`, {
                change_decision_type_id: decisionId,
                notify_users: notifyUser,
            }).then(() => {
                if (typeof callback !== 'undefined') {
                    callback();
                }
            }).catch(error => {
                this.$toastr.e(error?.response?.data?.message || 'error');
            }).finally(() => {
                this.loading = false;
            });
        },
        getLabel(option) {
            return `${option.name} (${option.id})`;
        },
    },
};
</script>

<style>
.modal-container {
    width: 90vw;
    height: 90vh;
    overflow: auto;
}

.condition-modal-header {
    box-shadow: inset 0px 1px 5px rgb(0 0 0 / 38%);
    position: absolute;
    background-color: white;
    z-index: 99999999;
    width: inherit;
    height: 3rem;
}

.condition-modal-header div {
    text-align: right;
    margin-right: 1rem;
}
</style>
