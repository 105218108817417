<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <input
                        v-model="filter_term_pg"
                        type="text"
                        class="form-control mb-3 col-7"
                        placeholder="Filter by Group"
                    />
                    <div class="col-5 mt-2">
                        <span class="pull-right">
                            <router-link tag="a" :to="{ name: 'smartfilter.pg.create' }">
                                New Product Group
                            </router-link>
                        </span>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="table-responsive">
                    <table v-if="filteredGroups.length > 0" class="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Tooltip</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <app-group
                                v-for="(group, index) in filteredGroups"
                                :key="group.id"
                                :index="index"
                                :group="group"
                                @removeGroup="removeGroup"
                            >
                            </app-group>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import appGroup from './ProductGroupTableRow';

export default {
    components: {
        appGroup
    },
    data() {
        return {
            groups: [],
            filter_term_pg: ''
        };
    },
    computed: {
        filteredGroups() {
            return this.groups.filter(el => {
                if (!el.name) {
                    return false;
                }
                return el.name.toLowerCase().match(this.filter_term_pg.toLowerCase());
            });
        }
    },
    created() {
        this.fetchGroups();
    },
    methods: {
        fetchGroups() {
            axios
                .get('/api/superadmin/lab-report/product-group')
                .then(res => {
                    this.groups = res.data;
                })
                .catch(error => console.error(error));
        },
        removeGroup(index) {
            this.groups.splice(index, 1);
        }
    }
};
</script>
