const tempOpts = [
    'once_an_hour',
    'once_a_day',
    'once_a_week',
    'other',
    'No fridges',
    'twice_a_day',
    'Automated sensors'
];
const bacteriaOpts = [
    'one_two_times_year',
    '2 times in a year',
    'four_times_year',
    'other',
    'not_done',
    'once a month'
];
const healtAndHygieneOpts = [
    '2 times in a year',
    'once_in_year',
    'after_every_two_years',
    'after_every_three_years',
    'after_every_four_years',
    'after_every_five_years',
    'after_every_six_years',
    'once_in_manning',
    'custom'
];
const threatOpts = [
    'automatedTemperatureControl',
    'storageEquipmentNew',
    'servingInMarmite',
    'slicer',
    'readyToEatFood',
    'sousVide',
    'freezedFoodTransportation',
    'warmFoodTransportation',
    'serve_raw_meat',
    'no_food_contact',
    'fritureFrying',
    'serve_raw_fish'
];

const employeeOrServiceOpts = ['employee', 'service'];

const waterAnalysesOpts = ['own_well', 'central_network', 'water_tanks', 'bottled_water'];

const ecoLabelOpts = ['yes', 'no'];

const extendingShelfLifeOpts = ['yes', 'no'];

const nullEmployeeServiceOpts = ['employee', 'service'];

const foodGroupTempOpts = ['', 'sub18', 'from2to6', 'over6', 'over63'];

export default {
    tempOpts,
    bacteriaOpts,
    healtAndHygieneOpts,
    threatOpts,
    employeeOrServiceOpts,
    waterAnalysesOpts,
    extendingShelfLifeOpts,
    nullEmployeeServiceOpts,
    foodGroupTempOpts,
    ecoLabelOpts
};
