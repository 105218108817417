<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <input
                        v-model="filter_term"
                        type="text"
                        class="form-control mb-3 col-7"
                        placeholder="Filter by Table Name"
                    />
                    <div class="col-5 mt-2">
                        <span class="pull-right">
                            <router-link tag="a" :to="{ name: 'fsp_cond.structure.create' }">
                                Add New Table
                            </router-link>
                        </span>
                    </div>
                </div>

                <div v-if="filteredStructures.length > 0" class="table-responsive">
                    <p class="text-success">
                        To reorder, drag a row to the desired position
                    </p>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Static Name</th>
                                <th>Group</th>
                                <th class="pointer" @click="orderBy('name.en')">
                                    Name
                                    <span v-html="order_field == 'name.en' ? active_sort_icon : sort_icon"></span>
                                </th>
                                <th>Rows/Columns</th>
                                <th></th>
                            </tr>
                        </thead>
                        <app-draggable v-model="reorderedStructures" v-bind="{ animation: 200 }" tag="tbody">
                            <app-structure
                                v-for="(table, index) in reorderedStructures"
                                :key="table.id"
                                :index="index"
                                :table="table"
                                @removeStructure="removeStructure"
                            ></app-structure>
                        </app-draggable>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appStructure from './FspConditionStructureTableRow';
import appDraggable from 'vuedraggable';
import axios from 'axios';
import _ from 'lodash';

export default {
    components: {
        appStructure,
        appDraggable
    },
    data() {
        return {
            tables: [],
            filter_term: '',
            order_field: 'order_number',
            order_dir: 'asc',
            sort_icon: '<i class="fa fa-sort"></i>'
        };
    },
    computed: {
        filteredStructures() {
            return this.tables.filter(el => {
                const englishTranslation = this.decipherAPIString(el);
                if (!englishTranslation) {
                    return false;
                }
                return englishTranslation.toLowerCase().match(this.filter_term.toLowerCase());
            });
        },
        orderedStructures() {
            return _.orderBy(this.filteredStructures, this.order_field, this.order_dir);
        },
        reorderedStructures: {
            get() {
                return this.orderedStructures;
            },
            set(tables) {
                axios.put('/api/superadmin/fsp-tables/reorder', {sections: tables}).then(() => {
                    this.$toastr.i('Sections reordered');
                    this.fetchFspConditionTables();
                }).catch(() => {
                    this.$toastr.i('Error, sections were not reordered, please refresh!');
                });
            }
        },
        active_sort_icon() {
            if (this.order_dir == 'desc') {
                return '<i class="fa fa-sort-down"></i>';
            } else {
                return '<i class="fa fa-sort-up"></i>';
            }
        }
    },
    created() {
        this.fetchFspConditionTables();
    },
    methods: {
        decipherAPIString(string) {
            try {
                return string.section_translations.filter(s => s.language_id === 43)[0].translation_text;
            } catch (e) {
                try {
                    return string.static_name;
                } catch (e) {
                    return 'not stored';
                }            }
        },
        fetchFspConditionTables() {
            axios.get(`/api/superadmin/fsp-tables?documentTypeId=1`).then(res => {
                this.tables = res.data.data;
            }).catch(error => {
                console.error(error);
            });
        },
        orderBy(field) {
            this.order_field = field;
            if (this.order_dir == 'desc') {
                this.order_dir = 'asc';
            } else {
                this.order_dir = 'desc';
            }
        },
        removeStructure(index) {
            this.tables.splice(index, 1);
        }
    }
};
</script>
