<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <h4>Edit Column for Conditions Structure</h4>
                        <div class="form-group row mb-1">
                            <label class="col-sm-2 col-form-label">Countries</label>
                            <div class="col-sm-10">
                                <app-multiselect
                                    v-model="selected_countries"
                                    :options="countries"
                                    :multiple="true"
                                    group-values="countries"
                                    group-label="continent"
                                    :group-select="true"
                                    placeholder="Type to search"
                                    track-by="name"
                                    label="name"
                                >
                                    <template slot="selection" slot-scope="{ values, isOpen }">
                                        <span v-if="values.length && !isOpen" class="multiselect__single">
                                            {{ values.length }} countries selected
                                        </span>
                                    </template>
                                    <span slot="noResult">Nothing found</span>
                                </app-multiselect>
                            </div>
                        </div>
                        <!-- Standard types -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-2 col-form-label">Standard type</label>
                            <div class="col-sm-10">
                                <v-select
                                    v-model="standards"
                                    v-validate="'required'"
                                    :close-on-select="false"
                                    multiple
                                    :options="standardOptions"
                                    label="name"
                                    placeholder="- choose -"
                                    data-vv-name="standard_options"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                                <span v-show="errors.has('standard_options')" class="text-danger" role="alert">
                                    <strong>{{ errors.first('standard_options') }}</strong>
                                </span>
                            </div>
                        </div>
                        <!-- Static name -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-2 col-form-label">Static name</label>
                            <div class="col-sm-10">
                                <v-select
                                    v-model="staticName"
                                    :options="staticNames"
                                    label="Static name"
                                    placeholder="- choose -"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                    </div>
                </div>
                <button v-if="isEdit" type="button" class="btn btn-success mx-1" @click="update">Update</button>
                <button v-if="isCreate" type="button" class="btn btn-success mx-1" @click="update(false)">Save</button>
                <button v-if="isCreate" type="button" class="btn btn-success mx-1" @click="update(true)">Save and Add New Column</button>
            </div>
        </div>
    </div>
</template>

<script>
import appMultiselect from 'vue-multiselect';
import axios from 'axios';

export default {
    components: {
        appMultiselect
    },
    data() {
        return {
            staticName: '',
            staticNames: [],
            form: {
                name: {}
            },
            column: {},
            langs: [],
            countries: [],
            selected_countries: [],
            applicationType: null,
            standards: [],
            standardOptions: [],
            parentId: null,
        };
    },
    computed: {
        isCreate() {
            return this.applicationType === 'create';
        },
        isEdit() {
            return this.applicationType === 'edit';
        },
    },
    created() {
        this.getApplicationType();
        this.getLanguages();
        this.getStaticNames();
        this.getColumn();
    },
    methods: {
        update(newColumn) {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    let selecteCountries = [];
                    this.selected_countries.forEach(item => {
                        selecteCountries.push({id: item.id});
                    });
                    let translations = [];
                    Object.keys(this.form.name).forEach(key => {
                        if(this.form.name[key]){
                            translations.push({
                                language: key,
                                translation_text: this.form.name[key]
                            });
                        }
                    });
                    this.form.countries = selecteCountries;
                    this.form.standards = this.standards;
                    this.form.section_translations = translations;
                    this.form.static_name = this.staticName;
                    if(this.isEdit){
                        axios.put(
                            '/api/superadmin/fsp-table-row-columns/' +
                            this.$route.params.fsp_cond_structure_column_id,
                            this.form
                        )
                            .then(() => {
                                this.$toastr.i(`Condition ${this.$route.params.fsp_cond_structure_column_id} changed!`);
                                this.$router.push({ name: 'fsp_cond.structure' });
                            })
                            .catch(error => console.error(error));
                    } else {
                        this.form.parent_fsp_section_id = parseInt(this.$route.params.fsp_cond_structure_row_id, 0);
                        axios.post(
                            '/api/superadmin/fsp-table-row-columns/', this.form)
                            .then(() => {
                                this.$toastr.i(`Condition added!`);
                                if(newColumn){
                                    this.form.name = {};
                                } else {
                                    this.$router.push({ name: 'fsp_cond.structure' });
                                }
                            })
                            .catch(error => console.error(error));
                    }
                }
            });
        },
        getTable(id) {
            axios.get(`/api/superadmin/fsp-table-rows/${id}`).then(response => {
                if(response.data){
                    this.standardOptions = response.data.standards;
                }
            });
        },
        getLanguages() {
            axios
                .get('/api/superadmin/food-safety-plan-conditions/languages')
                .then(res => {
                    this.langs = res.data;
                })
                .catch(error => console.error(error));
        },
        getStaticNames() {
            axios
                .get('/api/superadmin/fsp-table-row-columns/static-names')
                .then(({data}) => {
                    this.staticNames = data;
                })
                .catch(error => console.error(error));
        },
        getColumn() {
            if(this.isEdit){
                axios.get(
                    '/api/superadmin/fsp-table-row-columns/' +
                    this.$route.params.fsp_cond_structure_column_id
                )
                    .then(res => {
                        let translations = {};
                        res.data.section_translations.forEach(lang => {
                            translations = {[lang.language]: lang.translation_text , ...translations};
                        });
                        this.form.name = translations;
                        this.selected_countries = res.data.countries;
                        this.parentId = res.data.parent_fsp_section_id;
                        this.standards = res.data.standards;
                        this.staticName = res.data.static_name;
                    })
                    .then(() => {
                        this.getCountries();
                        this.getTable(this.parentId);
                    })
                    .catch(error => console.error(error));
            } else {
                this.getCountries();
                this.getTable(parseInt(this.$route.params.fsp_cond_structure_row_id, 0));
            }
        },
        getCountries() {
            axios
                .get('/api/classifiers/countries?simple-format=true')
                .then(res => {
                    this.countries = res.data;
                })
                .catch(error => console.error(error));
        },
        getApplicationType() {
            if(this.$route.name === 'fsp_cond.structure.column.create'){
                this.applicationType = 'create';
            } else if(this.$route.name === 'fsp_cond.structure.column.edit') {
                this.applicationType = 'edit';
            }
        },
    }
};
</script>
