<template>
    <div class="page-wrapper">
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div v-if="user_role == 'admin' || user_role == 'superadmin'" class="col-3">
                            <div class="row">
                                <div class="col-9">
                                    <h4>
                                        <strong>VISION</strong>: <strong>Quality stamp</strong>
                                        for food handling companies.
                                        <strong>Transparent</strong> food safety
                                        <strong>across the whole</strong> supply chain
                                    </h4>
                                    <br />
                                    <h4><strong>MISSION</strong>: To become #1 food safety solution in the world</h4>
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div class="col-9">
                            <h4>Team members</h4>
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Tasks</th>
                                            <th>Contact</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><strong>TOTAL (16)</strong></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td><strong>STRATEGIC TEAM (4)</strong></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Katrin Liivat</td>
                                            <td>katrin.liivat@fooddocs.ee</td>
                                            <td>Marketing, sales, finances</td>
                                            <td>+372 503 8997</td>
                                        </tr>
                                        <tr>
                                            <td>Uku-Rasmus Lind</td>
                                            <td>uku-rasmus.lind@fooddocs.com</td>
                                            <td>Chief Technology Officer</td>
                                            <td>+372 523 1520</td>
                                        </tr>
                                        <tr>
                                            <td>Karin Repp</td>
                                            <td>karin.repp@fooddocs.ee</td>
                                            <td>Product owner, hardware</td>
                                            <td>+372 5155 392</td>
                                        </tr>
                                        <tr>
                                            <td>Markko Karu</td>
                                            <td>hello@markkokaru.com</td>
                                            <td>Product analytics, designer</td>
                                            <td>+372 5667 2111</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>DEVELOPMENT TEAM (6)</strong>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Keit Reitel</td>
                                            <td>keit.reitel@fooddocs.com</td>
                                            <td>Product manager, Food technologist</td>
                                            <td>+372 5348 7859</td>
                                        </tr>
                                        <tr>
                                            <td>Karel Paan</td>
                                            <td>karel.paan@fooddocs.com</td>
                                            <td>Front and App Developer</td>
                                            <td>+372 5669 0151</td>
                                        </tr>
                                        <tr>
                                            <td>Martin Toode</td>
                                            <td>martin.toode@fooddocs.com</td>
                                            <td>Front Developer</td>
                                            <td>+372 511 6044</td>
                                        </tr>
                                        <tr>
                                            <td>Helena Väinmaa</td>
                                            <td>helena.vainmaa@fooddocs.com</td>
                                            <td>Front Developer</td>
                                            <td>+372 5628 5268</td>
                                        </tr>
                                        <tr>
                                            <td>Sander Laasik</td>
                                            <td>sander.laasik@fooddocs.com</td>
                                            <td>Back Developer</td>
                                            <td>+372 5373 3259</td>
                                        </tr>
                                        <tr>
                                            <td>Karli Kirsimäe</td>
                                            <td>karli.kirsimae@fooddocs.com</td>
                                            <td>Back Developer</td>
                                            <td>+372 5395 4616</td>
                                        </tr>
                                        <tr>
                                            <td><strong>MARKETING TEAM (2)</strong></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Karin Kustavus</td>
                                            <td>karin.kustavus@fooddocs.com</td>
                                            <td>Marketing strategy lead</td>
                                            <td>+372 5621 1461</td>
                                        </tr>
                                        <tr>
                                            <td>Jete Nelke</td>
                                            <td>jete.nelke@fooddocs.com</td>
                                            <td>Head of digital marketing</td>
                                            <td>+372 5813 4212</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>CUSTOMER SUPPORT / SALES TEAM (3)</strong>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Galina Muiźnieks</td>
                                            <td>galina@fooddocs.com</td>
                                            <td>Head of Customer Support</td>
                                            <td>+372 5622 5758</td>
                                        </tr>
                                        <tr>
                                            <td>Eleri Lipping</td>
                                            <td>eleri@fooddocs.com</td>
                                            <td>Head of Sales</td>
                                            <td>+372 5866 6173</td>
                                        </tr>
                                        <tr>
                                            <td>Violetta Njunina</td>
                                            <td>violetta@fooddocs.com</td>
                                            <td>Head of Sales</td>
                                            <td>+372 5558 8741</td>
                                        </tr>
                                        <tr>
                                            <td><strong>ADMINISTRATIVE (1)</strong></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Geli Nõlvand</td>
                                            <td>geli.nolvand@fooddocs.ee</td>
                                            <td>Accounting</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import AuthService from '@/services/AuthService';

export default {
    components: {
    },
    data() {
        return {
            show_month_data_modal: false,

            last_month_revenue_options: {},
            last_month_revenue_series: [],

            user_role: null,
            statistics: {},
            usersList: null
        };
    },
    computed: {
        userRole() {
            return this.$store.getters.getUserRole;
        }
    },
    created() {
        this.getUserRole();
    },
    methods: {
        showMonthDataModal() {
            this.show_month_data_modal = true;
        },
        closeMonthDataModal() {
            this.show_month_data_modal = false;
        },

        getUserRole() {
            axios.get('/api/profile').then(res => {
                if (res.data.role == null || res.data.role == 0) {
                    AuthService.logout();
                }
                this.user_role = res.data.role;
            });
        }
    }
};
</script>
