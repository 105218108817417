<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <input v-model="filter_ba_lt" type="text" class="form-control mb-3" placeholder="Filter by Activity" />
                <div class="clearfix"></div>
                <table v-if="filteredLTs.length > 0" class="table table-striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Business Activity</th>
                            <th>Location Type</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <app-balt v-for="(balt, index) in filteredLTs" :key="balt.id" :index="index" :balt="balt">
                        </app-balt>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import BALT from './LocationTypeTableRow';
import axios from 'axios';

export default {
    components: {
        appBalt: BALT
    },
    data() {
        return {
            business_activity_lts: [],
            filter_ba_lt: ''
        };
    },
    computed: {
        filteredLTs() {
            return this.business_activity_lts.filter(el => {
                if (!el.name) {
                    return false;
                }
                return el.name.toLowerCase().match(this.filter_ba_lt.toLowerCase());
            });
        }
    },
    created() {
        this.fetchBusinessActivities();
    },
    methods: {
        fetchBusinessActivities() {
            axios
                .get('/api/classifiers/business-activities')
                .then(res => {
                    this.business_activity_lts = res.data;
                })
                .catch(error => console.error(error));
        }
    }
};
</script>
