import Vue from 'vue';
import Vuex from 'vuex';

import login_logout from './modules/login_logout';
import companies from './modules/companies/companies';
import places from './modules/places/places';
import loader from './modules/loader';
import user from './modules/user/user';
import note_reminders from './modules/companies/note_reminders';
import fsp_conditions from '../components/fsp_conditions/store/index';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        login_logout,
        companies,
        places,
        loader,
        user,
        note_reminders,
        fsp_conditions
    }
});
