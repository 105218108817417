<template>
    <tr :id="'product_group_' + group.id">
        <td>{{ index + 1 }}</td>
        <td v-if="!editing">{{ group.name }}</td>
        <td v-else>
            <input
                id="name"
                v-model="form.name"
                v-validate="'required'"
                type="text"
                data-vv-name="name"
                data-vv-as="Name"
                :class="{ 'form-control': true, 'is-invalid': errors.has('name') }"
            />
            <span v-show="errors.has('name')" class="invalid-feedback" role="alert">
                <strong>{{ errors.first('name') }}</strong>
            </span>
        </td>
        <td v-if="!editing">{{ group.tooltip }}</td>
        <td v-else>
            <input id="tooltip" v-model="form.tooltip" type="text" class="form-control" />
        </td>
        <td>
            <div class="pull-right">
                <a v-if="!editing" href="javascript:void(0)" @click="edit(group)">Edit</a>
                <a v-else href="javascript:void(0)" @click="cancelEdit(group)">Cancel</a>
                |
                <a v-if="!editing" href="javascript:void(0)" @click="destroy(group.id)">Delete</a>
                <a v-else href="javascript:void(0)" @click="update(group.id)">Update</a>
            </div>
        </td>
    </tr>
</template>

<script>
import axios from 'axios';

export default {
    props: ['group', 'index'],
    data() {
        return {
            editing: false,
            form: {},
            original: {}
        };
    },
    methods: {
        edit(process) {
            this.form = process;
            this.original = Object.assign({}, process);
            this.editing = true;
        },
        cancelEdit(process) {
            Object.assign(process, this.original);
            this.form = this.original = null;
            this.editing = false;
        },
        update(id) {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    axios.put(`/api/superadmin/product-group/${id}/update`, this.form).then(res => {
                        this.$toastr.i(res.data.message);
                        this.editing = false;
                    });
                }
            });
        },
        destroy(id) {
            if (!window.confirm('Delete?')) {
                return false;
            }
            axios
                .delete(`/api/superadmin/product-group/${id}/destroy`)
                .then(res => {
                    this.$toastr.i(res.data.message);
                    this.$emit('removeGroup', this.index);
                })
                .catch(error => {
                    this.$toastr.e(error.response.data.message);
                });
        }
    }
};
</script>
