<template>
    <tr :id="bapg.id">
        <td>{{ index + 1 }}</td>
        <td>{{ bapg.product_group.name }}</td>
        <td>{{ bapg.business_activity.name }}</td>
        <td>{{ bapg.extra_data != null ? bapg.extra_data.name : '-' }}</td>
        <td>
            <span class="pull-right">
                <a href="javascript:void(0)" @click="destroy(bapg.id)">Delete</a>
            </span>
        </td>
    </tr>
</template>

<script>
import axios from 'axios';

export default {
    props: ['bapg', 'index'],
    methods: {
        destroy(id) {
            if (!window.confirm('Delete?')) {
                return false;
            }
            axios
                .delete('/api/superadmin/business-activity/product-group/' + id + '/destroy')
                .then(res => {
                    this.$toastr.i(res.data.message);
                    this.$emit('removeBaProductGroup', this.index);
                })
                .catch(error => console.error(error));
        }
    }
};
</script>
