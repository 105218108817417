<template>
    <tr :id="bafg.id">
        <td>{{ index + 1 }}</td>
        <td>{{ bafg.food_group.name }}</td>
        <td>{{ bafg.business_activity.name }}</td>
        <td>{{ bafg.extra_data != null ? bafg.extra_data.name : '-' }}</td>
        <td>
            <span class="pull-right">
                <a href="javascript:void(0)" @click="destroy(bafg.id)">Delete</a>
            </span>
        </td>
    </tr>
</template>

<script>
import axios from 'axios';

export default {
    props: ['bafg', 'index'],
    methods: {
        destroy(id) {
            if (!window.confirm('Delete?')) {
                return false;
            }
            axios
                .delete('/api/superadmin/business-activity/food-group/' + id + '/destroy')
                .then(res => {
                    this.$toastr.i(res.data.message);
                    this.$emit('removeBaFoodGroup', this.index);
                })
                .catch(error => console.error(error));
        }
    }
};
</script>
