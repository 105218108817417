import { vm } from '@/main';
import axios from 'axios';

class AuthService {
    constructor() {
        if (AuthService.instance) {
            return AuthService.instance;
        }

        this._isAuthorized = !!localStorage.getItem('access_token');

        AuthService.instance = this;
    }

    get isAuthorized() {
        return this._isAuthorized;
    }

    async login({ username, password }) {
        try {
            const res = await axios.post('/oauth/token', {
                username,
                password,
            });

            vm.$store.commit('SET_ACCESS_TOKEN', res.data);

            axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`;

            this._isAuthorized = true;

            await vm.$store.dispatch('fetchCompanies');
            return true;
        } catch (error) {
            vm.$toastr.e(error?.response?.data?.message || 'error');
        }

        return false;
    }

    async logout(values = {}) {
        const { skipRequest } = values;

        if (!this._isAuthorized || !localStorage.getItem('access_token')) {
            return;
        }

        this._isAuthorized = false;
        vm.$store.commit('removeToken');

        if (skipRequest) {
            clearStorage();

            await vm.$router.push({ name: 'login' });
        } else {
            return axios
                .get('/api/logout')
                .catch(() => console.error('Already logged out'))
                .finally(() => {
                    clearStorage();

                    vm.$router.push({ name: 'login' });
                });
        }
    }
}

function clearStorage() {
    localStorage.clear();
    sessionStorage.clear();
}

export default new AuthService();