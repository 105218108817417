<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <h4>Edit Table Group</h4>
                        <div class="form-group row mb-1">
                            <label class="col-sm-2 col-form-label">Translation Key</label>
                            <div class="col-sm-10">
                                <input
                                    v-model="form.name"
                                    v-validate="'required'"
                                    type="text"
                                    data-vv-name="name"
                                    data-vv-as="translation key"
                                    :class="{ 'form-control': true, 'is-invalid': errors.has('name') }"
                                />
                                <span v-show="errors.has('name')" class="invalid-feedback" role="alert">
                                    <strong>{{ errors.first('name') }}</strong>
                                </span>
                            </div>
                        </div>
                        <!-- Standard types -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-2 col-form-label">Standard type</label>
                            <div class="col-sm-10">
                                <v-select
                                    v-model="form.selected_standards"
                                    :close-on-select="false"
                                    multiple
                                    :options="standardTypes"
                                    label="name"
                                    placeholder="- choose -"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- table type -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-2 col-form-label">Table type</label>
                            <div class="col-sm-10">
                                <select v-model="form.selected_type" class="form-control">
                                    <option value="1">Food Safety Plan</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <button v-if="isEdit" type="button" class="btn btn-success" @click="update">Update</button>
                <button v-if="isCreate" type="button" class="btn btn-success" @click="update(false)">Save</button>
                <button v-if="isCreate" type="button" class="btn btn-success" @click="update(true)">Save and Add New Group</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            form: {
                name: '',
                selected_standards: [],
                selected_type: '1',
            },
            standardTypes: [],
            applicationType: null,
        };
    },
    computed: {
        isCreate() {
            return this.applicationType === 'create';
        },
        isEdit() {
            return this.applicationType === 'edit';
        },
    },
    created() {
        this.getApplicationType();
        this.getGroup();
        this.getStandardTypes();
    },
    methods: {
        update(newGroup) {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    if(this.isEdit){
                        axios.put('/api/superadmin/fsp-table-groups/' +
                                this.$route.params.structure_group_id,
                        {
                            static_name: this.form.name,
                            standards: this.form.selected_standards,
                            fsp_document_type_id: parseInt(this.form.selected_type, 0)
                        })
                            .then(() => {
                                this.$toastr.i(`Table group ${this.$route.params.structure_group_id} changed!`);
                                this.$router.push({ name: 'structure.groups' });
                            })
                            .catch(error => console.error(error));
                    } else {
                        axios.post('/api/superadmin/fsp-table-groups/', {
                            static_name: this.form.name,
                            standards: this.form.selected_standards,
                            fsp_document_type_id: parseInt(this.form.selected_type, 0)
                        })
                            .then(() => {
                                this.$toastr.i(`Table group added!`);
                                if(newGroup){
                                    this.form.name = '';
                                } else {
                                    this.$router.push({ name: 'structure.groups' });
                                }
                            })
                            .catch(error => console.error(error));
                    }

                }
            });
        },
        getGroup() {
            if(this.isEdit){
                axios
                    .get(
                        '/api/superadmin/fsp-table-groups/' +
                        this.$route.params.structure_group_id
                    )
                    .then(res => {
                        this.form.name = res.data.static_name;
                        this.form.selected_standards = res.data.standards;
                        this.form.selected_type = res.data.fsp_document_type_id;
                    })
                    .catch(error => console.error(error));
            }
        },
        getStandardTypes() {
            axios.get('/api/classifiers/fsp-standard-types').then(types => {
                this.standardTypes = types.data;
            }).catch(error => console.error(error));
        },
        getApplicationType() {
            if(this.$route.name === 'structure.groups.create'){
                this.applicationType = 'create';
            } else if(this.$route.name === 'structure.groups.edit') {
                this.applicationType = 'edit';
            }
        },
    }
};
</script>
