<template>
    <tr>
        <td>{{ changelog.id }}</td>
        <td>{{ changelog.published_at | formatDate }}</td>
        <td>{{ changelog.title.et }}</td>
        <td v-tooltip.top-start="changelog.body.et" :inner-html.prop="changelog.intro.et | truncate"></td>
        <td v-tooltip.top-start="changelog.body.et" :inner-html.prop="changelog.body.et | truncate"></td>
        <td>
            <span v-for="(tag, i) in changelog.tags">
                {{ tag }}<span v-if="i !== changelog.tags.length - 1">,</span>
            </span>
        </td>
        <td>{{ changelog.storage_name ? 'Yes' : 'No' }}</td>
        <td>
            <toggle-button
                :value="changelog.visible == 1 ? true : false"
                color="#5ec58c"
                :labels="{ checked: 'yes', unchecked: 'no' }"
                @change="toggleVisibility(changelog.id)"
            />
        </td>
        <td v-if="!status_editing" @click="editStatus">
            {{ changelog.order_by }}
            <span class="ml-4"><i class="fa fa-pencil"></i></span>
        </td>
        <td v-else class="d-inline-block">
            <input
                id="order_by"
                v-model="changelog.order_by"
                v-validate="'required|numeric'"
                name="order_by"
                type="number"
                data-vv-name="order_by"
                data-vv-as="order_by"
                :class="{ 'custom-is-invalid': errors.has('order_by') }"
            />
            <span clas="ml-4" @click="updateOrder(changelog.id)"><i class="fa fa-check"></i></span>
        </td>
        <td>
            <a
                data-toggle="collapse"
                :href="'#countries_' + changelog.id"
                role="button"
                aria-expanded="false"
                :aria-controls="'countries_' + changelog.id"
            >
                Show countries
            </a>
            <div :id="'countries_' + changelog.id" class="collapse" style="list-style: none">
                {{ getCountriesFromArray(changelog) }}
            </div>
        </td>
        <td>
            <span class="pull-right">
                <router-link :to="{ name: 'pm.changelog.edit', params: { changelog_id: changelog.id } }" tag="a">
                    Edit
                </router-link>
                |
                <a href="javascript:void(0)" @click="destroy(changelog.id)">Delete</a>
            </span>
        </td>
    </tr>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

export default {
    props: ['changelog', 'index'],
    data() {
        return {
            status_editing: false
        };
    },
    methods: {
        editStatus() {
            this.status_editing = !this.status_editing;
        },
        destroy(id) {
            if (!window.confirm('Delete?')) {
                return false;
            }

            axios
                .delete('/api/superadmin/page-management/changelog/' + id + '/destroy')
                .then(res => {
                    this.$toastr.i(res.data.message);
                    this.$emit('removeChangelog', this.index);
                })
                .catch(error => {
                    this.$toastr.e(error.response.data.message);
                });
        },
        toggleVisibility(id) {
            axios
                .put('/api/superadmin/page-management/changelog/' + id + '/visible', {})
                .then(() => {
                    this.$toastr.i('Visibility updated');
                })
                .catch(error => console.error(error));
        },
        updateOrder(id) {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    axios
                        .put('/api/superadmin/page-management/changelog/' + id + '/order_by', {
                            order_by: this.changelog.order_by
                        })
                        .then(res => {
                            if (res.response && res.response.status == 422) {
                                this.$toastr.i('Invalid input given.');
                            } else {
                                this.$toastr.i(res.data.message);
                                this.editStatus();
                            }
                        })
                        .catch(error => console.error(error));
                }
            });
        },
        getCountriesFromArray(changelog) {
            return _.map(changelog.country, 'name').join(',');
        }
    }
};
</script>
