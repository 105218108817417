<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <input
                        v-model="filterTerm"
                        type="text"
                        class="form-control mb-3 col-7"
                        placeholder="Filter by Flow Chart Name"
                    />
                </div>
                <div class="form-group row mb-1">
                    <label class="col-sm-3 col-form-label">Countries</label>
                    <div class="col-sm-9">
                        <v-select
                            v-model="selectedCountry"
                            close-on-select
                            :options="countries"
                            label="name"
                            placeholder="Type to search"
                            @input="select"
                        >
                            <span slot="no-options">Nothing found</span>
                        </v-select>
                    </div>
                </div>
                <div v-if="filteredProcesses.length > 0" class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Count</th>
                                <th>Details</th>
                                <th></th>
                            </tr>
                        </thead>
                        <app-structure
                            v-for="(table, tableIndex) in filteredProcesses"
                            :key="tableIndex"
                            :index="tableIndex"
                            :table="table"
                            :countryId=selectedCountry.id
                            :countryName=selectedCountry.name
                        ></app-structure>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import appStructure from './FspConditionsFlowChartRow';
import axios from 'axios';

export default {
    components: {
        appStructure,
    },
    data() {
        return {
            tables: [],
            filterTerm: '',
            selectedCountry: [],
            countries: []
        };
    },
    computed: {
        filteredProcesses() {
            return this.tables.filter((el) => el.process_name.toLowerCase().match(this.filterTerm.toLowerCase()));
        },
    },
    created() {
        this.getCountries();
        this.getAllFlowChartDefaults();
    },
    methods: {
        getAllFlowChartDefaults() {
            axios.get('/api/superadmin/flow-chart-processes', {
                params: {
                    country_id: this.selectedCountry?.id,
                }
            })
            .then((res) => {
                this.tables = res.data;
            });
        },
        async getCountries() {
            try {
                this.countries = (await axios.get('/api/classifiers/countries'))?.data;
            } catch(error) {
                this.load_error = true;
                console.error(error);
            }
        },
        select() {
            this.getAllFlowChartDefaults();
        },
    },
};
</script>