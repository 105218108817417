<template>
    <div>
        <div v-for="(conditionGroup, name) in conditionGroups" :key="name" class="mt-3">
            <span class="condition-title"><i :class="`fa fa-${getIcon(name)} fsp-check-icon`"></i> {{ name }}</span>
            <div v-for="condition in conditionGroup">
                <Proposals
                    :proposal-groups="getConditionProposals(condition.id)"
                    :all-conditions="allConditions"
                    :condition="condition"
                    @rejectProposal="(conditionId, proposalId, callback, notifyUser) => $emit('rejectProposal', conditionId, proposalId, callback, notifyUser)"
                    @acceptProposal="(conditionId, proposalId, callback, notifyUser) => $emit('acceptProposal', conditionId, proposalId, callback, notifyUser)"
                    @createProposal="(conditionId, proposalId, callback, notifyUser) => $emit('createProposal', conditionId, proposalId, callback, notifyUser)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Proposals from './Proposals';
export default {
    name: 'ConditionGroup', components: { Proposals },
    props: {
        allConditions: {
            type: Array,
            required: true,
        },
        row: {
            type: Object,
            required: true,
        },
        selectedGroupId: {
            type: Number,
            default: null,
        },
    },
    computed: {
        group() {
            return this.row?.groups?.find(group => group.id === this.selectedGroupId);
        },
        conditionGroups() {
            const conditions = {};
            this.group?.members.forEach(member => {
                conditions[member.parent?.static_name] = [ member, ...(conditions?.[member.parent?.static_name] || []) ];
            });

            const keysToBeRemoved = [];
            Object.entries(conditions).forEach(([key, value]) => {
                const hasConditions = !!value.find((condition) => {
                    const proposals = this.getConditionProposals(condition.id);
                    return proposals && Object.keys(proposals).length;
                });

                if(!hasConditions) {
                    keysToBeRemoved.push(key);
                }
            });
            keysToBeRemoved.forEach((key) => {
                delete conditions[key];
            });

            return conditions;
        },
    },
    methods: {
        getCondition(conditionId) {
            return this.allConditions.find(con => con.id === conditionId);
        },
        getConditionProposals(conditionId) {
            const changes = {};
            const proposals = this.getCondition(conditionId)?.text_change_proposals;
            if(proposals?.length) {
                proposals.forEach(proposal => {
                    changes[proposal.language_id] = [ proposal, ...(changes[proposal.language_id] || []) ];
                });
            }
            return changes;
        },
        getIcon(name) {
            switch (name) {
                case 'hazard':
                    return 'exclamation-triangle';
                case 'check':
                    return 'eye';
                case 'do':
                    return 'hand-o-right';
                case 'act':
                    return 'arrow-right';
                case 'proof':
                    return 'flask';
                case 'verify':
                    return 'clipboard-check';
                default:
                    return 'info-circle';            }
        },
    },
};
</script>

<style scoped>
.condition-title {
    color: #212529;
    font-size: 1.7rem;
    font-weight: bold;
    line-height: 1.5;
    font-family: 'Rubik', sans-serif !important;
}
.fsp-check-icon {
    color: #00c06d !important;
}
</style>
