<template>
    <tr
        class="pointer"
        :class="{ 'trans-row-active': selected_ing_data == ing.id }"
        @click="selectIngData(ing.id, index)"
    >
        <td>{{ ing.id }}</td>
        <td>
            {{ ing.name[from_lang] | striphtml }}<br />
            {{ ing.alt_names[from_lang] | striphtml | truncate(100) }}<br />
        </td>
        <td>
            {{ ing.name[to_lang] | striphtml }}<br />
            {{ ing.alt_names[to_lang] | striphtml | truncate(100) }}<br />
        </td>
    </tr>
</template>

<script>
export default {
    props: ['ing', 'index', 'selected_ing_data', 'from_lang', 'to_lang'],
    data() {
        return {};
    },
    methods: {
        selectIngData(id, index) {
            this.$emit('selectIngData', { id: id, index: index });
        }
    }
};
</script>
<style scoped>
.trans-row-active {
    background: #def3e8 !important;
}
.table th,
.table td {
    padding: 0px !important;
}
</style>
