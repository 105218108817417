<template>
    <div>
        <div class="row page-titles hidden-sm-up">
            <div class="col-md-5 align-self-center">
                <h4 class="text-themecolor">New Microorganism Analysis</h4>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title hidden-xs-down">New Microorganism Analysis</h4>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Frequency</label>
                            <v-select
                                v-model="form.frequency"
                                :options="frequencyOptions"
                                label="name"
                                :on-change="frequencyChange"
                                :class="{ 'custom-is-invalid': frequency_has_error }"
                                placeholder="- choose -"
                            >
                                <span slot="no-options">Nothing found</span>
                            </v-select>
                            <input type="hidden" class="form-control is-invalid" />
                            <span v-if="frequency_has_error" class="invalid-feedback" role="alert">
                                <strong>Frequency required</strong>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Minimum allowed limit</label>
                            <input
                                id="minAllowedLimit"
                                v-model="form.minAllowedLimit"
                                v-validate="'required'"
                                type="text"
                                data-vv-name="minAllowedLimit"
                                data-vv-as="minAllowedLimit"
                                :class="{ 'form-control': true, 'is-invalid': errors.has('minAllowedLimit') }"
                            />
                            <span v-show="errors.has('minAllowedLimit')" class="invalid-feedback" role="alert">
                                <strong>{{ errors.first('minAllowedLimit') }}</strong>
                            </span>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Acceptable limit</label>
                            <input
                                id="acceptableAllowedLimit"
                                v-model="form.acceptableAllowedLimit"
                                v-validate="'required'"
                                type="text"
                                data-vv-name="acceptableAllowedLimit"
                                data-vv-as="acceptableAllowedLimit"
                                :class="{ 'form-control': true, 'is-invalid': errors.has('acceptableAllowedLimit') }"
                            />
                            <span v-show="errors.has('acceptableAllowedLimit')" class="invalid-feedback" role="alert">
                                <strong>{{ errors.first('acceptableAllowedLimit') }}</strong>
                            </span>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Critical limit</label>
                            <input
                                id="name"
                                v-model="form.criticalAllowedLimit"
                                v-validate="'required'"
                                type="text"
                                data-vv-name="criticalAllowedLimit"
                                data-vv-as="criticalAllowedLimit"
                                :class="{ 'form-control': true, 'is-invalid': errors.has('criticalAllowedLimit') }"
                            />
                            <span v-show="errors.has('criticalAllowedLimit')" class="invalid-feedback" role="alert">
                                <strong>{{ errors.first('criticalAllowedLimit') }}</strong>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Correcting Action</label>
                            <v-select
                                v-model="form.correcting_action"
                                :options="correctingActionOptions"
                                label="name"
                                :on-change="correctingActionChange"
                                :class="{ 'custom-is-invalid': correcting_action_has_error }"
                                placeholder="- choose -"
                            >
                                <span slot="no-options">Nothing found</span>
                            </v-select>
                            <input type="hidden" class="form-control is-invalid" />
                            <span v-if="correcting_action_has_error" class="invalid-feedback" role="alert">
                                <strong>Correcting action is required</strong>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Minimum production amount</label>
                            <input
                                id="minProductionAmount"
                                v-model="form.minProductionAmount"
                                type="text"
                                data-vv-name="minProductionAmount"
                                data-vv-as="minProductionAmount"
                                class="form-control"
                            />
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Minimum production unit</label>
                            <input
                                id="maxProductionAmount"
                                v-model="form.minProductionUnit"
                                type="text"
                                data-vv-name="minProductionUnit"
                                data-vv-as="minProductionUnit"
                                class="form-control"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Maximum production amount</label>
                            <input
                                id="maxProductionAmount"
                                v-model="form.maxProductionAmount"
                                type="text"
                                data-vv-name="maxProductionAmount"
                                data-vv-as="maxProductionAmount"
                                class="form-control"
                            />
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Maximum production unit</label>
                            <input
                                id="maxProductionUnit"
                                v-model="form.maxProductionUnit"
                                type="text"
                                data-vv-name="maxProductionUnit"
                                data-vv-as="maxProductionUnit"
                                class="form-control"
                            />
                        </div>
                    </div>
                </div>

                <button type="button" class="btn btn-success" @click="store">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // title: {},
            // intro: {},
            // body: {},
            // tag: "",
            // tags: [],
            form: {},
            // img_url: "",
            // preview_img_url: "",
            // body_required_et: '',
            tagOptions: [{ text: 'News' }, { text: 'Food safety' }, { text: 'FoodDocs in media' }],
            frequency_has_error: true,
            correcting_action_has_error: true,
            frequencyOptions: [
                { id: 'every batch', name: 'Every batch' },
                { id: 'daily', name: 'Daily' },
                { id: 'weekly', name: 'Weekly' },
                { id: 'quarterly', name: 'Quarterly' },
                { id: 'yearly', name: 'Yearly' }
            ],
            correctingActionOptions: [
                { name: 'Improving production hygiene' },
                { name: 'Changing raw material' },
                { name: 'Shorten the shelf-life of the product' }
            ]
        };
    },
    methods: {
        frequencyChange(obj) {
            this.frequency_has_error = false;
            if (obj == null) {
                this.frequency_has_error = true;
                this.form.frequency = null;
                return null;
            }
            return (this.form.frequency = obj.id);
        },
        correctingActionChange(obj) {
            this.correcting_action_has_error = false;
            if (obj == null) {
                this.correcting_action_has_error = true;
                this.form.correctingAction = null;
                return null;
            }
            return (this.form.correctingAction = obj.name);
        },
        // onImageLoad(dataUri) {
        //     Vue.set(this.form, "img", dataUri);
        // },
        // onPreviewImageLoad(dataUri) {
        //     Vue.set(this.form, "preview_img", dataUri);
        // },
        // getFileName(file) {
        //     Vue.set(this.form, "file_name", file.name);
        // },
        // getPreviewFileName(file) {
        //     Vue.set(this.form, "preview_file_name", file.name);
        // },
        // bodyChange({text}) {
        //     this.body_required_et = text;
        // },
        store() {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    // let updatedTags = [];
                    // this.tags.forEach(tag => {
                    //     updatedTags.push(tag.text);
                    // });
                    // this.form = {
                    //     title: this.title,
                    //     intro: this.intro,
                    //     body: this.body,
                    //     tags: updatedTags,
                    //     img: this.form.img,
                    //     file_name: this.form.file_name,
                    //     preview_img: this.form.preview_img,
                    //     preview_file_name: this.form.preview_file_name
                    // };
                    // axios.post('/api/superadmin/page-management/articles', this.form).then(res => {
                    //     this.$toastr.i("Article saved");
                    //     this.$router.push({name: 'pm.articles'});
                    // }).catch(error => console.error(error));
                }
            });
        }
    }
};
</script>
