<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <input
                        v-model="filter_ba_pg"
                        type="text"
                        class="form-control mb-3 col-7"
                        placeholder="Filter by Product Group"
                    />
                    <div class="col-5 mt-2">
                        <span class="pull-right">
                            <router-link tag="a" :to="{ name: 'smartfilter.bapg.create' }">
                                New Product Group
                            </router-link>
                        </span>
                    </div>
                </div>
                <div class="clearfix"></div>
                <table v-if="filteredPGs.length > 0" class="table table-striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Product Groups</th>
                            <th>Business Activity</th>
                            <th>Extra data</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <app-bapg
                            v-for="(bapg, index) in filteredPGs"
                            :key="bapg.id"
                            :index="index"
                            :bapg="bapg"
                            @removeBaProductGroup="removeBaProductGroup"
                        >
                        </app-bapg>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import appBapg from './ProductGroupTableRow';
import axios from 'axios';

export default {
    components: {
        appBapg
    },
    data() {
        return {
            groups: [],
            filter_ba_pg: ''
        };
    },
    computed: {
        filteredPGs() {
            return this.groups.filter(el => {
                if (!el.product_group.name) {
                    return false;
                }
                return el.product_group.name.toLowerCase().match(this.filter_ba_pg.toLowerCase());
            });
        }
    },
    created() {
        this.fetchProductGroups();
    },
    methods: {
        fetchProductGroups() {
            axios
                .get('/api/superadmin/business-activity/product-groups')
                .then(res => {
                    this.groups = res.data;
                })
                .catch(error => console.error(error));
        },
        removeBaProductGroup(index) {
            this.groups.splice(index, 1);
        }
    }
};
</script>
