<template>
    <tr>
        <td>{{ user.id }}</td>
        <td>{{ user.country ? user.country.name : '-' }}</td>
        <td>
            {{ user.lang }}
        </td>
        <td>{{ user.firstname ? user.firstname.substring(0, 20) : '-' }}</td>
        <td>{{ user.lastname ? user.lastname : '-' }}</td>
        <td>{{ user.username }}</td>
        <td>{{ user.phone ? user.phone : '-' }}</td>
        <td>{{ usedCoupons }}</td>
        <td v-if="!editing">{{ user.superadmin }}</td>
        <td v-else>
            <span v-if="translator_role">
                <input
                    v-model="form.superadmin"
                    v-validate="'required'"
                    type="text"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('role') }"
                    data-vv-name="role"
                    data-vv-as="Role"
                />
                <span v-show="errors.has('role')" class="invalid-feedback" role="alert">
                    <strong>{{ errors.first('role') }}</strong>
                </span>
                <small>Exmaple: "translator:en,ru" or "technolog|translator:en,ru"</small>
                <br />
                <toggle-button
                    :value="translator_role == true ? true : false"
                    :width="100"
                    class="mt-1"
                    color="#5ec58c"
                    :labels="{ checked: 'translator', unchecked: 'not translator' }"
                    @change="toggleForTranlatorRole"
                />
            </span>
            <span v-else>
                <select v-model="form.superadmin" class="form-control">
                    <option value="">-</option>
                    <option value="technolog">technolog</option>
                    <option value="admin">admin</option>
                    <option value="superadmin">superadmin</option>
                </select>
                <toggle-button
                    :value="translator_role == true ? true : false"
                    :width="100"
                    class="mt-1"
                    color="#5ec58c"
                    :labels="{ checked: 'translator', unchecked: 'not translator' }"
                    @change="toggleForTranlatorRole"
                />
            </span>
        </td>
        <td>
            <v-select
                v-if="editing"
                v-model="user.account_type"
                close-on-select
                :clearable="false"
                :options="accountTypes"
                label="name"
                placeholder="- choose -"
            ></v-select>
            <template v-else>{{ user.account_type && user.account_type.name || '-' }}</template>
        </td>
        <td>{{ user.created_at | formatDate }}</td>
        <td v-if="!editing">
            <span v-for="(country, index) in user.countries" :key="index">
                {{ country.name }}
                <span v-if="index != user.countries.length - 1">,</span>
            </span>
        </td>
        <td v-else>
            <v-select
                v-model="form.countries"
                :options="countryList"
                label="name"
                :close-on-select="false"
                placeholder="- choose -"
                multiple
            >
                <span slot="no-options">Nothing found</span>
            </v-select>
        </td>
        <td>
            <div class="pull-right">
                <a v-if="!editing" href="javascript:void(0)" @click="edit(user)">Edit | </a>
                <a v-if="!editing" href="javascript:void(0)" @click="$emit('showUserLogins', user.logins)">
                    Show logins |
                </a>
                <a v-if="editing" href="javascript:void(0)" @click="cancelEdit(user)">Cancel | </a>
                <a v-if="editing" href="javascript:void(0)" @click="update(user.id)">Update | </a>
                <a href="javascript:void(0)" @click="$emit('openPermissionsModal', user.id)">Edit permissions | </a>
                <a href="javascript:void(0)" @click="$emit('openPasswordModal', user)">Change password | </a>
                <a href="javascript:void(0)" class="text-danger" @click="$emit('deleteUser', user.id)">Delete</a>
            </div>
        </td>
    </tr>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        countryList: {
            type: Array,
            required: true,
        },
        accountTypes: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            editing: false,
            translator_role: false,
            form: {},
            original: {},
        };
    },
    computed: {
        usedCoupons() {
            if (this.user && this.user.used_coupons) {
                let coupons = [];
                this.user.used_coupons.forEach(coupon => {
                    coupons.push(coupon.coupon.code);
                });

                return coupons.join();
            } else {
                return '';
            }
        }
    },
    methods: {
        toggleForTranlatorRole() {
            this.translator_role = !this.translator_role;
        },
        edit(user) {
            this.form = user;
            this.original = Object.assign({}, user);
            this.editing = true;
        },
        cancelEdit(user) {
            Object.assign(user, this.original);
            this.form = this.original = null;
            this.editing = false;
        },
        update(id) {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    let formObj = {};
                    Object.assign(formObj, this.form);
                    formObj.countries = formObj.countries.map(country => country.id);
                    delete formObj['account_type'];
                    formObj.account_type_id = this.form.account_type.id;
                    axios.put('/api/superadmin/users/' + id + '/update', formObj).then(res => {
                        // eslint-disable-next-line vue/no-mutating-props
                        this.user.countries = this.form.countries;
                        this.$toastr.i(res.data.message);
                        this.editing = false;
                    });
                }
            });
        },
    }
};
</script>
