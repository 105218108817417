<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body col-8">
                <h2>{{ $route.params.tableName }}</h2>
                <h3 v-if="countryName">Country: {{ countryName }}</h3>
                <div class="">
                    <label>Type</label>
                    <div>
                        <b-form-group>
                            <b-form-radio-group v-model="selectedProcessDetails.type" name="type">
                                <template v-for="(p, i) in processDetails.types">
                                    <b-form-radio :value="p" :key="i">{{ `${p.name} (${p.cnt})` }}</b-form-radio>
                                </template>
                            </b-form-radio-group>
                        </b-form-group>
                    </div>
                </div>
                <div>
                    <label>Controls</label>
                    <div>
                        <b-form-group>
                            <b-form-checkbox-group v-model="selectedProcessDetails.controls" name="controls">
                                <template v-for="p in processDetails.controls">
                                    <b-form-checkbox :value="p">{{ `${p.name} (${p.cnt})` }}</b-form-checkbox>
                                </template>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </div>
                </div>
                <div>
                    <div>
                        <label>Description</label>
                    </div>
                    <div>
                        <b-form-group>
                            <b-form-radio-group v-model="selectedProcessDetails.description" name="description">
                                <template v-for="d in processDetails.descriptions">
                                    <b-form-radio :value="d">
                                        <textarea
                                            v-model="d.description"
                                            class="longText"
                                            cols="100%"
                                            rows="10"
                                            type="text"
                                        />
                                        <button class="btn btn-danger" @click="deleteDescription(d)">x</button>
                                    </b-form-radio>
                                </template>
                            </b-form-radio-group>
                        </b-form-group>
                        <button class="btn btn-success add-btn" @click="createNewDescription">Add</button>
                    </div>
                </div>
                <div>
                    <button class="btn btn-success save-btn" @click="saveProcessDetails">Save Changes</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

const isDefault = (item) => item.is_default === 1;

export default {
    data() {
        return {
            processDetails: {
                controls: [],
                descriptions: [],
                types: [],
            },
            selectedProcessDetails: {
                controls: [],
                description: null,
                type: null,
            },
        };
    },
    computed: {
        countryName() {
            return this.$route.params.countryName;
        },
        countryId() {
            return this.$route.params.countryId;
        }
    },
    created() {
        this.getProcessDetails();
    },
    methods: {
        getProcessDetails() {
            axios
                .get(`/api/superadmin/flow-chart-processes/${this.$route.params.tableName}`, {
                    params: {
                        country_id: this.countryId,
                    }
                })
                .then((res) => {
                    this.processDetails = res.data;
                })
                .catch((error) => {
                    this.$toastr.e(error.response.data.message);
                    console.error(error);
                })
                .finally(() => {
                    this.selectedProcessDetails.type = this.processDetails.types.find(isDefault) || null;
                    this.processDetails.controls.forEach((item) => {if(item.is_default === 1 && item.name !== null) {this.selectedProcessDetails.controls.push(item);}});
                    this.selectedProcessDetails.description = this.processDetails.descriptions.find(isDefault) || null;
                });
        },
        saveProcessDetails() {
            console.log(this.selectedProcessDetails);
            const selectedControlsTexts = this.selectedProcessDetails.controls.map((item) => item.name);
            axios
                .put(`/api/superadmin/flow-chart-processes/${this.$route.params.tableName}`, {
                    country_id: this.countryId,
                    type: this.selectedProcessDetails.type,
                    controls: selectedControlsTexts,
                    description: this.selectedProcessDetails?.description?.description,
                })
                .catch((error) => {
                    this.$toastr.e(error.response.data.message);
                    console.error(error);
                }).finally(this.$router.push({name: 'fsp_conditions.flow.chart.defaults'}));
        },
        customLabel(name, count) {
            return name + ' (' + count + ')';
        },
        createNewDescription() {
            this.processDetails.descriptions.push({ description: '', cnt: '', is_default: 0 });
        },
        deleteDescription(description) {
            axios
                .put(`/api/superadmin/flow-chart-processes/reject-description/${this.$route.params.tableName}`, {
                    country_id: this.countryId,
                    description: description.description,
                })
                .catch((error) => {
                    this.$toastr.e(error.response.data.message);
                    console.error(error);
                });
            this.processDetails.descriptions = this.processDetails.descriptions.filter((item) => item !== description);
        },
    },
};
</script>
<style>
.longText {
    height: 6rem;
    margin-bottom: 1rem;
}
.btn {
    margin-top: 0;
}
.add-btn {
    margin-left: 1rem;
    margin-right: 1rem;
    float: left;
}
.save-btn {
    float: left;
}
h2 {
    color: black;
    margin-bottom: 1.5rem;
}
label {
    color: #565656;
    margin-bottom: 1rem;
}
.tab-content {
    padding-bottom: 2rem;
}
</style>
