<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <input
                        v-model="filter_term"
                        type="text"
                        class="form-control mb-3 col-7"
                        placeholder="Filter by Name"
                    />
                    <div class="col-5 mt-2">
                        <span class="pull-right">
                            <router-link tag="a" :to="{ name: 'structure.groups.create' }">
                                Add New Group
                            </router-link>
                        </span>
                    </div>
                </div>

                <div v-if="filteredGroups.length > 0" class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th class="pointer" @click="orderBy('name')">
                                    Name
                                    <span v-html="order_field == 'name' ? active_sort_icon : sort_icon"></span>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <app-group
                            v-for="(group, index) in orderedGroups"
                            :key="group.id"
                            :index="index"
                            :group="group"
                            @removeGroup="removeGroup"
                        ></app-group>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appGroup from './FspStructureGroupTableRow';
import axios from 'axios';
import _ from 'lodash';

export default {
    components: {
        appGroup
    },
    data() {
        return {
            groups: [],
            filter_term: '',
            order_field: 'created_at',
            order_dir: 'desc',
            sort_icon: '<i class="fa fa-sort"></i>'
        };
    },
    computed: {
        filteredGroups() {
            return this.groups.filter(el => {
                if (!el.static_name) {
                    return false;
                }
                return el.static_name.toLowerCase().match(this.filter_term.toLowerCase());
            });
        },
        orderedGroups() {
            return _.orderBy(this.filteredGroups, this.order_field, this.order_dir);
        },
        active_sort_icon() {
            if (this.order_dir == 'desc') {
                return '<i class="fa fa-sort-down"></i>';
            } else {
                return '<i class="fa fa-sort-up"></i>';
            }
        }
    },
    created() {
        this.fetchStructureGroups();
    },
    methods: {
        fetchStructureGroups() {
            axios
                .get('/api/superadmin/fsp-table-groups')
                .then(res => {
                    this.groups = res.data.data;
                })
                .catch(error => console.error(error));
        },
        orderBy(field) {
            this.order_field = field;
            if (this.order_dir == 'desc') {
                this.order_dir = 'asc';
            } else {
                this.order_dir = 'desc';
            }
        },
        removeGroup(index) {
            this.groups.splice(index, 1);
        }
    }
};
</script>
