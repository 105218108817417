<template>
    <div ref="quill">
        <quill-editor
            v-bind="$attrs"
            v-on="$listeners"
        ></quill-editor>
    </div>
</template>

<script>
export default {
    name: 'BaseQuill',
    props: {
        onPasteHideHTML: Boolean,
    },
    methods: {
        onPaste(event) {
            if(this.onPasteHideHTML) {
                event.preventDefault();

                const paste = (event.clipboardData || window.clipboardData).getData('text');
                const selection = window.getSelection();
                if (!selection.rangeCount) {
                    return;
                }
                selection.deleteFromDocument();
                selection.getRangeAt(0).insertNode(document.createTextNode(paste));
            }
        },
    },
    mounted() {
        this.$refs.quill.addEventListener('paste', this.onPaste);
    },
    destroyed() {
        this.$refs.quill.removeEventListener('paste', this.onPaste);
    },
};
</script>

<style scoped>

</style>