<template>
    <tr :id="balt.id">
        <td>{{ index + 1 }}</td>
        <td>{{ balt.name }}</td>
        <td v-if="!editing">
            <span v-for="(data, i) in balt.location_types" class="badge badge-pill badge-success" :key="i">
                {{ data.name }}
            </span>
        </td>
        <td v-else>
            <v-select
                v-model="form.location_types"
                :options="location_type"
                label="name"
                :close-on-select="false"
                placeholder="- choose -"
                multiple
            >
                <span slot="no-options">Nothing found</span>
            </v-select>
        </td>
        <td>
            <span class="pull-right">
                <a v-if="!editing" href="javascript:void(0)" @click="edit(balt.location_types)">Edit</a>
                <a v-else href="javascript:void(0)" @click="update(balt.id)">Update</a>
            </span>
        </td>
    </tr>
</template>

<script>
import axios from 'axios';

export default {
    props: ['balt', 'index'],
    data() {
        return {
            form: {},
            editing: false,
            location_type: []
        };
    },
    methods: {
        edit(lts) {
            this.form.location_types = lts;
            this.fetchLocationTypes();
            this.editing = true;
        },
        update(id) {
            axios
                .put('/api/superadmin/business-activity/locationType/update/' + id, this.form)
                .then(res => {
                    this.$toastr.i(res.data.message);
                    this.editing = false;
                    this.$parent.fetchBusinessActivities();
                })
                .catch(error => console.error(error));
        },
        fetchLocationTypes() {
            axios
                .get('/api/classifiers/location_types')
                .then(res => {
                    this.location_type = res.data;
                })
                .catch(error => console.error(error));
        }
    }
};
</script>
