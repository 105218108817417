<template>
    <transition v-if="open" name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="predictions-container">
                    <div v-if="loading" class="row permissions-loader">
                        <div class="col">
                            <app-loader line-fg-color="#5ec58c" message="Loading..." size="small" class="m-auto" />
                        </div>
                    </div>
                    <div v-else>
                        <div class="modal-body">
                            <button class="btn btn-default mr-2 mt-2" @click="close">
                                X
                            </button>
                            <div v-if="!permissionsChanged">
                                <div class="d-flex">
                                    Search company:
                                    <input v-model="search" class="form-control company-search mr-2" type="text" @change="getSelectableCompanies">
                                    <button class="btn btn-primary" @click="getSelectableCompanies">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                                <div v-for="company in selectableCompanies">
                                    <div class="d-flex mt-2">
                                        <div class="mr-2">{{ company.name }}</div>
                                        <v-select
                                            v-model="company.selectedRole"
                                            class="predictions-select"
                                            :options="roles.company"
                                            :clearable="false"
                                            label="name"
                                            placeholder="- choose -"
                                        >
                                            <span slot="no-options">Nothing found</span>
                                        </v-select>
                                    </div>
                                    <div v-for="place in company.places" :key="place.id" class="d-flex ml-5 mt-2">
                                        <div class="mr-2">{{ place.name }}</div>
                                        <v-select
                                            v-model="place.selectedRole"
                                            :options="roles.place"
                                            class="predictions-select"
                                            :clearable="false"
                                            label="name"
                                            placeholder="- choose -"
                                        >
                                            <span slot="no-options">Nothing found</span>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="my-3">
                                    <button class="btn btn-default" @click="selectableCompanies = []">
                                        Cancel
                                    </button>
                                    <button class="btn btn-primary" @click="addPermissions">
                                        Add permissions
                                    </button>
                                </div>
                            </div>

                            <hr />
                            <div class="row">
                                <div class="col-6">
                                    <ul>
                                        <li v-for="company in companies" :key="company.id" class="mt-1">
                                            {{ company.name }}
                                            <v-select
                                                v-model="company.selectedRole"
                                                :options="roles.company"
                                                label="name"
                                                placeholder="- choose -"
                                                class="predictions-select"
                                                @input="permissionsChanged = true"
                                            >
                                                <span slot="no-options">Nothing found</span>
                                            </v-select>
                                            <div class="col-6">
                                                <ul>
                                                    <li v-for="place in company.places" :key="place.id" class="mt-1">
                                                        {{ place.name }}
                                                        <v-select
                                                            v-model="place.selectedRole"
                                                            class="predictions-select"
                                                            :options="roles.place"
                                                            label="name"
                                                            placeholder="- choose -"
                                                            @input="permissionsChanged = true"
                                                        >
                                                            <span slot="no-options">Nothing found</span>
                                                        </v-select>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="text-right">
                                <button :disabled="!permissionsChanged" class="btn btn-primary" @click="save">
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from 'axios';

const ROLE_OWNER = 'owner';

export default {
    name: 'UserPermissionsModal',
    props: {
        value: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            permissionsChanged: false,
            search: '',
            companies: [],
            places: [],
            selectableCompanies: [],
            roles: {
                company: [
                    'owner',
                    'readonly',
                ],
                place: [
                    'owner',
                    'readonly,temp-measurements',
                ],
            }
        };
    },
    computed: {
        open: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(id) {
                this.getPermissions(id);
            },
        },
    },
    methods: {
        async getPermissions(id) {
            if(id) {
                this.loading = true;

                return await axios.get(`/api/superadmin/users/${id}/permissions`).then(({data}) => {
                    const places = data?.places?.map(place => ({
                        ...place,
                        id: place.place_id,
                        name: place.place_name,
                        selectedRole: place.role || '',
                        company: place.company,
                    })) || [];
                    this.places = [...places];

                    this.companies = data?.companies?.map(company => {
                        const companyPlaces = [];
                        places?.filter(place => place?.company.id === company.company_id).forEach(place => {
                            companyPlaces.push(place);
                            places.splice(places.findIndex(p => p.id === place.id), 1);
                        });
                        return {
                            ...company,
                            id: company.company_id,
                            name: company.company_name,
                            selectedRole: company.role || '',
                            places: company.role === ROLE_OWNER ? [] : companyPlaces,
                        };
                    }) || [];

                    const companiesWithoutRoles = [...new Set(places.map(place => place?.company?.id))];
                    this.companies = this.companies.concat(companiesWithoutRoles.map(id => {
                        const companyPlaces = places.filter(place => place?.company.id === id);
                        return {
                            id: companyPlaces[0]?.company?.id,
                            name: companyPlaces[0]?.company?.name,
                            role: null,
                            selectedRole: null,
                            places: companyPlaces,
                        };
                    }));

                    this.loading = false;
                    return true;
                });
            }
        },
        async addPermissions() {
            this.loading = true;

            const places = [];
            const companies = this.selectableCompanies.map((company) => {
                places.push(...company.places.filter((place) => !!place.selectedRole).map((place) => ({
                    name: place.name,
                    place_id: place.id,
                    role: place.selectedRole,
                })));
                return {
                    isCompany: true,
                    name: company.name,
                    company_id: company.id,
                    role: company?.selectedRole,
                };
            }).filter((company) => !!company.role);

            for (const payload of [...places, ...companies]) {
                await axios.put(`/api/superadmin/users/${this.value}/permissions`, payload).then(() => {
                    this.$toastr.i(`${payload.isCompany ? 'Company' : 'Place'} "${payload.name}" rights added with role "${payload.role}"!`);
                });
            }
            await this.getPermissions(this.value);
            this.selectableCompanies = [];

            this.loading = false;
        },
        async save() {
            this.loading = true;

            for (const company of this.companies) {
                for (const place of company.places) {
                    const payload = { 'place_id': place.id, };
                    if(place.selectedRole) {
                        if(place.selectedRole !== place.role) {
                            payload.role = place.selectedRole;
                            await axios.put(`/api/superadmin/users/${this.value}/permissions`, { ...payload }).then(() => {
                                this.$toastr.i(`Place "${place.name}" role changed to "${place.selectedRole}"!`);
                            });
                        }
                    } else {
                        payload.role = place.role;
                        await axios.delete(`/api/superadmin/users/${this.value}/permissions`, { params: payload }).then(() => {
                            this.$toastr.i(`Place "${place.name}" rights deleted!`);
                        });
                    }
                }

                const payload = { 'company_id': company.id, };
                if(company.selectedRole) {
                    if(company.selectedRole !== company.role) {
                        payload.role = company.selectedRole;
                        await axios.put(`/api/superadmin/users/${this.value}/permissions`, { ...payload }).then(() => {
                            this.$toastr.i(`Company "${company.name}" role changed to "${company.selectedRole}"!`);
                        });
                    }
                } else if(company.role){
                    payload.role = company.role;
                    await axios.delete(`/api/superadmin/users/${this.value}/permissions`, { params: payload }).then(() => {
                        this.$toastr.i(`Company "${company.name}" rights deleted!`);
                    });
                }
            }

            this.loading = false;
            this.close();
        },
        close() {
            this.$emit('input', null);
            this.search = '';
            this.selectableCompanies = [];
            this.companies = [];
            this.open = false;
            this.permissionsChanged = false;
        },
        getSelectableCompanies() {
            const params = {
                perPage: 5,
                search: this.search || '',
            };
            axios.get('/api/superadmin/companies', { params }).then(({ data }) => {
                this.selectableCompanies = data?.data?.map(company => {
                    const selectedCompany = this.companies?.find(c => c.id === company.id);
                    company.selectedRole = selectedCompany?.role || '';
                    company.places = company.places.map(place => {
                        const selectedPlace = this.places.find(p => p.id === place.id);
                        place.selectedRole = selectedPlace?.role || '';
                        return place;
                    });
                    return company;
                });
            });
        },
    },
};
</script>

<style scoped>
.company-search {
    width: 18rem !important;
}
.predictions-select {
    width: 15rem !important;
}
.permissions-loader {
    height: 100%;
    margin-top: 26rem;
}
.predictions-container {
    margin: 0 auto;
    background-color: white;
    width: 40rem !important;
    height: 80vh !important;
    overflow: auto;
}
</style>
