<template>
    <tbody>
        <tr :class="!subscription.deleted_at && 'table-success'">
            <td @click="toggleDetails">{{ subscription.id }}</td>
            <td @click="toggleDetails">{{ subscription.last_paid_amt }}</td>
            <td @click="toggleDetails">{{ subscription.started_at | formatDate }}</td>
            <td @click="toggleDetails">{{ subscription.renewal_started_at | formatDate }}</td>
            <td @click="toggleDetails">{{ subscription.renewal_valid_to | formatDate }}</td>
            <td @click="toggleDetails">{{ subscription.subscription_renewal_period }}</td>
            <td>
                <span class="pull-right">
                    <template v-if="!subscription.deleted_at">
                        <a @click="edit">Edit</a>|
                        <a @click="destroy(subscription.id)">Delete</a>
                    </template>
                    <a href="#" @click="packageDetailsOpen = !packageDetailsOpen"><i :class="`fa fa-caret-${packageDetailsOpen ? 'down' : 'left'}`"></i></a>
                </span>
            </td>
        </tr>
        <tr v-if="packageDetailsOpen">
            <td colspan="7" style="background-color: #f0f0f0">
                <div v-if="subscription.price_packages && subscription.price_packages.length" class="row" style="margin: 0.1rem">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Created at</th>
                                <th>Updated at</th>
                                <th>Place name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="pack in subscription.price_packages" :key="pack.id">
                                <td>{{ pack.id }}</td>
                                <td>{{ pack.price_package && pack.price_package.name || '-' }}</td>
                                <td>{{ pack.created_at }}</td>
                                <td>{{ pack.updated_at }}</td>
                                <td>{{ pack.place && pack.place.name || '-' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else>
                    NO packages found!
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
import axios from 'axios';

export default {
    props: ['subscription'],
    data() {
        return {
            packageDetailsOpen: false,
        };
    },
    methods: {
        toggleDetails() {
            return this.packageDetailsOpen = !this.packageDetailsOpen;
        },
        edit() {
            this.$router.push({
                name: 'company.subscription.edit',
                params: {
                    subscription_id: this.subscription.id,
                    company_id: this.$route.params.company_id,
                    subscription: this.subscription,
                }
            });
        },
        destroy(subscriptionId) {
            if (!window.confirm(`Delete subscription id=${subscriptionId}?`)) {
                return false;
            }
            axios.delete('/api/superadmin/companies/' + this.$route.params.company_id + '/subscriptions/' + subscriptionId)
                .then(() => {
                    this.$emit('removeModule', subscriptionId);
                })
                .catch(error => {
                    this.$toastr.e(error.response.data.message);
                });
        }
    }
};
</script>
