import axios from 'axios';

const state = {
    user: {},
    user_role: null
};

const mutations = {
    SET_USER(state, user) {
        state.user = user;
    },
    SET_USER_ROLE(state, role) {
        state.user_role = role;
    }
};

const actions = {
    fetchUser({ commit, dispatch }) {
        axios
            .get('/api/profile')
            .then(res => {
                commit('SET_USER', res.data);
                commit('SET_USER_ROLE', res.data.role);
                if (res.data.role === 'superadmin' && res.data.role.includes('technolog') && res.data.role === 'admin') {
                    dispatch('fetchUserReminders', res.data.id);
                }
            })
            .catch(error => console.error(error));
    }
};

const getters = {
    getUser: state => {
        return state.user;
    },
    getUserRole: state => {
        return state.user_role;
    },
    isAdmin: state => {
        return state.user_role === 'admin' || state.user_role === 'superadmin';
    },
};

export default {
    state,
    mutations,
    actions,
    getters
};
