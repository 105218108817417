<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <input
                            v-model="filter_term_changelog"
                            type="text"
                            class="form-control mb-3 col-7"
                            placeholder="Filter by Title"
                        />
                    </div>
                    <div class="col-md-4">
                        <v-select
                            v-model="selected_countries"
                            v-validate="'required'"
                            multiple
                            taggable
                            :options="countries"
                            label="name"
                            data-vv-name="country"
                            data-vv-as="country"
                            placeholder="- choose -"
                        >
                            <span slot="no-options">Nothing found</span>
                        </v-select>
                    </div>
                    <div class="col-md-2 mt-2">
                        <span class="pull-right">
                            <router-link tag="a" :to="{ name: 'pm.changelog.create' }">
                                New Program update
                            </router-link>
                        </span>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="table-responsive">
                    <table v-if="filteredChangelogs.length > 0" class="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Title</th>
                                <th>Intro</th>
                                <th>Body</th>
                                <th>Tags</th>
                                <th>Photo</th>
                                <th>Visible</th>
                                <th>Priority</th>
                                <th>Country</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <app-changelog
                                v-for="(changelog, index) in filteredChangelogs"
                                :key="changelog.id"
                                :index="index"
                                :changelog="changelog"
                                @removeChangelog="removeChangelog"
                            >
                            </app-changelog>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Changelog from './ChangelogTableRow';
import axios from 'axios';
import _ from 'lodash';

export default {
    components: {
        appChangelog: Changelog,
    },
    data() {
        return {
            changelogs: [],
            filter_term_changelog: '',
            countries: [],
            selected_countries: []
        };
    },
    computed: {
        filteredChangelogs() {
            return this.filteredCountryChangelogs.filter(el => {
                if (!el.title.et) {
                    return false;
                }
                return el.title.et.toLowerCase().match(this.filter_term_changelog.toLowerCase());
            });
        },
        filteredCountryChangelogs() {
            if (this.selected_countries.length == 0) {
                return this.changelogs;
            }
            let selectionCountryArray = this.selected_countries.map(condition => condition.id);
            let arr = [];
            for (let changelog of this.changelogs) {
                let index = changelog.country.findIndex(obj => {
                    return _.includes(selectionCountryArray, obj.id) ? true : false;
                });
                if (index != -1) {
                    arr.push(changelog);
                }
            }
            return arr;
        }
    },
    created() {
        this.fetchChangelogs();
        this.getCountries();
    },
    methods: {
        fetchChangelogs() {
            axios
                .get('/api/superadmin/page-management/changelog')
                .then(res => {
                    this.changelogs = res.data;
                })
                .catch(error => console.error(error));
        },
        removeChangelog(index) {
            this.changelogs.splice(index, 1);
        },
        getCountries() {
            axios
                .get('/api/classifiers/countries?simple-format=true')
                .then(res => {
                    let arr = [];
                    for (const item of res.data) {
                        arr = arr.concat(item.countries);
                    }
                    arr.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
                    this.countries = arr;
                })
                .catch(error => console.error(error));
        }
    }
};
</script>
<style>
.vue-js-switch .v-switch-label {
    z-index: 0 !important;
}

.vue-js-switch .v-switch-core .v-switch-button {
    z-index: 0 !important;
}
</style>
