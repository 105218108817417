<template>
    <div>
        <div class="row page-titles hidden-sm-up">
            <div class="col-md-5 align-self-center">
                <h4 class="text-themecolor">Edit Program update</h4>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title hidden-xs-down">
                    Edit Program update
                    <button type="button" class="btn btn-success" @click="update">
                        Update
                    </button>
                </h4>
                <ul class="nav nav-tabs">
                    <li v-for="(item, index) in supportedLanguage" class="nav-item" :key="index">
                        <a
                            class="nav-link"
                            :class="{ active: item.code == 'et' }"
                            data-toggle="tab"
                            :href="'#' + item.code"
                        >{{ item.name }}</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div
                        v-for="(item, index) in supportedLanguage"
                        :id="item.code"
                        class="tab-pane"
                        :class="{ active: item.code == 'et' }"
                        :key="index"
                    >
                        <div class="row mt-3">
                            <div class="col">
                                <div class="form-group">
                                    <label>Title *</label>
                                    <input
                                        v-model="title[item.code]"
                                        v-validate="item.code == 'et' ? 'required' : ''"
                                        type="text"
                                        :data-vv-name="'title_' + item.code"
                                        :data-vv-as="'title_' + item.code"
                                        :class="{
                                            'form-control': true,
                                            'is-invalid': errors.has('title_' + item.code)
                                        }"
                                    />
                                    <span
                                        v-show="errors.has('title_' + item.code)"
                                        class="invalid-feedback"
                                        role="alert"
                                    >
                                        <strong>{{ errors.first('title_' + item.code) }}</strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label>Intro</label>
                                    <quill-editor v-model="intro[item.code]"></quill-editor>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>Body *</label>
                                    <quill-editor v-model="body[item.code]"></quill-editor>
                                    <input
                                        v-model="body[item.code]"
                                        v-validate="item.code == 'et' ? 'required' : ''"
                                        type="hidden"
                                        :class="{
                                            'form-control': true,
                                            'is-invalid': errors.has('body_required_et') && item.code == 'et'
                                        }"
                                        data-vv-name="body_required_et"
                                        data-vv-as="Body"
                                    />
                                    <span
                                        v-show="errors.has('body_required_et') && item.code == 'et'"
                                        class="invalid-feedback"
                                        role="alert"
                                    >
                                        <strong>{{ errors.first('body_required_et') }}</strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <quill-custom-tags />
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Tags</label>
                                    <div>
                                        <vue-tags-input
                                            v-model="tag"
                                            :tags="tags"
                                            :autocomplete-items="tagOptions"
                                            :add-only-from-autocomplete="true"
                                            @tags-changed="newTags => (tags = newTags)"
                                        ></vue-tags-input>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Date</label>
                                    <datepicker
                                        v-model="published_at"
                                        :monday-first="true"
                                        :clear-button="true"
                                        clear-button-icon="fa fa-close text-red"
                                        input-class="form-control bg-white"
                                        format="dd.MM.yyyy"
                                        :class="{ 'bg-white': true }"
                                    >
                                    </datepicker>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Order</label>
                            <input
                                id="order_by"
                                v-model="form.order_by"
                                v-validate="'required|numeric'"
                                name="order_by"
                                type="number"
                                data-vv-name="order_by"
                                data-vv-as="order_by"
                                :class="{
                                    'form-control': true,
                                    'is-invalid': errors.has('order_by')
                                }"
                            />
                            <span v-show="errors.has('order_by')" class="invalid-feedback" role="alert">
                                <strong>The Order field is required.</strong>
                            </span>
                        </div>
                        <div class="form-group">
                            <label>Header Photo</label><br />
                            <vue-base64-file-upload
                                accept="image/png,image/jpeg"
                                input-class="v1-image"
                                :disable-preview="true"
                                @file="getFileName"
                                @load="onImageLoad"
                            />
                        </div>
                        <img v-if="photoSource" :src="photoSource" style="width: 50%" />
                        <button type="button" class="btn btn-success" @click="update">
                            Update
                        </button>
                        <div class="form-group">
                            <label>Preview Photo</label><br />
                            <vue-base64-file-upload
                                accept="image/png,image/jpeg"
                                input-class="v1-image"
                                :disable-preview="true"
                                @file="getPreviewFileName"
                                @load="onPreviewImageLoad"
                            />
                        </div>
                        <img v-if="previewPhotoSource" :src="previewPhotoSource" style="width: 50%" />
                        <button type="button" class="btn btn-success" @click="update">
                            Update
                        </button>
                        <div class="form-group">
                            <label>Countries</label>
                            <button class="btn btn-success btn-sm" @click="selectAllCountries">Select all</button>
                            <button class="btn btn-warning btn-sm" @click="deselectAllCountries">Remove all</button>
                            <v-select
                                v-model="country"
                                v-validate="'required'"
                                :options="countries"
                                label="name"
                                multiple
                                data-vv-name="country"
                                data-vv-as="country"
                                :class="{ 'custom-is-invalid': country_has_error }"
                                placeholder="- choose -"
                            >
                                <span slot="no-options">Nothing found</span>
                            </v-select>
                            <span v-if="country_has_error" class="invalid-feedback" role="alert">
                                <strong>Country is required</strong>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <h4>
                            Country Priorities
                            <button type="button" class="btn btn-success float-right" @click="addPriority">
                                Add Priority
                            </button>
                        </h4>
                        <div class="clearfix"></div>
                        <div v-for="(priority, i) in country_priorities" class="row mt-2" :key="i">
                            <div class="col-md-6">
                                <label class="mb-2">Country</label>
                                <v-select
                                    v-model="priority.country_id"
                                    :options="countries"
                                    label="name"
                                    placeholder="- choose -"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                            <div class="col-md-6">
                                <label>Priority</label>
                                <button
                                    type="button"
                                    class="btn btn-danger btn-sm float-right mb-1"
                                    @click="removePriority(i)"
                                >
                                    Remove Priority
                                </button>
                                <div class="clearfix"></div>
                                <input v-model="priority.priority" type="number" class="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import _ from 'lodash';

export default {
    data() {
        return {
            country_priorities: [],
            id: null,
            title: {},
            intro: {},
            body: {},
            tag: '',
            tags: [],
            form: {},
            country: [],
            image_url: false,
            preview_image_url: false,
            body_required_et: '',
            tagOptions: [
                { text: 'News' },
                { text: 'Food safety' },
                { text: 'FoodDocs in media' },
                { text: 'Case study' }
            ],
            countries: [],
            published_at: '',
            supportedLanguage: []
        };
    },
    computed: {
        photoSource() {
            if (this.form.img) {
                return this.form.img;
            } else {
                return this.image_url;
            }
        },
        previewPhotoSource() {
            if (this.form.preview_img) {
                return this.form.preview_img;
            } else {
                return this.preview_image_url;
            }
        },
        country_has_error() {
            return this.country && this.country.length === 0;
        }
    },
    created() {
        this.getSupportedFspConditionsLanguage();
        this.getChangelog();
        this.getCountries();
    },
    methods: {
        selectAllCountries() {
            this.country = [...this.countries];
        },
        deselectAllCountries() {
            this.country = [];
        },
        addPriority() {
            let priority = { country_id: { name: 'Estonia', id: 63 }, priority: 1 };
            this.country_priorities.push(priority);
        },
        removePriority(index) {
            this.country_priorities.splice(index, 1);
        },
        getSupportedFspConditionsLanguage() {
            axios
                .get('/api/superadmin/food-safety-plan-conditions/languages')
                .then(res => {
                    this.supportedLanguage = res.data;
                })
                .catch(error => console.error(error));
        },
        onImageLoad(dataUri) {
            Vue.set(this.form, 'img', dataUri);
        },
        onPreviewImageLoad(dataUri) {
            Vue.set(this.form, 'preview_img', dataUri);
        },
        getFileName(file) {
            Vue.set(this.form, 'file_name', file.name);
        },
        getPreviewFileName(file) {
            Vue.set(this.form, 'preview_file_name', file.name);
        },
        bodyChange({ text }) {
            this.body_required_et = text;
        },
        getChangelog() {
            axios
                .get('/api/superadmin/page-management/changelog/' + this.$route.params.changelog_id)
                .then(res => {
                    this.id = res.data.id;
                    this.country_priorities = res.data.country_priorities ? res.data.country_priorities : [];
                    this.title = res.data.title;
                    this.intro = res.data.intro;
                    this.body = res.data.body;
                    this.image_url = res.data.file_name;
                    this.preview_image_url = res.data.preview_file_name;
                    this.country = res.data.country;
                    this.published_at = res.data.published_at;
                    this.form.order_by = res.data.order_by;
                    let parsedTags = [];
                    res.data.tags.forEach(tag => {
                        parsedTags.push({ text: tag });
                    });
                    this.tags = parsedTags;
                })
                .catch(error => console.error(error));
        },
        getCountries() {
            axios
                .get('/api/classifiers/countries?simple-format=true')
                .then(res => {
                    let arr = [];
                    for (const item of res.data) {
                        arr = arr.concat(item.countries);
                    }
                    arr.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
                    this.countries = arr;
                })
                .catch(error => console.error(error));
        },
        update() {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    let updatedTags = [];
                    this.tags.forEach(tag => {
                        updatedTags.push(tag.text);
                    });
                    let country_id = _.uniqBy(this.country, function(e) {
                        return e.id;
                    });
                    country_id = country_id.map(obj => obj.id);
                    this.form = {
                        title: this.title,
                        intro: this.intro,
                        body: this.body,
                        tags: updatedTags,
                        img: this.form.img,
                        file_name: this.form.file_name,
                        preview_img: this.form.preview_img,
                        preview_file_name: this.form.preview_file_name,
                        country_id: country_id,
                        order_by: this.form.order_by,
                        published_at: this.published_at,
                        country_priorities: this.country_priorities
                    };
                    axios
                        .put(
                            '/api/superadmin/page-management/changelog/' + this.$route.params.changelog_id + '/update',
                            this.form
                        )
                        .then(res => {
                            if (res.response && res.response.status == 403) {
                                this.$toastr.e('Access denied');
                                return false;
                            }
                            this.$toastr.i('Changelog updated');
                        })
                        .catch(error => console.error(error));
                }
            });
        },
        destroyImage(id) {
            if (!window.confirm('Delete?')) {
                return false;
            }
            axios
                .delete('/api/superadmin/page-management/changelog/' + id + '/destroyImage')
                .then(res => {
                    this.$toastr.i(res.data.message);
                    this.form.storage_name = '';
                })
                .catch(error => {
                    this.$toastr.e(error.response.data.message);
                });
        }
    }
};
</script>

<style>
.ql-custom-btn:after {
    content: 'N';
    font-weight: bold;
}
</style>
