<template>
    <div class="page-wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-9 col-xlg-9 col-md-8">
                    <div class="card">
                        <ul class="nav nav-tabs profile-tab" role="tablist">
                            <li class="nav-item">
                                <router-link
                                    :to="{
                                        name: 'place.products.import',
                                        params: { place_id: $route.params.place_id }
                                    }"
                                    tag="a"
                                    class="nav-link"
                                >
                                    <span class="hidden-sm-up">
                                        <i class="fas fa-upload"></i>
                                    </span>
                                    <span class="hidden-xs-down">
                                        Products Import
                                    </span>
                                </router-link>
                            </li>
                        </ul>
                        <router-view :user_id="user_id" :user_role="user_role"></router-view>
                    </div>
                </div>

                <div class="col-lg-3 col-xlg-3 col-md-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="m-t-30 text-center">
                                <img
                                    src="/assets/img/fooddocs_logo_green_icon_40px.png"
                                    class="img-circle"
                                    width="150"
                                />
                                <h4 class="card-title m-t-10">{{ place.name }}</h4>
                            </div>
                            <div>
                                <hr />
                            </div>
                            <small class="text-muted">Company</small>
                            <h5>
                                <router-link
                                    :to="{
                                        name: 'company.places',
                                        params: { company_id: place.company_id }
                                    }"
                                >
                                    {{ place.company.name }}
                                </router-link>
                            </h5>
                            <small class="text-muted">Contact Person</small>
                            <h5>{{ place.contact_person }}</h5>
                            <small class="text-muted">Email</small>
                            <h5>{{ place.person_email }}</h5>
                            <small class="text-muted p-t-30 db">Phone</small>
                            <h5>{{ place.person_phone }}</h5>
                            <small class="text-muted p-t-30 db">Address</small>
                            <h5>{{ place.address }}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            user_id: null,
            user_role: null
        };
    },
    computed: {
        place() {
            return this.$store.getters.getPlace;
        }
    },
    created() {
        this.getPlaceAction();
        this.getUserId();
    },
    methods: {
        getPlaceAction() {
            return this.$store.dispatch('fetchPlace', this.$route.params.place_id);
        },
        getUserId() {
            axios.get('/api/profile').then(res => {
                this.user_id = res.data.id;
                this.user_role = res.data.role;
            });
        }
    }
};
</script>
