<template>
    <div id="app">
        <app-topbar v-if="access_token"></app-topbar>
        <app-nav v-if="access_token"></app-nav>

        <app-login v-if="!access_token"></app-login>
        <router-view v-if="access_token"></router-view>
    </div>
</template>

<script>

import Login from '@/components/Login';
import Topbar from '@/components/layout/Topbar';
import Nav from '@/components/layout/Nav';

export default {
    name: 'App',
    components: {
        appLogin: Login,
        appTopbar: Topbar,
        appNav: Nav
    },
    data() {
        return {
            user_role: null
        };
    },
    computed: {
        access_token() {
            return this.$store.getters.getAccessToken;
        }
    },
    mounted() {
        this.$store.dispatch('resetToken');
    }
};
</script>

<style>
#app {
    font-family: Rubik,sans-serif;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #edf1f5;
}
</style>

<style lang="scss">
@import '@styles/styles';
</style>