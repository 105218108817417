import axios from 'axios';
import { LOG_ERROR } from '../common/constants';

const state = {
    companies: [],
    company: {},
    currencies: [],
};

const mutations = {
    SET_COMPANY(state, company) {
        state.company = company;
    },
    SET_COMPANIES(state, companies) {
        state.companies = companies;
    },
    setCurrencies(state, data) {
        state.currencies = data;
    },
    editCompany(state, company) {
        Object.keys(company).forEach(key => {
            state.company[key] = company[key];
        });
    },
};

const actions = {
    fetchCompany({ commit, dispatch }, companyId) {
        axios
            .get(`/api/superadmin/companies/${companyId}`)
            .then(res => {
                if (res) {
                    commit('SET_COMPANY', res.data);
                } else {
                    throw new TypeError(`Can't find res`);
                }
            })
            .catch(error => dispatch(LOG_ERROR, `companies store fetchCompany error: ${error}`));
    },
    fetchCompanies({ commit, dispatch }) {
        dispatch('startLoader');
        axios
            .get(`/api/superadmin/company`)
            .then(res => {
                res.data.forEach(function(company) {
                    let unpaid_invoices = company.invoices.filter(invoice => {
                        return invoice.paid == false;
                    });
                    let unpaid_amount = unpaid_invoices.reduce((start, invoice) => start + invoice.amount, 0);
                    company.unpaid_amount = unpaid_amount / 100;
                });
                commit('SET_COMPANIES', res.data);
            })
            .then(() => {
                dispatch('stopLoader');
            });
    },
    fetchCurrencies({ commit }) {
        axios.get('/api/classifiers/currencies')
            .then(({data}) => commit('setCurrencies', data))
            .catch(error => console.error(error));
    },
};

const getters = {
    getCompany: state => {
        return state.company;
    },
    getCompanies: state => {
        return state.companies;
    },
    getCurrencies: state => {
        return state.currencies;
    },
};

export default {
    state,
    mutations,
    actions,
    getters
};
