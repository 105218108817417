<template>
    <div class="mt-5">
        <div>Select related tasks</div>
        <v-select
            v-model="selectedTasks"
            :options="taskOptions"
            :close-on-select="false"
            placeholder="- choose -"
            multiple
            :get-option-label="getLabel"
            label="id"
        >
            <span slot="no-options">Nothing found</span>
        </v-select>

        <button class="btn btn-success mt-2" @click="saveHazardEstimation">Save</button>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: 'HazardEstimationDropdown',
    props: {
        row: {
            type: Object,
            required: true,
        },
        selectedGroupId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            taskOptions: [],
            selectedTasks: [],
            relatedCountries: [],
        };
    },
    async created() {
        try {
            await axios.get(`/api/superadmin/monitoring-tasks`).then(({data}) => {
                this.taskOptions = data[0];
                this.relatedCountries = data[1];
            });
        } catch(error) {
            console.error(error);
            this.$toastr.e(error);
        }
        
        this.selectedTasks = this.row.groups.find(item => item?.id === this.selectedGroupId).task_subtypes.map(t => t.task_subtype);
    },
    methods: {
        saveHazardEstimation() {
            const rowId = this.row && this.row.row && this.row.row.id;
            const params = {
                container_fsp_section_id: rowId,
                task_subtypes: this.selectedTasks.map(task => ({ task_subtype: { id: task.id } })),
            };
            axios.put(`/api/superadmin/fsp-table-row-groups/${this.selectedGroupId}`, params).then(({data}) => {
                this.row.groups.find(item => item?.id === this.selectedGroupId).task_subtypes = data?.task_subtypes;
            }).catch(error => console.error(error));
        },
        getLabel(option) {
            return `${option.name} (${option.id}) (${this.relatedCountries[option.id]?.join(', ')||''})`;
        },

    }

};
</script>
<style>
.btn {
    float: right;
    margin-top: 1rem;
}
</style>
