<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <b-form-group>
                            <b-form-radio-group
                                v-model="subscriptionsType"
                                :options="[
                                    {text: 'all', value: 'all'},
                                    {text: 'active', value: 'active'},
                                    {text: 'deleted', value: 'deleted'},
                                ]"
                                name="subscription-radio"
                                @input="fetchCompanySubscriptions"
                            ></b-form-radio-group>
                        </b-form-group>
                    </div>
                    <div class="col-6 text-right">
                        <router-link
                            v-if="!orderedSubscriptions.length && subscriptionsType === 'active'"
                            tag="a"
                            :to="{ name: 'company.subscription.create', params: { company_id: $route.params.company_id } }"
                        >
                            New Subscription
                        </router-link>
                    </div>
                </div>
                <div class="clearfix mb-3"></div>
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th class="pointer" @click="orderBy('last_paid_amt')">
                                    Last paid amount
                                    <span v-html="order_field === 'last_paid_amt' ? active_sort_icon : sort_icon"></span>
                                </th>
                                <th class="pointer" @click="orderBy('started_at')">
                                    Started at
                                    <span v-html="order_field === 'started_at' ? active_sort_icon : sort_icon"></span>
                                </th>
                                <th class="pointer" @click="orderBy('renewal_started_at')">
                                    Renewal started at
                                    <span v-html="order_field === 'renewal_started_at' ? active_sort_icon : sort_icon"></span>
                                </th>
                                <th class="pointer" @click="orderBy('renewal_valid_to')">
                                    Renewal Valid to
                                    <span v-html="order_field === 'renewal_valid_to' ? active_sort_icon : sort_icon"></span>
                                </th>
                                <th class="pointer" @click="orderBy('renewal_time_unit_cnt')">
                                    Renewal period
                                    <span v-html="order_field === 'renewal_time_unit_cnt' ? active_sort_icon : sort_icon"></span>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <app-period
                            v-for="subscription in orderedSubscriptions"
                            :key="subscription.id"
                            :subscription="subscription"
                            :user_id="user_id"
                            :user_role="user_role"
                            @removeModule="removeSubscription"
                        />
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appPeriod from './TableRow';
import axios from 'axios';

export default {
    components: {
        appPeriod
    },
    props: ['user_id', 'user_role'],
    data() {
        return {
            subscriptions: [],
            subscriptionsType: 'active',
            order_field: 'active',
            order_dir: 'desc',
            sort_icon: '<i class="fa fa-sort"></i>'
        };
    },
    computed: {
        company() {
            return this.$store.getters.getCompany;
        },
        orderedSubscriptions() {
            if(this.order_field === 'active'){
                return this.subscriptions.sort((a,b) => {
                    return !!a.deleted_at && !b.deleted_at? 1 : -1;
                });
            }
            return _.orderBy(this.subscriptions, this.order_field, this.order_dir);
        },
        active_sort_icon() {
            if (this.order_dir === 'desc') {
                return '<i class="fa fa-sort-down"></i>';
            } else {
                return '<i class="fa fa-sort-up"></i>';
            }
        }
    },
    created() {
        this.fetchCompanySubscriptions();
    },
    methods: {
        fetchCompanySubscriptions() {
            this.order_field = 'active';
            this.subscriptions = [];
            let scope;
            if(this.subscriptionsType !== 'all'){
                scope = this.subscriptionsType;
            }
            axios.get(`/api/superadmin/companies/${this.$route.params.company_id}/subscriptions`, {params: {scope}})
                .then(({data}) => {
                    if(data){
                        if(data.length || data.length === 0){
                            this.subscriptions = data;
                        } else {
                            if(Object.keys(data).length){
                                this.subscriptions = [data];
                            }
                        }
                    }
                })
                .catch(error => console.error(error));
        },
        removeSubscription(subscriptionId) {
            this.subscriptions = this.subscriptions.filter(subscription => {
                return subscriptionId !== subscription.id;
            });
            this.fetchCompanySubscriptions();
        },
        orderBy(field) {
            this.order_field = field;
            if (this.order_dir === 'desc') {
                this.order_dir = 'asc';
            } else {
                this.order_dir = 'desc';
            }
        }
    }
};
</script>
