<template>
    <div>
        <span v-for="(image, index) in images" :key="index">
            <img width="100%" :src="image.url" class="mb-1">
            <button v-if="removeImage" :disabled="disabled" class="btn btn-danger mb-2" @click="deleteImage(image.id)">Delete</button>
        </span>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Images',
    props: {
        files: {
            type: Array,
            required: true
        },
        removeImage: Function,
        disabled: Boolean
    },
    data() {
        return {
            images: []
        };
    },
    created() {
        this.getAllUrls();
    },
    methods: {
        deleteImage(id) {
            this.images = this.images.filter(f => f.id !== id);
            this.removeImage(id);
        },
        getAllUrls() {
            this.images = [];
            this.files.forEach(file => {
                axios.get(`/api/files/${file.id}`, { responseType: 'blob' }).then(response => {
                    this.images.push({
                        id: file.id,
                        name: file.name,
                        url: URL.createObjectURL(response.data)
                    });
                });
            });
        }
    }

};
</script>

<style scoped>

</style>
