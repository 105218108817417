<template>
    <tr v-if="!fhpVisible">
        <td>{{ company.id }}</td>
        <td>
            {{ company.country.name }}
        </td>
        <td class="pointer" @click="fhpVisible = !fhpVisible">
            <i class="icon-arrow-down-circle font-20"></i>
            <small class="align-top">({{ company.places.length }})</small>
        </td>
        <td>
            <router-link tag="a" :to="{ name: 'company.invoices', params: { company_id: company.id } }">
                {{ company.name ? company.name : '-' }}
            </router-link>
            <br />
            <small v-if="company.active_period">({{ company.active_period }})</small>
        </td>
        <td>
            <p v-for="rep in company.reps" class="mb-0">{{ rep.user.username }}&nbsp;({{ rep.role }})</p>
        </td>
        <td>
            <p v-for="rep in company.reps" class="mb-0">{{ rep.user.phone ? rep.user.phone : '-' }}&nbsp;</p>
        </td>
        <td
            :class="{
                'bg-yellow': is_about_to_expire(company.next_payment_date),
                'bg-red': is_expired(company.next_payment_date)
            }"
            data-field="next_payment"
        >
            {{ company.next_payment_date | formatDate }}
        </td>
        <td>{{ company.created_at | formatDateTime }}</td>
        <td :class="{ 'bg-red': company.unpaid_amount > 0 }">
            {{ company.unpaid_amount }}
        </td>
    </tr>
    <tr v-else>
        <td>{{ company.id }} {{ company.country.code }}</td>
        <td class="pointer" @click="fhpVisible = !fhpVisible">
            <i class="icon-arrow-up-circle font-20"></i>
            <small class="align-top">({{ company.places.length }})</small>
        </td>
        <td>
            <p v-for="(place, index) in company.places" class="mb-1">
                {{ index + 1 }}. {{ place.name ? place.name : '-' }}
                {{ place.business_activity != null ? ' - ' + place.business_activity.name : '' }}
            </p>
        </td>
        <td>
            <p v-for="rep in company.reps" class="mb-0">{{ rep.user.username }}&nbsp;({{ rep.role }})</p>
        </td>
        <td>-</td>
        <td>
            <p v-for="place in company.places" class="mb-1">
                {{ place.person_phone ? place.person_phone : '-' }}
            </p>
        </td>
        <td>
            <p v-for="place in company.places" class="mb-1">
                {{ place.person_email ? place.person_email : '-' }}
            </p>
        </td>
        <td colspan="5"></td>
    </tr>
</template>

<script>
import moment from 'moment';

export default {
    props: ['company', 'index'],
    data() {
        return {
            fhpVisible: false,
        };
    },
    methods: {
        is_about_to_expire(next_payment_date) {
            let today = moment().format('YYYY-MM-DD');
            let futureDate = moment()
                .add(7, 'days')
                .format('YYYY-MM-DD');

            if (next_payment_date < futureDate && next_payment_date > today) {
                return true;
            }
            return false;
        },
        is_expired(next_payment_date) {
            let today = moment().format('YYYY-MM-DD');
            if (next_payment_date < today) {
                return true;
            }
            return false;
        }
    }
};
</script>
