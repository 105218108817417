<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <div class="col-3">
                        <h6>Search</h6>
                        <input
                            v-model="searchString"
                            @change="select"
                        />
                    </div>
                    <div class="col-3">
                        <h6>Business Activities</h6>
                        <v-select
                            v-model="selectedBusinessModels"
                            :close-on-select="false"
                            :options="business_activities"
                            label="name"
                            multiple
                            placeholder="- choose -"
                            @input="select"
                            @change="select"
                        >
                            <span slot="no-options">Nothing found</span>
                        </v-select>
                    </div>
                    <div class="col-3">
                        <h6>FSP Table</h6>
                        <v-select
                            v-model="selectedFSPTable"
                            close-on-select
                            :options="FSPTableOptions"
                            label="static_name"
                            placeholder="- choose -"
                            @input="select"
                        >
                            <span slot="no-options">Nothing found</span>
                        </v-select>
                    </div>
                    <div class="col-3">
                        <h6>Countries</h6>
                        <app-multiselect
                            v-model="selected_countries"
                            :options="countries"
                            :multiple="true"
                            :close-on-select="false"
                            group-values="countries"
                            group-label="continent"
                            :group-select="true"
                            placeholder="Type to search"
                            track-by="name"
                            label="name"
                            @select="select"
                        >
                            <template slot="selection" slot-scope="{ values, search, isOpen }">
                                <span v-if="values.length && !isOpen" class="multiselect__single">
                                    {{ values.length }} countries selected
                                </span>
                            </template>
                            <span slot="noResult">Nothing found</span>
                        </app-multiselect>
                    </div>
                    <div class="col-3">
                        <h6>Needs translation?</h6>
                        <toggle-button
                            :value="needs_translation == 1 ? true : false"
                            :width="120"
                            class="mt-2"
                            color="#5ec58c"
                            :labels="{
                                checked: 'needs translations',
                                unchecked: 'check translations'
                            }"
                            @change="toggleNeedsTranslation"
                        />
                    </div>
                    <div class="col-3">
                        <h6>Entities per pages</h6>
                        <v-select
                            v-model="perPage"
                            :options="perPageOptions"
                            placeholder="- choose -"
                            @input="select"
                        >
                        </v-select>
                    </div>
                    <div class="col-3">
                        <h6>Navigate</h6>
                        <FspConditionsNavigation :total="total" :current-page="currentPage" :per-page="perPage" :change-current-page-number="changeCurrentPageNumber" />
                    </div>
                    <div class="col-3 mt-2">
                        <span class="pull-right">
                            <router-link tag="a" :to="{ name: 'fsp_conditions.create' }">
                                New Condition
                            </router-link>
                        </span>
                    </div>
                </div>
                <app-loader v-if="loading" size="medium" line-fg-color="#5ec58c" message="Loading..."></app-loader>
                <div v-if="!loading && conditions.length > 0" class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Business Activity</th>
                                <th class="pointer" style="width: 700px" @click="orderBy('section_translations')">
                                    Condition
                                    <span v-html="order_field == 'condition.en' ? active_sort_icon : sort_icon"></span>
                                </th>
                                <th>FSP Table</th>
                                <th>Tags</th>
                                <th>Type</th>
                                <th width="250px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <app-condition
                                v-for="(condition, index) in conditions"
                                :key="condition.id"
                                :index="index"
                                :condition="condition"
                                :business_activities="business_activities"
                                :langs="langs"
                                :decipher-a-p-i-string="decipherAPIString"
                                @removeCondition="removeCondition"
                            ></app-condition>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appCondition from './FspConditionTableRow';
import appMultiselect from 'vue-multiselect';
import FspConditionsNavigation from './FspConditionsNavigation';
import axios from 'axios';

export default {
    components: {
        FspConditionsNavigation,
        appCondition,
        appMultiselect
    },
    data() {
        return {
            loading: true,
            langs: [],
            needs_translation: false,
            conditions: [],
            countries: [],
            selected_countries: [],
            condition: null,
            FSPTableOptions: [],
            business_activities: [],
            selectedBusinessModels: [],
            selectedFSPTable: {},
            order_field: 'created_at',
            order_dir: 'desc',
            sort_icon: '<i class="fa fa-sort"></i>',
            currentPage: 1,
            total: 0,
            perPage: 50,
            searchString: ''
        };
    },
    computed: {
        perPageOptions() {
            return [5, 10, 25, 50, 75, 100, 150, 200, this.total ? this.total : 1000];
        },
        active_sort_icon() {
            if (this.order_dir == 'desc') {
                return '<i class="fa fa-sort-down"></i>';
            } else {
                return '<i class="fa fa-sort-up"></i>';
            }
        }
    },
    beforeDestroy() {
        this.$store.commit('leaveConditionsView', {
            conditions: this.conditions,
            searchString: this.searchString,
            perPage: this.perPage,
            currentPage: this.currentPage,
            total: this.total,
            selectedCountries: this.selected_countries,
            selectedBusinessActivities: this.selectedBusinessModels,
            selectedFSPTable: this.selectedFSPTable,
        });
    },
    created() {
        this.getLanguages();
        const preData = this.$store.getters.enterConditionsView;
        if(preData && preData.conditions.length) {
            this.conditions = preData.conditions;
            this.searchString = preData.searchString;
            this.perPage = preData.perPage;
            this.currentPage = preData.currentPage;
            this.total = preData.total;
            this.selected_countries = preData.selectedCountries;
            this.selectedBusinessModels = preData.selectedBusinessActivities;
            this.selectedFSPTable = preData.selectedFSPTable;
            this.loading = false;
        } else {
            this.fetchFspConditions();
        }
        this.getBusinessActivities();
        this.getCountries();
        this.getFSPTableOptions();
    },
    methods: {
        select() {
            this.currentPage = 1;
            this.fetchFspConditions();
        },
        changeCurrentPageNumber(pageNumber) {
            this.currentPage = pageNumber;
            this.fetchFspConditions();
        },
        decipherAPIString(string) {
            try {
                return string.section_translations.filter(s => s.language_id === 43)[0].translation_text;
            } catch (e) {
                try {
                    return string.static_name;
                } catch (e) {
                    return 'translation not found';
                }
            }
        },
        toggleNeedsTranslation() {
            this.needs_translation = !this.needs_translation;
        },
        fetchFspConditions() {
            this.loading = true;

            let businessModels = '';
            this.selectedBusinessModels.forEach((sbm, i) => {
                businessModels += sbm.id + (i !== this.selectedBusinessModels.length - 1 ? ',' : null);
            });
            let countries = '';
            this.selected_countries.forEach((c, i) => {
                countries += c.id + (i !== this.selected_countries.length - 1 ? ',' : null);
            });
            axios
                .get('/api/superadmin/food-safety-plan-conditions', {params: {
                    page: this.currentPage,
                    perPage: this.perPage,
                    countries: countries,
                    businessActivities: businessModels,
                    searchText: this.searchString,
                    tableId: this.selectedFSPTable?.id,
                }})
                .then(res => {
                    this.conditions = res.data.data;
                    this.total = res.data.total;
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => console.error(error));
        },
        getBusinessActivities() {
            axios
                .get('/api/classifiers/business-activities')
                .then(res => {
                    this.business_activities = res.data;
                })
                .catch(error => console.error(error));
        },
        getFSPTableOptions() {
            axios
                .get('/api/superadmin/fsp-tables?documentTypeId=1')
                .then(res => {
                    this.FSPTableOptions = res?.data?.data;
                })
                .catch(error => console.error(error));
        },
        orderBy(field) {
            this.order_field = field;
            if (this.order_dir == 'desc') {
                this.order_dir = 'asc';
            } else {
                this.order_dir = 'desc';
            }
        },
        removeCondition(index) {
            this.conditions.splice(index, 1);
        },
        getCountries() {
            axios
                .get('/api/classifiers/countries?simple-format=true')
                .then(res => {
                    this.countries = res.data;
                })
                .catch(error => {
                    this.load_error = true;
                    console.error(error);
                });
        },
        getLanguages() {
            axios
                .get('/api/superadmin/food-safety-plan-conditions/languages')
                .then(res => {
                    this.langs = res.data;
                })
                .catch(error => console.error(error));
        }
    }
};
</script>
