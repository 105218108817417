<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <h4>Edit Row for Conditions Structure</h4>
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Countries</label>
                            <div class="col-sm-9">
                                <app-multiselect
                                    v-model="selected_countries"
                                    :options="countries"
                                    :multiple="true"
                                    group-values="countries"
                                    group-label="continent"
                                    :group-select="true"
                                    placeholder="Type to search"
                                    track-by="name"
                                    label="name"
                                >
                                    <template slot="selection" slot-scope="{ values, isOpen }">
                                        <span v-if="values.length && !isOpen" class="multiselect__single">
                                            {{ values.length }} countries selected
                                        </span>
                                    </template>
                                    <span slot="noResult">Nothing found</span>
                                </app-multiselect>
                            </div>
                        </div>
                        <!-- Standard types -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Standard type</label>
                            <div class="col-sm-9">
                                <v-select
                                    v-model="standards"
                                    v-validate="'required'"
                                    :close-on-select="false"
                                    multiple
                                    :options="standardOptions"
                                    label="name"
                                    placeholder="- choose -"
                                    data-vv-name="standard_options"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                                <span v-show="errors.has('standard_options')" class="text-danger" role="alert">
                                    <strong>{{ errors.first('standard_options') }}</strong>
                                </span>
                            </div>
                        </div>
                        <!-- Static naem -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Static name</label>
                            <div class="col-sm-9">
                                <v-select
                                    v-model="staticName"
                                    :options="staticNames"
                                    label="Static name"
                                    placeholder="- choose -"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- Production processes -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Production Process</label>
                            <div class="col-sm-9">
                                <v-select
                                    v-model="selected_pp"
                                    :close-on-select="false"
                                    :options="processes"
                                    label="name"
                                    multiple
                                    placeholder="- choose -"
                                    @input="productionProcessChange"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- production process subprocesses -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">Production Sub Processes</label>
                            <div class="col-sm-9">
                                <v-select
                                    v-model="selected_pp_sub_process"
                                    multiple
                                    :close-on-select="false"
                                    :options="production_sub_processes"
                                    label="name"
                                    placeholder="- choose -"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <div v-for="lang in langs" class="form-group row mb-1">
                            <label class="col-sm-3 col-form-label">{{ lang.name }}</label>
                            <div class="col-sm-9">
                                <div v-if="lang.code == 'en'">
                                    <input
                                        v-model="name[lang.code]"
                                        v-validate="'required'"
                                        type="text"
                                        data-vv-name="table_en"
                                        data-vv-as="English table name"
                                        :class="{ 'form-control': true, 'is-invalid': errors.has('table_en') }"
                                    />
                                    <span v-show="errors.has('table_en')" class="invalid-feedback" role="alert">
                                        <strong>{{ errors.first('table_en') }}</strong>
                                    </span>
                                </div>
                                <div v-else>
                                    <input v-model="name[lang.code]" type="text" class="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button v-if="isEdit" type="button" class="btn btn-success" @click="update">Update</button>
                <button v-if="isCreate" type="button" class="btn btn-success" @click="update(false)">Save</button>
                <button v-if="isCreate" type="button" class="btn btn-success" @click="update(true)">Save and Add New Row</button>
            </div>
        </div>
    </div>
</template>

<script>
import appMultiselect from 'vue-multiselect';
import axios from 'axios';

export default {
    components: {
        appMultiselect
    },
    data() {
        return {
            staticName: '',
            staticNames: [],
            form: {},
            row: {},
            langs: [],
            processes: [],
            selected_pp: null,
            selected_pp_sub_process: null,
            production_sub_processes: [],
            name: {},
            countries: [],
            selected_countries: [],
            applicationType: null,
            standards: [],
            standardOptions: [],
            parentId: null,
        };
    },
    computed: {
        isCreate() {
            return this.applicationType === 'create';
        },
        isEdit() {
            return this.applicationType === 'edit';
        },
    },
    created() {
        this.getApplicationType();
        this.getLanguages();
        this.getStaticNames();
        this.fetchProcesses();
        this.getRow();
    },
    methods: {
        getApplicationType() {
            if(this.$route.name === 'fsp_cond.structure.row.create'){
                this.applicationType = 'create';
            } else if(this.$route.name === 'fsp_cond.structure.row.edit') {
                this.applicationType = 'edit';
            }
        },
        update(newRow) {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    let selecteCountries = [];
                    this.selected_countries.forEach(item => {
                        selecteCountries.push({id: item.id});
                    });
                    let translations = [];
                    Object.keys(this.name).forEach(key => {
                        if(this.name[key]){
                            translations.push({
                                language: key,
                                translation_text: this.name[key]
                            });
                        }
                    });
                    this.form = {
                        section_translations: translations,
                        production_processes: this.selected_pp,
                        production_subprocesses: this.selected_pp_sub_process,
                        countries: selecteCountries,
                        standards: this.standards,
                        static_name: this.staticName,
                    };
                    if(this.isEdit){
                        axios.put(
                            '/api/superadmin/fsp-table-rows/' +
                                this.$route.params.fsp_cond_structure_row_id, this.form)
                            .then(() => {
                                this.$toastr.i(`Added row to ${this.$route.params.fsp_cond_structure_row_id}`);
                                this.$router.push({ name: 'fsp_cond.structure' });
                            })
                            .catch(error => console.error(error));
                    } else {
                        this.form.parent_fsp_section_id = this.$route.params.fsp_cond_structure_id;
                        axios.post(
                            '/api/superadmin/fsp-table-rows/', this.form)
                            .then(() => {
                                this.$toastr.i(`Added new row!`);
                                if(newRow){
                                    this.name = {};
                                } else {
                                    this.$router.push({ name: 'fsp_cond.structure' });
                                }
                            })
                            .catch(error => console.error(error));
                    }
                }
            });
        },
        fetchProcesses() {
            axios
                .get('/api/classifiers/production-processes')
                .then(res => {
                    this.processes = res.data;
                })
                .catch(error => console.error(error));
        },
        getProductionSubProcesses(process) {
            axios
                .get(`/api/classifiers/production-sub-processes/${process}`)
                .then(res => {
                    this.production_sub_processes = res.data;
                })
                .catch(error => console.error(error));
        },
        productionProcessChange(items) {
            let processes = [];
            items.forEach(process => {
                processes.push(process.id);
            });
            if(processes.length) {
                this.getProductionSubProcesses(processes);
            }
        },
        getLanguages() {
            axios
                .get('/api/superadmin/food-safety-plan-conditions/languages')
                .then(res => {
                    this.langs = res.data;
                })
                .catch(error => console.error(error));
        },
        getStaticNames() {
            axios
                .get('/api/superadmin/fsp-table-rows/static-names')
                .then(({data}) => {
                    this.staticNames = data;
                })
                .catch(error => console.error(error));
        },
        getTable(id) {
            axios.get(`/api/superadmin/fsp-tables/${id}`).then(response => {
                if(response.data){
                    this.standardOptions = response.data.standards;
                }
            });
        },
        getRow() {
            if(this.isEdit){
                axios
                    .get(
                        '/api/superadmin/fsp-table-rows/' +
                        this.$route.params.fsp_cond_structure_row_id
                    )
                    .then(res => {
                        if (res.data.production_process) {
                            this.getProductionSubProcesses(res.data.production_process);
                        }
                        let translations = {};
                        res.data.section_translations.forEach(section => {
                            translations = {[section.language]: section.translation_text , ...translations};
                        });
                        this.name = translations;
                        this.selected_pp = res.data.production_processes;
                        this.selected_pp_sub_process = res.data.production_subprocesses;
                        this.selected_countries = res.data.countries;
                        this.standards = res.data.standards;
                        this.parentId = res.data.parent_fsp_section_id;
                        this.staticName = res.data.static_name;
                    })
                    .then(() => {
                        this.getCountries();
                        this.getTable(this.parentId);
                        this.productionProcessChange(this.selected_pp);
                    })
                    .catch(error => console.error(error));
            } else {
                this.getCountries();
                this.getTable(this.$route.params.fsp_cond_structure_id);
            }
        },
        getCountries() {
            axios
                .get('/api/classifiers/countries?simple-format=true')
                .then(res => {
                    this.countries = res.data;
                })
                .catch(error => console.error(error));
        }
    }
};
</script>
