<template>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title hidden-xs-down">New Invoice</h4>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label>Target *</label>
                        <v-select
                            v-model="form.target"
                            index="id"
                            label="name"
                            :options="target_options"
                            :class="{ 'custom-is-invalid': !form.target }"
                            placeholder="- choose -"
                        >
                            <span slot="no-options">Nothing found</span>
                        </v-select>
                        <input type="hidden" class="form-control is-invalid" />
                        <span v-if="!form.target" class="invalid-feedback" role="alert">
                            <strong>Target required</strong>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label>Choose currency</label>
                        <v-select
                            v-model="form.currency"
                            class="selectGray"
                            :options="['EUR', 'USD', 'GBP']"
                            not-empty
                        />
                    </div>
                </div>
                <div class="col-6">
                    <div v-if="isRenewal" class="form-group">
                        <label>Choose period</label>
                        <div class="d-flex">
                            <b-form-group>
                                <b-form-radio-group v-model="form.period" name="choose_period">
                                    <template v-for="(p, i) in periods">
                                        <b-form-radio :value="p" :key="i">{{ p.name }}</b-form-radio>
                                    </template>
                                </b-form-radio-group>
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-check mb-3">
                <input id="custom-config" v-model="form.execute" type="checkbox" class="form-check-input" />
                <label for="custom-config" class="form-check-label">Execute actions</label>
            </div>
            <app-loader v-if="loading" size="medium" line-fg-color="#5ec58c" message="Loading..."></app-loader>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label>Emails to send invoice</label>
                        <v-select
                            v-model="form.invoice_email"
                            :options="invoiceEmail"
                            placeholder="- choose -"
                            taggable
                            multiple
                        >
                            <span slot="no-options">Nothing found</span>
                        </v-select>
                    </div>
                </div>
            </div>
            <button v-if="!loading" type="button" class="btn btn-success" :disabled="!formValid" @click="store">
                Save
            </button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            loading: false,
            periods: [],
            form: {
                currency: 'EUR',
                invoice_email: [],
                fhp: null,
                target: null,
                execute: false,
                period: false,
                techRows: [
                    {
                        hours: 0,
                        work_date: null,
                        comment: '',
                    }
                ],
            },
            target_options: [
                {id: 'haccp_renewal', name: 'HACCP edit and store'},
                {id: 'subscription_renewal', name: 'Renew subscription'},
                {id: 'haccp', name: 'Digital HACCP (downloadable)'},
            ],
        };
    },
    computed: {
        isRenewal() {
            return this.form.target === 'subscription_renewal' || this.form.target === 'haccp_renewal';
        },
        company() {
            return this.$store.getters.getCompany;
        },
        invoiceEmail() {
            if (this.$store.getters.getCompany && this.$store.getters.getCompany.invoice_email) {
                return this.$store.getters.getCompany.invoice_email.split(',');
            }
            return [];
        },
        nonNullPlaces() {
            let nonNullPlaces = [];
            if (!this.company || !this.company.places) {
                return [];
            }
            this.company.places.forEach(function(place) {
                if (place.name == null) {
                    place.name = '';
                }
                nonNullPlaces.push(place);
            });
            return nonNullPlaces;
        },
        formValid() {
            switch(this.form.target) {
                case 'haccp_renewal':
                case 'subscription_renewal':
                    return !!this.form.period.time_unit_id && !!this.form.period.time_unit_cnt;
                case 'haccp':
                    return !!this.form.currency;
                default:
                    return false;
            }
        },
    },
    mounted() {
        this.getCompanyPeriods();
    },
    methods: {
        store() {
            this.loading = true;
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    let invoiceData = {
                        target: this.form.target,
                        currency: this.form.currency,
                    };
                    if (this.form.invoice_email.length) {
                        invoiceData.invoice_email = this.form.invoice_email;
                    }

                    if(this.isRenewal){
                        invoiceData.timeUnitId = this.form.period.time_unit_id;
                        invoiceData.timeUnitCnt = this.form.period.time_unit_cnt;
                        invoiceData.execute = this.form.execute;
                    }
                    axios
                        .post(`/api/superadmin/companies/${this.$route.params.company_id}/invoices`, invoiceData)
                        .then(res => {
                            this.$toastr.i('Invoice created ID=' + res.data.id);
                            this.$store.dispatch('fetchCompany', this.$route.params.company_id);
                            this.loading = false;
                            this.$router.push({
                                name: 'company.invoices',
                                params: { company_id: this.$route.params.company_id }
                            });
                        })
                        .catch(error => {
                            this.loading = false;
                            this.showErrors(error);
                            console.error(error);
                        });
                }
            });
        },
        addTechRow() {
            this.form.techRows.push({
                hours: 0,
                work_date: null,
                comment: '',
            });
        },
        removeTechRow(index) {
            if(this.form.techRows.length > 1){
                this.form.techRows.splice(index, 1);
            }
        },
        getCompanyPeriods() {
            axios.get(`/api/superadmin/companies/${this.$route.params.company_id}/subscriptions/periods`).then(({ data }) => {
                this.periods = data;
            });
        },
    }
};
</script>
