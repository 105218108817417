var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.row.groups),function(group,i){return _c('div',{staticClass:"fsp-row-group"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10"},[_c('p',{staticClass:"pointer",staticStyle:{"background":"#efefef"},on:{"click":function($event){return _vm.receiveCondition(group)}}},[_vm._v(" Click to move to Group "+_vm._s(i + 1)+" ")])]),_c('div',{staticClass:"col-md-2"},[_c('button',{staticClass:"btn btn-danger btn-xs btn-block",attrs:{"title":"Delete Group"},on:{"click":function($event){return _vm.removeRowGroup(group.id, _vm.row.row.id, i)}}},[_vm._v(" x ")])])]),_vm._l((_vm.getColumnMembers(group.members)),function(cond,index){return _c('div',{key:cond.id,staticClass:"fsp-condition pointer",class:{
                active_condition: _vm.move_condition && _vm.move_condition.id === cond.id,
                'bg-primary has-proposals': _vm.getHazardEstimationExists(cond.id),
                'bg-success has-proposals': _vm.getConditionProposalsLength(cond.id),
            },on:{"click":function($event){return _vm.moveToGroup(cond, index, i)},"dblclick":function($event){_vm.hasDoubleClick && _vm.showGroupChanges(group.id)}}},[_c('button',{staticClass:"btn btn-primary edit-condition-button",on:{"click":function () { return _vm.goToConditionEdit(cond.id); }}},[_vm._v("✏️")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.getConditionName(cond.id))}}),_c('Images',{attrs:{"files":_vm.getConditionImages(cond.id)}})],1)}),_c('div',{staticClass:"clearfix"})],2)}),_vm._l((_vm.unGroupedConditions),function(cond,index){return _c('div',{key:cond.id,staticClass:"fsp-condition pointer",class:{
            active_condition: _vm.move_condition && _vm.move_condition.id === cond.id,
            'bg-success has-proposals': cond.text_change_proposals && cond.text_change_proposals.length,
        },on:{"click":function($event){return _vm.moveToGroup(cond, index)},"dblclick":function($event){_vm.hasDoubleClick &&
                cond.text_change_proposals &&
                cond.text_change_proposals.length &&
                _vm.showConditionChange(cond)}}},[_c('button',{staticClass:"btn btn-primary edit-condition-button",on:{"click":function () { return _vm.goToConditionEdit(cond.id); }}},[_vm._v("✏️")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.decipherAPIString(cond))}}),_c('Images',{attrs:{"files":_vm.getConditionImages(cond.id)}})],1)}),(_vm.conditionModal)?_c('ConditionChangeModal',{attrs:{"selected-group-id":_vm.selectedGroupId,"row":_vm.row,"columns":_vm.columns,"selected-condition":_vm.selectedCondition},on:{"close":_vm.closeModal}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }