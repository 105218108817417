var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('span',{staticClass:"pointer"},[(_vm.row.production_process_id)?_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:(_vm.row.production_process.name),expression:"row.production_process.name",modifiers:{"hover":true}}],staticClass:"fa fa-question-circle-o text-primary",attrs:{"title":"Production Process"}}):_vm._e(),(_vm.row.sub_process_id)?_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:(_vm.row.sub_process.name),expression:"row.sub_process.name",modifiers:{"hover":true}}],staticClass:"fa fa-question-circle-o text-primary",attrs:{"title":"Production Sub Process"}}):_vm._e(),_c('small',[_vm._v("[row]")]),_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._v(" - "),_c('router-link',{attrs:{"to":{
            name: 'fsp_cond.structure.column.create',
            params: { fsp_cond_structure_row_id: _vm.row.id }
        },"tag":"a"}},[_vm._v(" Add Column ")]),_vm._v(" | "),_c('router-link',{attrs:{"to":{
            name: 'fsp_cond.structure.row.edit',
            params: { fsp_cond_structure_row_id: _vm.row.id }
        },"tag":"a"}},[_vm._v(" Edit ")]),_vm._v(" | "),_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.destroyRow(_vm.row.id)}}},[_vm._v("Delete")]),_c('ul',{staticStyle:{"list-style":"none"}},[_c('app-draggable',_vm._b({attrs:{"tag":"span"},model:{value:(_vm.reorderedColumns),callback:function ($$v) {_vm.reorderedColumns=$$v},expression:"reorderedColumns"}},'app-draggable',{ animation: 200 },false),_vm._l((_vm.orderedColumns),function(column,columnIndex){return _c('li',[_c('span',{staticClass:"pointer"},[_c('small',[_vm._v("[column]")]),_vm._v(" "+_vm._s(_vm.columnTitle(column)))]),_vm._v(" - "),_c('router-link',{attrs:{"to":{
                        name: 'fsp_cond.structure.column.edit',
                        params: { fsp_cond_structure_column_id: column.id }
                    },"tag":"a"}},[_vm._v(" Edit ")]),_vm._v(" | "),_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.destroyColumn(column.id, columnIndex)}}},[_vm._v("Delete")])],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }