<template>
    <tr :id="'production_process_' + process.id">
        <td>{{ index + 1 }}</td>
        <td v-if="!editing">{{ process.name }}</td>
        <td v-else>
            <input
                id="name"
                v-model="form.name"
                v-validate="'required'"
                type="text"
                data-vv-name="name"
                data-vv-as="Name"
                :class="{ 'form-control': true, 'is-invalid': errors.has('name') }"
            />
            <span v-show="errors.has('name')" class="invalid-feedback" role="alert">
                <strong>{{ errors.first('name') }}</strong>
            </span>
        </td>
        <td v-if="!editing">{{ process.tooltip }}</td>
        <td v-else>
            <input
                id="tooltip"
                v-model="form.tooltip"
                type="text"
                data-vv-name="tooltip"
                data-vv-as="Tooltip"
                class="form-control"
            />
        </td>
        <td>
            <toggle-button
                :value="process.always_on == 1 ? true : false"
                color="#5ec58c"
                :labels="{ checked: 'yes', unchecked: 'no' }"
                @change="toggleAlwaysOn(process.id)"
            />
        </td>
        <td>
            <div class="pull-right">
                <a v-if="!editing" href="javascript:void(0)" @click="edit(process)">Edit</a>
                <a v-else href="javascript:void(0)" @click="cancelEdit(process)">Cancel</a>
                |
                <a v-if="!editing" href="javascript:void(0)" @click="destroy(process.id)">Delete</a>
                <a v-else href="javascript:void(0)" @click="update(process.id)">Update</a>
                |
                <router-link v-if="!editing" tag="a" :to="{
                    name: 'smartfilter.pp.copy',
                    params: { id: process.id } 
                }">
                    Copy
                </router-link>
            </div>
        </td>
    </tr>
</template>

<script>
import axios from 'axios';

export default {
    props: ['process', 'index'],
    data() {
        return {
            editing: false,
            form: {},
            original: {}
        };
    },
    methods: {
        edit(process) {
            this.form = process;
            this.original = Object.assign({}, process);
            this.editing = true;
        },
        cancelEdit(process) {
            Object.assign(process, this.original);
            this.form = this.original = null;
            this.editing = false;
        },
        update(id) {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    axios.put('/api/superadmin/production-process/update/' + id, this.form).then(res => {
                        this.$toastr.i(res.data.message);
                        this.editing = false;
                    });
                }
            });
        },
        destroy(id) {
            if (!window.confirm('Delete?')) {
                return false;
            }
            axios
                .delete('/api/superadmin/production-process/destroy/' + id)
                .then(res => {
                    this.$toastr.i(res.data.message);
                    this.$emit('removeProdProcess', this.index);
                })
                .catch(error => {
                    this.$toastr.e(error.response.data.message);
                });
        },
        toggleAlwaysOn(id) {
            axios
                .put('/api/superadmin/production-process/toggle-always-on/' + id, {})
                .then(res => {
                    this.$toastr.i(res.data.message);
                })
                .catch(error => console.error(error));
        }
    }
};
</script>
