<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <PaginationPanel :total-rows="total" @search="search" />
                <app-loader v-if="loading" line-fg-color="#5ec58c" message="Loading..." size="small" />
                <div v-else class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th class="pointer" @click="orderBy('country.name')">
                                    Country
                                    <span v-html="order_field == 'country' ? active_sort_icon : sort_icon"></span>
                                </th>
                                <th>Places</th>
                                <th class="pointer" @click="orderBy('name')">
                                    Name
                                    <span v-html="order_field == 'name' ? active_sort_icon : sort_icon"></span>
                                </th>
                                <th>Representatives</th>
                                <th>Phone</th>
                                <th class="pointer" @click="orderBy('next_payment_date')">
                                    Next payment date
                                    <span
                                        v-html="order_field == 'next_payment_date' ? active_sort_icon : sort_icon"
                                    ></span>
                                </th>
                                <th class="pointer created_at" @click="orderBy('created_at')">
                                    Created
                                    <span v-html="order_field == 'created_at' ? active_sort_icon : sort_icon"></span>
                                </th>
                                <th class="pointer" @click="orderBy('unpaid_amount')">
                                    Unpaid &euro;
                                    <span v-html="order_field == 'unpaid_amount' ? active_sort_icon : sort_icon"></span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <app-company
                                v-for="(company, index) in orderedCompanies"
                                :id="'company_' + company.id"
                                :key="company.id"
                                :company="company"
                                :index="index"
                            />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Company from './CompanyTableRow';
import PaginationPanel from '../common/PaginationPanel';
import axios from 'axios';

export default {
    components: {
        PaginationPanel,
        appCompany: Company
    },
    data() {
        return {
            loading: true,
            companies: [],
            total: 0,
            order_field: 'created_at',
            order_dir: 'desc',
            sort_icon: '<i class="fa fa-sort"></i>'
        };
    },
    computed: {
        orderedCompanies() {
            return _.orderBy(this.companies, this.order_field, this.order_dir);
        },
        active_sort_icon() {
            if (this.order_dir == 'desc') {
                return '<i class="fa fa-sort-down"></i>';
            } else {
                return '<i class="fa fa-sort-up"></i>';
            }
        }
    },
    created() {
        if (this.$route.query.scrollback_id) {
            let row = this.$el.querySelector('#company_' + this.$route.query.scrollback_id);
            row.scrollIntoView();
        }
    },
    methods: {
        search(params) {
            this.loading = true;
            axios.get(`/api/superadmin/companies`, {params}).then(({ data }) => {
                if(data && data.data) {
                    data.data.forEach((company) => {
                        let unpaid_invoices = company.invoices.filter(invoice => {
                            return invoice.paid === false;
                        });
                        let unpaid_amount = unpaid_invoices.reduce((start, invoice) => start + invoice.amount, 0);
                        company.unpaid_amount = unpaid_amount / 100 || 0;
                    });
                    this.companies = data && data.data;
                    this.total = data && data.total || 0;
                } else {
                    this.companies = [];
                    this.total = 0;
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        orderBy(field) {
            this.order_field = field;
            if (this.order_dir == 'desc') {
                this.order_dir = 'asc';
            } else {
                this.order_dir = 'desc';
            }
        },
    }
};
</script>
