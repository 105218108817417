<template>
    <div class="tab-content">
        <app-loader v-if="loading" line-fg-color="#5ec58c" message="Loading..." size="small" />
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <h4>Download Base Template</h4>
                <button type="button" class="btn btn-success" @click="downloadTemplate">Download</button>
                <hr />
                <div class="mb-3">
                    Note - Products imported at company level will be available to all places and if imported at place
                    level will be available only inside that place.
                </div>
                <div class="row">
                    <div class="input-group mb-3 col-xs-12 col-sm-6">
                        <div class="custom-file">
                            <input
                                id="file-upload"
                                type="file"
                                class="custom-file-input"
                                accept=".xls,.xlsx"
                                @change="onFileUpload"
                            />
                            <label class="custom-file-label" for="file-upload">{{
                                (file && file.name) || 'Choose file'
                            }}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-6">
                        <button type="button" class="btn btn-success btn-block" :disabled="loading" @click="onImport">
                            Import
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            file: null
        };
    },
    computed: {
        company() {
            return this.$store.getters.getCompany;
        },
        loading() {
            return this.$store.getters.isLoading;
        },
        place() {
            return this.$store.getters.getPlace;
        }
    },
    created() {},
    methods: {
        onFileUpload(event) {
            if (event.target.files.length) {
                this.file = event.target.files[0];
            }
        },
        downloadTemplate() {
            const fileName = `products_import.xlsx`;
            const fileLocation = `/assets/data/${fileName}`;

            const link = document.createElement('a');
            link.href = fileLocation;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        onImport() {
            if (this.file) {
                this.$store.dispatch('startLoader');
                let formData = new FormData();
                formData.append('file', this.file);
                const url = this.place && this.place.id ? `place/${this.place.id}` : `companies/${this.company.id}`;
                axios
                    .post(`/api/superadmin/${url}/products/import`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(res => {
                        this.$toastr.i(res.data.message);
                        this.file = null;
                    })
                    .catch(error => {
                        this.$toastr.e(error.response.data.message);
                    })
                    .finally(() => {
                        this.$store.dispatch('stopLoader');
                    });
            }
        }
    }
};
</script>
