import axios from 'axios';
import Vue from 'vue';

const state = {
    searchString: '',
    perPage: 50,
    currentPage: 1,
    total: 0,
    selectedCountries: [],
    selectedBusinessActivities: [],
    selectedFSPTable: null,
    conditions: [],
    languages: [],
    countries: [],

    hasProposals: false,
    fspConditionTables: [],
    selectedFspTableId: null,
    selectedFspConditionTableRows: [],
    selectedFspConditionTableRowObjects: [],
};

const mutations = {
    setLanguages(state, data) {
        state.languages = data;
    },
    setCountries(state, data) {
        state.countries = data;
    },
    setSelectedFspTableId(state, data) {
        state.selectedFspTableId = data;
    },
    setSelectedFspConditionTableRows(state, data) {
        state.selectedFspConditionTableRows = data;
    },
    setSelectedFspConditionTableRowObjects(state, data) {
        state.selectedFspConditionTableRowObjects = data;
    },
    setHasProposals(state, data) {
        state.hasProposals = data;
    },
    setFspConditionTables(state, data) {
        state.fspConditionTables = data;
    },
    leaveConditionsView(state, body) {
        state.conditions = body.conditions;
        state.searchString = body.searchString;
        state.perPage = body.perPage;
        state.currentPage = body.currentPage;
        state.total = body.total;
        state.selectedCountries = body.selectedCountries;
        state.selectedBusinessActivities = body.selectedBusinessActivities;
        state.selectedFSPTable = body.selectedFSPTable;
    },
    addRowGroup(state, {id, data}) {
        state.selectedFspConditionTableRowObjects?.find(obj => +obj?.row?.id === +id)?.groups?.push(data);
    },
    removeRowGroup(state, { rowId, groupId }) {
        let groups = state.selectedFspConditionTableRowObjects?.find(obj => +obj?.row?.id === +rowId)?.groups;
        if(groups) {
            const index = groups.findIndex(group => +group.id !== +groupId);
            if(index !== -1) {
                Vue.delete(groups, index);
            }
        }
    },
};

const actions = {
    addRowGroup({ commit }, { id }) {
        return axios.post(`/api/superadmin/fsp-table-row-groups`, {container_fsp_section_id: id})
            .then(({ data }) => {
                if(data){
                    commit('addRowGroup', {id, data, });
                }
            })
            .catch(error => console.error(error));
    },
    checkIfAppHasProposals({ commit, }) {
        axios.get(`/api/superadmin/fsp-table-row-groups/change-proposals-exist`).then(({ data }) => {
            commit('setHasProposals', data);
        });
    },
    fetchLanguages({ commit }) {
        axios
            .get('/api/classifiers/all-languages')
            .then(res => {
                commit('setLanguages', res.data);
            })
            .catch(error => console.error(error));
    },
    fetchCountries({ commit }) {
        axios
            .get('/api/classifiers/countries')
            .then(res => {
                commit('setCountries', res.data);
            })
            .catch(error => console.error(error));
    },
    fetchFspConditionTables({ commit }) {
        axios.get('/api/superadmin/fsp-tables?documentTypeId=1').then(response => {
            commit('setFspConditionTables', response.data.data);
        });
    },
    async selectFetchFspConditionTables({ commit, getters }, table) {
        const tableId = table?.id ? table.id : getters.getSelectedFspTableId;

        if(tableId) {
            await Promise.all([
                axios.get(`/api/superadmin/fsp-table-rows?tableId=${tableId}`).then(response => {
                    this.tableRows = response.data.data;
                    commit('setSelectedFspConditionTableRows', response.data.data);
                }),
                axios.get(`/api/superadmin/fsp-table-row-groups?tableId=${tableId}`).then(response => {
                    commit('setSelectedFspConditionTableRowObjects', response.data);
                }),
            ]).then(() => {
                commit('setSelectedFspTableId', tableId);
            });
        }
    }
};

const getters = {
    getCountries: (state) => {
        return state.countries;
    },
    getLanguages: (state) => {
        return state.languages;
    },
    getSelectedFspTableId: (state) => {
        return state.selectedFspTableId;
    },
    getSelectedFspConditionTableRows: (state) => {
        return state.selectedFspConditionTableRows;
    },
    getSelectedFspConditionTableRowObjects: (state) => {
        return state.selectedFspConditionTableRowObjects;
    },
    hasProposals: (state) => {
        return state.hasProposals;
    },
    fspConditionTables: (state) => {
        return state.fspConditionTables;
    },
    enterConditionsView(state) {
        return {
            conditions: state.conditions,
            searchString: state.searchString,
            perPage: state.perPage,
            currentPage: state.currentPage,
            total: state.total,
            selectedCountries: state.selectedCountries,
            selectedBusinessActivities: state.selectedBusinessActivities,
            selectedFSPTable: state.selectedFSPTable,
        };
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
