<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <input
                        v-model="filter_term_pp"
                        type="text"
                        class="form-control mb-3 col-7"
                        placeholder="Filter by Process"
                    />
                    <div class="col-5 mt-2">
                        <span class="pull-right">
                            <router-link tag="a" :to="{ name: 'smartfilter.pp.create' }">
                                New Process
                            </router-link>
                        </span>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div v-if="loading" class="row permissions-loader">
                    <div class="col">
                        <app-loader line-fg-color="#5ec58c" message="Loading..." size="small" class="m-auto" />
                    </div>
                </div>
                <div v-else class="table-responsive">
                    <table v-if="filteredProcesses.length > 0" class="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Tooltip</th>
                                <th>Always show</th>
                                <th></th>
                            </tr>
                        </thead>
                        <app-draggable v-model="reorderedFilteredProcesses" v-bind="{ animation: 200 }" tag="tbody" :move="cardMoved" :component-data="getComponentData()">
                            <app-process
                                v-for="(process, index) in filteredProcesses"
                                :key="process.id"
                                :index="index"
                                :process="process"
                            >
                            </app-process>
                        </app-draggable>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Process from './ProcessTableRow';
import appDraggable from 'vuedraggable';

export default {
    components: {
        appProcess: Process,
        appDraggable,
    },
    data() {
        return {
            processes: [],
            filter_term_pp: '',
            loading: false,
        };
    },
    computed: {
        filteredProcesses() {
            return this.processes.filter(el => {
                if (!el.name) {
                    return false;
                }
                return el.name.toLowerCase().match(this.filter_term_pp.toLowerCase());
            });
        },
        reorderedFilteredProcesses: {
            get() {
                return this.filteredProcesses;
            },
            async set(rows) {
                this.loading = true;
                await axios.put('/api/superadmin/production-process/reorder', rows).then(res => {
                    this.$toastr.i('Rows are reordered!');
                    this.processes = res.data;
                });
                this.loading = false;
            }
        }
    },
    created() {
        this.fetchProcesses();
    },
    methods: {
        fetchProcesses() {
            axios
                .get('/api/classifiers/production-processes')
                .then(res => {
                    this.processes = res.data;
                })
                .catch(error => console.error(error));
        },
        removeProdProcess(index) {
            this.processes.splice(index, 1);
        },
        cardMoved(event) {
            console.log(event);
        },
        getComponentData() {
            return {
                on: {
                    change: (event) => {
                        console.log('change', event);
                    }
                }
            };
        },
    }
};
</script>
