<template>
    <div>
        <div v-for="(group, i) in row.groups" class="fsp-row-group">
            <div class="row">
                <div class="col-md-10">
                    <p style="background: #efefef" class="pointer" @click="receiveCondition(group)">
                        Click to move to Group {{ i + 1 }}
                    </p>
                </div>
                <div class="col-md-2">
                    <button
                        class="btn btn-danger btn-xs btn-block"
                        title="Delete Group"
                        @click="removeRowGroup(group.id, row.row.id, i)"
                    >
                        x
                    </button>
                </div>
            </div>
            <div
                v-for="(cond, index) in getColumnMembers(group.members)"
                :key="cond.id"
                class="fsp-condition pointer"
                :class="{
                    active_condition: move_condition && move_condition.id === cond.id,
                    'bg-primary has-proposals': getHazardEstimationExists(cond.id),
                    'bg-success has-proposals': getConditionProposalsLength(cond.id),
                }"
                @click="moveToGroup(cond, index, i)"
                @dblclick="hasDoubleClick && showGroupChanges(group.id)"
            >
                <button class="btn btn-primary edit-condition-button" @click="() => goToConditionEdit(cond.id)">✏️</button>
                <span v-html="getConditionName(cond.id)"></span>
                <Images :files="getConditionImages(cond.id)" />
            </div>
            <div class="clearfix"></div>
        </div>
        <div
            v-for="(cond, index) in unGroupedConditions"
            :key="cond.id"
            class="fsp-condition pointer"
            :class="{
                active_condition: move_condition && move_condition.id === cond.id,
                'bg-success has-proposals': cond.text_change_proposals && cond.text_change_proposals.length,
            }"
            @click="moveToGroup(cond, index)"
            @dblclick="
                hasDoubleClick &&
                    cond.text_change_proposals &&
                    cond.text_change_proposals.length &&
                    showConditionChange(cond)
            "
        >
            <button class="btn btn-primary edit-condition-button" @click="() => goToConditionEdit(cond.id)">✏️</button>
            <span v-html="decipherAPIString(cond)"></span>
            <Images :files="getConditionImages(cond.id)" />
        </div>

        <ConditionChangeModal
            v-if="conditionModal"
            :selected-group-id="selectedGroupId"
            :row="row"
            :columns="columns"
            :selected-condition="selectedCondition"
            @close="closeModal"
        ></ConditionChangeModal>
    </div>
</template>

<script>
import Images from './Images';
import ConditionChangeModal from './ConditionChangeModal/index';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
    components: {
        ConditionChangeModal,
        Images
    },
    props: {
        row: {
            type: Object,
            default: () => ({}),
        },
        column: {
            type: Object,
            default: () => ({}),
        },
        deleteGroup: {
            type: Function,
            default: () => null,
        },
        columns: {
            type: Array,
            default: () => [],
        },
        hasDoubleClick: Boolean,
        selectedParentTableId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            move_condition: null,
            move_to_group: null,
            active_index: null,
            currentSelectedGroupIndex: null,
            conditionModal: false,
            selectedGroupId: null,
            selectedCondition: null,
            hazardEstimationsTypes: []
        };
    },
    computed: {
        ...mapGetters([ 'getSelectedFspTableId' ]),
        allConditions() {
            let allCons = [];
            this.column.children.forEach(cond => {
                if (cond.level_number === 5) {
                    allCons.push({
                        parent: {
                            level_number: 4,
                            id: this.column.id,
                            static_name: this.column.static_name
                        },
                        ...cond
                    });
                }
            });
            return allCons;
        },
        groupedConditionIds() {
            let ids = [];
            this.row.groups.forEach(group => {
                group.members.forEach(member => {
                    ids.push(member.id);
                });
            });
            return ids;
        },
        unGroupedConditions() {
            return this.allConditions.filter(cond => !this.groupedConditionIds.includes(cond.id));
        }
    },
    methods: {
        goToConditionEdit(id) {
            this.$router.push({
                name: 'fsp_conditions.edit',
                params: { fsp_condition_id: id, },
                query: {
                    'scrollY': window.scrollY,
                    'selectedParentTableId': this.getSelectedFspTableId,
                },
            });
        },
        async closeModal(hasChanges) {
            if (hasChanges) {
                await this.$store.dispatch('checkIfAppHasProposals');
                await this.$store.dispatch('fetchFspConditionTables');
                await this.$store.dispatch('selectFetchFspConditionTables');
            }
            this.conditionModal = false;
            this.selectedGroupId = null;
            this.selectedCondition = null;
        },
        showConditionChange(condition) {
            this.selectedCondition = condition;
            this.conditionModal = true;
        },
        showGroupChanges(groupId) {
            this.selectedGroupId = groupId;
            this.conditionModal = true;
        },
        getColumnMembers(members) {
            const filteredMembers = members.filter(m => m.parent.id === this.column.id);
            return filteredMembers.sort((a, b) => a.order_number - b.order_number);
        },
        getConditionName(conditionId) {
            const condition = this.allConditions.filter(con => con.id === conditionId);
            return condition && condition.length ? this.decipherAPIString(condition[0]) : 'translation not found';
        },
        getConditionProposals(conditionId) {
            const condition = this.allConditions.find(con => con.id === conditionId);
            return condition?.text_change_proposals;
        },
        getConditionProposalsLength(conditionId) {
            const condition = this.allConditions.find(con => con.id === conditionId);
            return condition?.text_change_proposals?.length;
        },
        getHazardEstimationExists(conditionId) {
            const i = this.row.groups.find(item => item.members.find(subItem => subItem.id === conditionId));
            if (i.hazard_estimation != null) {
                return 0;
            }
            return 1;
        },
        getConditionImages(conditionId) {
            const condition = this.allConditions.filter(con => con.id === conditionId);
            return condition && condition.length ? condition[0].file_list : [];
        },
        decipherAPIString(string) {
            let out;
            const english = string?.section_translations?.find(s => s.language_id === 43)?.translation_text;
            const staticName = string?.static_name;
            out = english || staticName;
            if (!out) {
                out = string?.section_translations?.find(s => s.translation_text)?.translation_text;
            }

            return out;
        },
        receiveCondition(group) {
            if (this.move_condition == null) {
                alert('Choose condition first');
                return false;
            }
            let currentGroup = {};
            let currentGroupIndex = null;
            this.row.groups.map((g, i) => {
                if (g.id === group.id) {
                    currentGroup = g;
                    currentGroupIndex = i;
                }
            });

            if (currentGroup) {
                let newMembersList = [];
                currentGroup.members.forEach(m => newMembersList.push({id: m.id}));
                newMembersList.push({id: this.move_condition.id});
                axios
                    .put(`/api/superadmin/fsp-table-row-groups/${group.id}`, {
                        container_fsp_section_id: this.row.row.id,
                        members: newMembersList
                    })
                    .then(() => {
                        this.$toastr.i('Moved to group!');
                        if (this.currentSelectedGroupIndex || this.currentSelectedGroupIndex === 0) {
                            this.row.groups[this.currentSelectedGroupIndex].members = this.row.groups[
                                this.currentSelectedGroupIndex
                            ].members.filter(g => g.id !== this.move_condition.id);
                        }
                        this.row.groups[currentGroupIndex].members.push(
                            this.allConditions.filter(ac => ac.id === this.move_condition.id)[0]
                        );
                    })
                    .then(() => {
                        this.move_condition = null;
                        this.move_to_group = null;
                        this.active_index = null;
                        this.currentSelectedGroupIndex = null;
                    })
                    .catch(error => console.error(error));
            }
        },
        moveToGroup(cond, index, currentGroupIndex) {
            this.active_index = null;
            this.active_index = index;
            this.move_condition = cond;
            this.currentSelectedGroupIndex = currentGroupIndex;
        },
        removeRowGroup(groupId, rowId, index) {
            if (!window.confirm('Delete?')) {
                return false;
            }
            axios
                .delete(`/api/superadmin/fsp-table-row-groups/${groupId}`)
                .then(() => {
                    this.$toastr.i('Row group deleted!');
                    this.deleteGroup(index);
                })
                .catch(error => console.error(error));
        }
    }
};
</script>

<style>
.edit-condition-button {
    border: 1px solid black;
}
</style>