<template>
    <div class="tab-content">
        <div class="card-body">
            <PaginationPanel search-term="searchText" :total-rows="total" :per-page="10" @search="search">
                <template #Language>
                    <v-select
                        v-model="selectedLangs"
                        close-on-select
                        multiple
                        :options="displayLangs"
                        label="name"
                        placeholder="- choose -"
                    >
                        <span slot="no-options">Nothing found</span>
                    </v-select>
                </template>
            </PaginationPanel>
            <app-loader v-if="loading" size="medium" line-fg-color="#5ec58c" message="Loading..."></app-loader>
            <div v-if="!loading && conditions.length > 0" class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Translations</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="condition in conditions">
                            <td>{{ condition.id }}</td>
                            <td>
                                <div class="row my-2">
                                    <template v-for="lang in showTranslation(condition.section_translations)">
                                        <div :key="`transl_${condition.id}_${lang.language_id}`" class="col-12 col-md-6 col-4 mt-3">
                                            <strong class="mb-3">{{ getLang(lang.language_id, 'name') }}</strong>
                                            <quill-editor
                                                v-model="lang.translation_text"
                                            ></quill-editor>
                                            <div>
                                                <span class="mr-2">Notify user?</span> <input v-model="lang.notify_users" type="checkbox">
                                            </div>
                                        </div>
                                    </template>
                                    <div class="col-12 text-right">
                                        <button class="btn btn-primary mt-5" @click="store(condition)">Save</button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import PaginationPanel from '../../common/PaginationPanel';
import axios from 'axios';

export default {
    components: {
        PaginationPanel,
    },
    data() {
        return {
            conditions: [],
            langs: [],
            displayLangOptions: [],
            selectedLangs: [],
            source: null,
            from_lang: null,
            needs_saving: false,
            form: {},
            total: 0,
            loading: false,
        };
    },
    computed: {
        role() {
            return this.$store.getters.getUserRole;
        },
        displayLangs() {
            try {
                const role = this.role.split(':')[0];
                if(role !== 'translator') { throw 'Not a translator.';}
                const availableLangs = this.role.split(':')[1].split(',');
                return this.langs.filter((lang) => lang && lang.iso2 && availableLangs.includes(lang.iso2));
            } catch {
                return this.langs.filter((lang) => lang && lang.iso2 && this.displayLangOptions.includes(lang.iso2));
            }
        },
    },
    created() {
        this.getLanguages();
    },
    methods: {
        showTranslation(translations) {
            if ( !(this.selectedLangs && this.selectedLangs.length) ) {
                return translations.filter((translation) => {
                    return !!this.displayLangs.find((lang) => lang.id === translation.language_id);
                });
            }
            return translations.filter((translation) => {
                return !!this.selectedLangs.find((lang) => lang.id === translation.language_id);
            });
        },
        getLang(langId, param) {
            const langItem = this.langs.find((lang) => lang.id === langId);
            if(param) {
                try {
                    return langItem[param];
                } catch {
                    return null;
                }
            }
            return langItem;
        },
        store(condition) {
            const form = {
                translations_only: 1,
                section_translations: condition.section_translations.filter((translation) => {
                    return !!this.displayLangs.find((lang) => lang.id === translation.language_id && translation.translation_text);
                }).map((lang) => ({
                    ...lang,
                    language: this.getLang(lang.language_id, 'iso2'),
                })),
            };
            axios.put(`/api/superadmin/food-safety-plan-conditions/${condition.id}?translations_only=1`, form).then(({data}) => {
                if(data){
                    this.$toastr.s('Translation successfully changed!');
                } else {
                    this.$toastr.e('Something wen\'t wrong, please try again');
                }
            }).catch((error) => {
                this.$toastr.e(error.response.data.message);
            });
        },
        search(params) {
            this.loading = true;
            axios
                .get('/api/superadmin/food-safety-plan-conditions', { params: { ...params, translations_only: 1} } )
                .then(({data}) => {
                    if(data && data.data){
                        this.conditions = data.data.map((condition) => {
                            const out = [];
                            const translations = condition.section_translations || [];
                            this.displayLangs.forEach((lang) => {
                                const currentLang = translations.find((l) => l.language_id === lang.id) ||
                                    {attribute_name: 'section_text', language_id: lang.id, translation_text: '', notify_users: false, };
                                out.push(currentLang);
                            });
                            return {...condition, section_translations: out};
                        });
                        this.total = data && data.total || 0;
                    } else {
                        this.conditions = [];
                        this.total = 0;
                    }
                })
                .catch(error => console.error(error))
                .finally(() => {
                    this.loading = false;
                });
        },
        getLanguages() {
            axios
                .get('/api/classifiers/all-languages')
                .then(res => {
                    this.langs = res.data;
                })
                .catch(error => console.error(error));

            axios
                .get('/api/superadmin/food-safety-plan-conditions/languages')
                .then(res => {
                    this.displayLangOptions = res.data.map((lang) => lang.code);
                })
                .catch(error => console.error(error));
        }
    }
};
</script>
