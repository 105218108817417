<template>
    <tr>
        <td>{{ index + 1 }}</td>
        <td>{{ table.static_name ? table.static_name : '- not stored -' }}</td>
        <td>{{ table.parent && table.parent.static_name ? table.parent.static_name : '- not chosen -' }}</td>
        <td class="pointer">{{ title }}</td>
        <td>
            <a
                data-toggle="collapse"
                :href="'#structure_' + table.id"
                role="button"
                aria-expanded="false"
                :aria-controls="'structure_' + table.id"
                @click="getStructure"
            >
                Show Structure
            </a>
            <ul v-if="reorderedRows && reorderedRows.length" :id="'structure_' + table.id">
                <app-draggable v-model="reorderedRows" v-bind="{ animation: 200 }" tag="span">
                    <app-rows-columns
                        v-for="(row, index) in orderedRows"
                        :key="index"
                        :row="row"
                        :index="index"
                        @removeRow="removeRow"
                    >
                    </app-rows-columns>
                </app-draggable>
            </ul>
        </td>
        <td>
            <span class="pull-right">
                <router-link
                    :to="{
                        name: 'fsp_cond.structure.row.create',
                        params: { fsp_cond_structure_id: table.id }
                    }"
                    tag="a"
                >
                    Add Row
                </router-link>
                |
                <router-link
                    :to="{
                        name: 'fsp_cond.structure.edit',
                        params: { fsp_cond_structure_id: table.id }
                    }"
                    tag="a"
                >
                    Edit
                </router-link>
                |
                <a href="javascript:void(0)" @click="destroyTable(table.id)">Delete</a>
            </span>
        </td>
    </tr>
</template>

<script>
import appDraggable from 'vuedraggable';
import appRowsColumns from './FspConditionStructureRowsColumns';
import axios from 'axios';
import _ from 'lodash';

export default {
    components: {
        appDraggable,
        appRowsColumns
    },
    props: ['table', 'index'],
    data() {
        return {
            location_rows: []
        };
    },
    computed: {
        title() {
            return this.decipherAPIString(this.table) !== 'not stored' ? this.decipherAPIString(this.table) : (this.table.static_name ? this.table.static_name : 'translation not found');
        },
        orderedRows() {
            return _.orderBy(this.location_rows, 'order_number', 'asc');
        },
        reorderedRows: {
            get() {
                return this.orderedRows;
            },
            set(rows) {
                axios.put('/api/superadmin/fsp-table-rows/reorder', {
                    sections: rows
                }).then(res => {
                    this.$toastr.i('Rows are reordered!');
                    this.location_rows = res.data;
                });
            }
        }
    },
    methods: {
        decipherAPIString(string) {
            try {
                return string.section_translations.filter(s => s.language_id === 43)[0].translation_text;
            } catch (e) {
                try {
                    return string.static_name;
                } catch (e) {
                    return 'not stored';
                }
            }
        },
        destroyTable(id) {
            if (!window.confirm('Delete table?')) {
                return false;
            }

            axios.delete('/api/superadmin/fsp-tables/' + id)
                .then(() => {
                    this.$toastr.i(`Row ${this.index} removed.`);
                    this.$emit('removeStructure', this.index);
                })
                .catch(() => {
                    this.$toastr.e('Error when moving row.');
                });
        },
        removeRow(index) {
            this.location_rows.splice(index, 1);
        },

        getStructure() {
            axios.get(`/api/superadmin/fsp-table-rows?tableId=${this.table.id}`).then(res => {
                this.location_rows = res.data.data;
            }).catch(error => {
                this.load_error = true;
                console.error(error);
            });
        }
    }
};
</script>

<style>
.pointer {
    cursor: pointer;
}
</style>
