<template>
    <li>
        <span class="pointer">
            <i
                v-if="row.production_process_id"
                v-b-popover.hover="row.production_process.name"
                class="fa fa-question-circle-o text-primary"
                title="Production Process"
            >
            </i>
            <i
                v-if="row.sub_process_id"
                v-b-popover.hover="row.sub_process.name"
                class="fa fa-question-circle-o text-primary"
                title="Production Sub Process"
            >
            </i>
            <small>[row]</small>
            {{ title }}
        </span>
        -
        <router-link
            :to="{
                name: 'fsp_cond.structure.column.create',
                params: { fsp_cond_structure_row_id: row.id }
            }"
            tag="a"
        >
            Add Column
        </router-link>
        |
        <router-link
            :to="{
                name: 'fsp_cond.structure.row.edit',
                params: { fsp_cond_structure_row_id: row.id }
            }"
            tag="a"
        >
            Edit
        </router-link>
        |
        <a href="javascript:void(0)" @click="destroyRow(row.id)">Delete</a>
        <ul style="list-style: none">
            <app-draggable v-model="reorderedColumns" v-bind="{ animation: 200 }" tag="span">
                <li v-for="(column, columnIndex) in orderedColumns">
                    <span class="pointer"><small>[column]</small> {{ columnTitle(column) }}</span>
                    -
                    <router-link
                        :to="{
                            name: 'fsp_cond.structure.column.edit',
                            params: { fsp_cond_structure_column_id: column.id }
                        }"
                        tag="a"
                    >
                        Edit
                    </router-link>
                    |
                    <a href="javascript:void(0)" @click="destroyColumn(column.id, columnIndex)">Delete</a>
                </li>
            </app-draggable>
        </ul>
    </li>
</template>

<script>
import appDraggable from 'vuedraggable';
import axios from 'axios';
import _ from 'lodash';

export default {
    components: {
        appDraggable
    },
    props: ['row', 'index'],
    computed: {
        title() {
            return this.decipherAPIString(this.row) !== 'translation not found' ? this.decipherAPIString(this.row) : (this.row.static_name ? this.row.static_name : 'translation not found');
        },
        orderedColumns() {
            return _.orderBy(this.row.children, 'order_number', 'asc');
        },
        reorderedColumns: {
            get() {
                return this.orderedColumns;
            },
            set(columns) {
                axios.put('/api/superadmin/fsp-table-row-columns/reorder', {sections: columns}).then(res => {
                    this.$toastr.i('Columns changed!');
                    this.row.children = res.data;
                });
            }
        }
    },
    methods: {
        columnTitle(col) {
            return this.decipherAPIString(col) !== 'translation not found' ? this.decipherAPIString(col) : (col.static_name ? col.static_name : 'translation not found');
        },
        destroyRow(id) {
            if (!window.confirm('Delete row?')) {
                return false;
            }
            axios
                .delete('/api/superadmin/fsp-table-rows/' + id)
                .then(() => {
                    this.$toastr.i(`Deleted row with id: ${id}`);
                    this.$emit('removeRow', this.index);
                })
                .catch(() => {
                    this.$toastr.e('Deleting row failed!');
                });
        },
        decipherAPIString(string) {
            try {
                return string.section_translations.filter(s => s.language_id === 43)[0].translation_text;
            } catch (e) {
                try {
                    return string.static_name;
                } catch (e) {
                    return 'translation not found';
                }            }
        },
        destroyColumn(id, index) {
            if (!window.confirm('Delete column?')) {
                return false;
            }

            axios
                .delete('/api/superadmin/fsp-table-row-columns/' + id)
                .then(() => {
                    this.$toastr.i(`Deleted column with id: ${id}`);
                    this.row.children.splice(index, 1);
                })
                .catch(() => {
                    this.$toastr.e('Deleting column failed!');
                });
        }
    }
};
</script>

<style>
.pointer {
    cursor: pointer;
}
</style>
