var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',[_vm._v(_vm._s(_vm.group.id))]),(!_vm.editing)?_c('td',[_vm._v(_vm._s(_vm.group.name))]):_vm._e(),(_vm.editing)?_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
                'form-control': true,
                'is-invalid': _vm.errors.has('name')
            },attrs:{"type":"text","data-vv-name":"name"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}})]):_vm._e(),(!_vm.editing)?_c('td',[_vm._v(_vm._s(_vm.group.description))]):_vm._e(),(_vm.editing)?_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.description),expression:"form.description"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
                'form-control': true,
                'is-invalid': _vm.errors.has('description')
            },attrs:{"type":"text","data-vv-name":"description"},domProps:{"value":(_vm.form.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "description", $event.target.value)}}})]):_vm._e(),(!_vm.editing)?_c('td',_vm._l((_vm.group.places),function(place){return _c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(place.name)+" ")])}),0):_vm._e(),(_vm.editing)?_c('td',[_c('app-multiselect',{attrs:{"options":_vm.places.map(function (p) { return p.id; }),"multiple":"","close-on-select":false,"open-direction":"bottom","placeholder":"Type to search","custom-label":function (id) { return _vm.places.some(function (p) { return p.id === id; })
                        ? ((_vm.places.find(function (p) { return p.id === id; }).name) + " " + (_vm.places.find(function (p) { return p.id === id; }).company
                                ? ("- " + (_vm.places.find(function (p) { return p.id === id; }).company.name))
                                : ''))
                        : null; }},model:{value:(_vm.group.placeIds),callback:function ($$v) {_vm.$set(_vm.group, "placeIds", $$v)},expression:"group.placeIds"}})],1):_vm._e(),_c('td',[_c('div',{staticClass:"float-right"},[(_vm.editing)?_c('a',{staticClass:"pointer",on:{"click":function($event){return _vm.update(_vm.group.id)}}},[_vm._v("Update | ")]):_vm._e(),(_vm.editing)?_c('a',{staticClass:"pointer",on:{"click":function($event){return _vm.cancelEdit(_vm.group)}}},[_vm._v("Cancel")]):_vm._e(),(!_vm.editing)?_c('a',{staticClass:"pointer",on:{"click":function($event){return _vm.edit(_vm.group)}}},[_vm._v("Edit | ")]):_vm._e(),(!_vm.editing)?_c('a',{staticClass:"pointer",on:{"click":function($event){return _vm.destroy(_vm.group.id)}}},[_vm._v("Delete")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }