<template>
    <tr :id="'ba_pp_' + bapp.id">
        <td>{{ index + 1 }}</td>
        <td>{{ bapp.production_process.name }}</td>
        <td>{{ bapp.business_activity.name }}</td>
        <td>{{ bapp.extra_data != null ? bapp.extra_data.name : '-' }}</td>
        <td>
            <span class="pull-right">
                <a href="javascript:void(0)" @click="destroy(bapp.id)">Delete</a>
            </span>
        </td>
    </tr>
</template>

<script>
import axios from 'axios';

export default {
    props: ['bapp', 'index'],
    methods: {
        destroy(id) {
            if (!window.confirm('Delete?')) {
                return false;
            }
            axios
                .delete('/api/superadmin/business-activity/production-process/' + id + '/destroy')
                .then(res => {
                    this.$toastr.i(res.data.message);
                    this.$emit('removeBaProdProcess', this.index);
                })
                .catch(error => console.error(error));
        }
    }
};
</script>
