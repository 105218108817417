<template>
    <tr :id="'extra_data_' + extra_data.id">
        <td>{{ index + 1 }}</td>
        <td v-if="!editing">{{ extra_data.name }}</td>
        <td v-else>
            <input
                id="name"
                v-model="form.name"
                v-validate="'required'"
                type="text"
                data-vv-name="name"
                data-vv-as="Name"
                :class="{ 'form-control': true, 'is-invalid': errors.has('name') }"
            />
            <span v-show="errors.has('name')" class="invalid-feedback" role="alert">
                <strong>{{ errors.first('name') }}</strong>
            </span>
        </td>
        <td>
            <toggle-button
                :value="extra_data.always_on == 1 ? true : false"
                color="#5ec58c"
                :labels="{ checked: 'yes', unchecked: 'no' }"
                @change="toggleAlwaysOn(extra_data.id)"
            />
        </td>
        <td>
            <div class="pull-right">
                <a v-if="!editing" href="javascript:void(0)" @click="edit(extra_data)">Edit</a>
                <a v-else href="javascript:void(0)" @click="cancelEdit(extra_data)">Cancel</a>
                |
                <a v-if="!editing" href="javascript:void(0)" @click="destroy(extra_data.id)">Delete</a>
                <a v-else href="javascript:void(0)" @click="update(extra_data.id)">Update</a>
            </div>
        </td>
    </tr>
</template>

<script>
import axios from 'axios';

export default {
    props: ['extra_data', 'index'],
    data() {
        return {
            editing: false,
            form: {},
            original: {}
        };
    },
    methods: {
        edit(extra_data) {
            this.form = extra_data;
            this.original = Object.assign({}, extra_data);
            this.editing = true;
        },
        cancelEdit(extra_data) {
            Object.assign(extra_data, this.original);
            this.form = this.original = null;
            this.editing = false;
        },
        update(id) {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    axios.put('/api/superadmin/extra-data/update/' + id, this.form).then(res => {
                        this.$toastr.i(res.data.message);
                        this.editing = false;
                    });
                }
            });
        },
        destroy(id) {
            if (!window.confirm('Delete?')) {
                return false;
            }
            axios
                .delete('/api/superadmin/extra-data/destroy/' + id)
                .then(res => {
                    this.$toastr.i(res.data.message);
                    this.$emit('removeExtraData', this.index);
                })
                .catch(error => {
                    this.$toastr.e(error.response.data.message);
                });
        },
        toggleAlwaysOn(id) {
            axios
                .put('/api/superadmin/extra-data/toggle-always-on/' + id, {})
                .then(res => {
                    this.$toastr.i(res.data.message);
                })
                .catch(error => console.error(error));
        }
    }
};
</script>
