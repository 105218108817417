<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <h4>Edit Table for Conditions Structure</h4>
                        <div class="form-group row mb-1">
                            <label class="col-sm-2 col-form-label">Countries</label>
                            <div class="col-sm-10">
                                <app-multiselect
                                    v-model="selected_countries"
                                    :options="countries"
                                    :multiple="true"
                                    group-values="countries"
                                    group-label="continent"
                                    :group-select="true"
                                    placeholder="Type to search"
                                    track-by="name"
                                    label="name"
                                >
                                    <template slot="beforeList">
                                        <li @click="selectAllCountries">
                                            <span
                                                class="multiselect__option"
                                                @mouseover="$event.target.classList.add('multiselect__option--highlight')"
                                                @mouseleave="$event.target.classList.remove('multiselect__option--highlight')"
                                            >
                                                Select all
                                            </span>
                                        </li>
                                        <li @click="deselectAllCountries">
                                            <span
                                                class="multiselect__option"
                                                @mouseover="$event.target.classList.add('multiselect__option--highlight')"
                                                @mouseleave="$event.target.classList.remove('multiselect__option--highlight')"
                                            >
                                                Deselect all
                                            </span>
                                        </li>
                                    </template>
                                    <template slot="selection" slot-scope="{ values, isOpen }">
                                        <span v-if="values.length && !isOpen" class="multiselect__single">
                                            {{ values.length }} countries selected
                                        </span>
                                    </template>
                                    <span slot="noResult">Nothing found</span>
                                </app-multiselect>
                            </div>
                        </div>
                        <div class="form-group row mb-1">
                            <label class="col-sm-2 col-form-label">Group</label>
                            <div class="col-sm-10">
                                <v-select
                                    v-model="selected_group"
                                    :options="groups"
                                    label="static_name"
                                    index="id"
                                    placeholder="- choose -"
                                    @input="selectGroup"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- Standard types -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-2 col-form-label">Standard type</label>
                            <div class="col-sm-10">
                                <v-select
                                    v-model="standards"
                                    v-validate="'required'"
                                    :close-on-select="false"
                                    multiple
                                    :options="standardOptions"
                                    label="name"
                                    placeholder="- choose -"
                                    data-vv-name="standard_options"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                                <span v-show="errors.has('standard_options')" class="text-danger" role="alert">
                                    <strong>{{ errors.first('standard_options') }}</strong>
                                </span>
                            </div>
                        </div>
                        <!-- table type -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-2 col-form-label">Table type</label>
                            <div class="col-sm-10">
                                <select v-model="table_type" class="form-control">
                                    <option value="1">Food Safety Plan</option>
                                </select>
                            </div>
                        </div>
                        <!-- production process -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-2 col-form-label">Production Processes</label>
                            <div class="col-sm-10">
                                <v-select
                                    v-model="selected_production_processes"
                                    :close-on-select="false"
                                    :options="production_processes"
                                    label="name"
                                    multiple
                                    placeholder="- choose -"
                                    @input="productionProcessChange"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <!-- production process subprocesses -->
                        <div class="form-group row mb-1">
                            <label class="col-sm-2 col-form-label">Production Sub Processes</label>
                            <div class="col-sm-10">
                                <v-select
                                    v-model="selected_production_subprocesses"
                                    :close-on-select="false"
                                    :options="production_sub_processes"
                                    label="name"
                                    multiple
                                    placeholder="- choose -"
                                >
                                    <span slot="no-options">Nothing found</span>
                                </v-select>
                            </div>
                        </div>
                        <div class="form-group row mb-1">
                            <label class="col-sm-2 col-form-label">Static Name</label>
                            <div class="col-sm-10">
                                <input
                                    v-model="static_name"
                                    v-validate="'required'"
                                    type="text"
                                    data-vv-name="static_name"
                                    data-vv-as="static name"
                                    :class="{ 'form-control': true, 'is-invalid': errors.has('static_name') }"
                                />
                                <span v-show="errors.has('static_name')" class="invalid-feedback" role="alert">
                                    <strong>{{ errors.first('static_name') }}</strong>
                                </span>
                            </div>
                        </div>
                        <div v-for="lang in langs" class="form-group row mb-1">
                            <label class="col-sm-2 col-form-label">{{ lang.name }}</label>
                            <div class="col-sm-10">
                                <div v-if="lang.code == 'en'">
                                    <input
                                        v-model="name[lang.code]"
                                        v-validate="'required'"
                                        type="text"
                                        data-vv-name="table_en"
                                        data-vv-as="English table name"
                                        :class="{ 'form-control': true, 'is-invalid': errors.has('table_en') }"
                                    />
                                    <span v-show="errors.has('table_en')" class="invalid-feedback" role="alert">
                                        <strong>{{ errors.first('table_en') }}</strong>
                                    </span>
                                </div>
                                <div v-else>
                                    <input v-model="name[lang.code]" type="text" class="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button v-if="isEdit" type="button" class="btn btn-success" @click="update">Update</button>
                <button v-if="isCreate" type="button" class="btn btn-success" @click="update(false)">
                    Save
                </button>
                <button v-if="isCreate" type="button" class="btn btn-success" @click="update(true)">
                    Save and Add New Table
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import appMultiselect from 'vue-multiselect';
import axios from 'axios';

export default {
    components: {
        appMultiselect
    },
    data() {
        return {
            form: {},
            name: {},
            table: {},
            langs: [],
            countries: [],
            selected_countries: [],
            groups: [],
            selected_group: null,
            static_name: '',
            table_type: null,
            production_processes: [],
            production_sub_processes: [],
            selected_production_processes: [],
            selected_production_subprocesses: [],
            applicationType: null,
            standards: [],
            standardOptions: [],
        };
    },
    computed: {
        isCreate() {
            return this.applicationType === 'create';
        },
        isEdit() {
            return this.applicationType === 'edit';
        },
    },
    async created() {
        await this.fetchStructureGroups();
        this.getApplicationType();
        this.getTable();
        this.getLanguages();
        this.getProductionProcesses();
        this.getProductionSubProcesses();
        this.getProductionProcesses();
    },
    methods: {
        selectGroup() {
            this.standards = [];
            const selectedGroup = this.groups.filter(g => g.id === this.selected_group)[0];
            this.standardOptions = selectedGroup.standards.map(s => s.standard);
        },
        getAllCountries() {
            let countries = [];
            this.countries.map(continent => {
                continent.countries.map(country => {
                    countries.push(country);
                });
            });
            return countries;
        },
        selectAllCountries() {
            this.selected_countries = this.getAllCountries();
        },
        deselectAllCountries() {
            this.selected_countries = [];
        },
        productionProcessChange(items) {
            let processes = [];
            items.forEach(process => {
                processes.push(process.id);
            });
            if (processes.length > 0) {
                this.getProductionSubProcesses(processes);
            }
        },
        update(newTable) {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    let lengthOfAllCountries = 0;
                    this.countries.forEach(continent => {
                        lengthOfAllCountries += continent && continent.countries ? continent.countries.length : 0;
                    });
                    let translations = [];
                    if(this.name){
                        Object.keys(this.name).forEach(key => {
                            if(this.name[key]){
                                translations.push({
                                    language: key,
                                    translation_text: this.name[key]
                                });
                            }
                        });
                    }
                    this.form = {
                        countries: this.countries.length !== this.selected_countries.length ? this.selected_countries : [],
                        section_translations: translations,
                        type: this.table_type,
                        static_name: this.static_name,
                        parent_fsp_section_id: this.selected_group,
                        fsp_document_type_id: parseInt(this.table_type, 0),
                        production_processes: this.selected_production_processes,
                        production_subprocesses: this.selected_production_subprocesses,
                        standards: this.standards,
                    };
                    if(this.isEdit){
                        axios.put('/api/superadmin/fsp-tables/' +
                                this.$route.params.fsp_cond_structure_id,
                        this.form
                        )
                            .then(() => {
                                this.$toastr.i('Edited table!');
                                this.$router.push({ name: 'fsp_cond.structure' });
                            })
                            .catch(error => console.error(error));
                    } else {
                        axios.post('/api/superadmin/fsp-tables/', this.form)
                            .then(() => {
                                this.$toastr.i('Table added!');
                                if (newTable) {
                                    this.name = {};
                                    this.static_name = '';
                                } else {
                                    this.$router.push({ name: 'fsp_cond.structure' });
                                }
                            })
                            .catch(error => console.error(error));
                    }

                }
            });
        },
        getLanguages() {
            axios
                .get('/api/superadmin/food-safety-plan-conditions/languages')
                .then(res => {
                    this.langs = res.data;
                })
                .catch(error => console.error(error));
        },
        getTable() {
            if(this.isEdit){
                axios
                    .get(
                        '/api/superadmin/fsp-tables/' + this.$route.params.fsp_cond_structure_id
                    )
                    .then(res => {
                        this.table = res.data;
                        this.table_type = res.data.fsp_document_type_id;
                        let translations = {};
                        res.data.section_translations.forEach(trans => {
                            translations = { [trans.language]: trans.translation_text, ...translations};
                        });
                        this.name = translations;
                        this.static_name = res.data.static_name;
                        this.selected_group = res.data.parent_fsp_section_id;
                        this.selected_countries = res.data.countries;
                        this.selected_production_processes = res.data.production_processes;
                        this.selected_production_subprocesses = res.data.production_subprocesses;
                        this.selectGroup();
                        this.standards = res.data.standards;
                    })
                    .then(() => {
                        this.getCountries();
                        this.productionProcessChange(this.selected_production_processes);
                    })
                    .catch(error => console.error(error));
            } else {
                this.getCountries();
            }
        },
        getCountries() {
            axios
                .get('/api/classifiers/countries?simple-format=true')
                .then(res => {
                    this.countries = res.data;
                    let condition_countries = this.table.countries;
                })
                .catch(error => console.error(error));
        },
        fetchStructureGroups() {
            axios
                .get('/api/superadmin/fsp-table-groups')
                .then(res => {
                    this.groups = res.data.data;
                })
                .catch(error => console.error(error));
        },
        getProductionProcesses() {
            axios
                .get('/api/classifiers/production-processes')
                .then(res => {
                    this.production_processes = res.data;
                })
                .catch(error => {
                    this.load_error = true;
                    console.error(error);
                });
        },
        getProductionSubProcesses(processes) {
            let pps;
            if (typeof processes === 'string' || processes instanceof String) {
                pps = JSON.parse(processes);
            } else {
                pps = processes;
            }
            axios
                .get(`/api/classifiers/production-sub-processes/${pps}`)
                .then(res => {
                    this.production_sub_processes = res.data;
                })
                .catch(error => console.error(error));
        },
        getApplicationType() {
            if(this.$route.name === 'fsp_cond.structure.create'){
                this.applicationType = 'create';
            } else if(this.$route.name === 'fsp_cond.structure.edit') {
                this.applicationType = 'edit';
            }
        },
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
