<template>
    <div>
        <div class="row page-titles hidden-sm-up">
            <div class="col-md-5 align-self-center">
                <h4 class="text-themecolor">New Business Activity Product Group</h4>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title hidden-xs-down">New Business Activity Product Group</h4>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Business Activity *</label>
                            <v-select
                                v-model="form.selected_business_activity"
                                :options="business_activites"
                                label="name"
                                :on-change="businessActivityChange"
                                :class="{ 'custom-is-invalid': business_activity_has_error }"
                                placeholder="- choose -"
                            >
                                <span slot="no-options">Nothing found</span>
                            </v-select>
                            <input type="hidden" class="form-control is-invalid" />
                            <span v-if="business_activity_has_error" class="invalid-feedback" role="alert">
                                <strong>Business Activity required</strong>
                            </span>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Product Groups *</label>
                            <v-select
                                v-model="form.selected_groups"
                                :options="groups"
                                :close-on-select="false"
                                label="name"
                                :on-change="groupChange"
                                :class="{ 'custom-is-invalid': group_has_error }"
                                placeholder="- choose -"
                                multiple
                            >
                                <span slot="no-options">Nothing found</span>
                            </v-select>
                            <input type="hidden" class="form-control is-invalid" />
                            <span v-if="group_has_error" class="invalid-feedback" role="alert">
                                <strong>Product Groups required</strong>
                            </span>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Extra data</label>
                            <v-select
                                v-model="form.selected_extra_datas"
                                :options="extra_data"
                                label="name"
                                :on-change="extraDataChange"
                                placeholder="- choose -"
                            >
                                <span slot="no-options">Nothing found</span>
                            </v-select>
                        </div>
                    </div>
                </div>
                <button type="button" class="btn btn-success" @click="store">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            groups: [],
            extra_data: [],
            business_activites: [],
            business_activity_has_error: true,
            group_has_error: true,
            form: {
                groups: [],
                extra_data: []
            }
        };
    },
    created() {
        this.fetchProductGroups();
        this.fetchExtraData();
        this.fetchBusinessActivites();
    },
    methods: {
        store() {
            axios
                .post('/api/superadmin/business-activity/product-group/create', this.form)
                .then(res => {
                    this.$toastr.i(res.data.message);
                    this.$router.push({ name: 'smartfilter.ba.pg' });
                })
                .catch(error => console.error(error));
        },
        fetchProductGroups() {
            axios
                .get('/api/classifiers/product-groups')
                .then(res => {
                    this.groups = res.data;
                })
                .catch(error => console.error(error));
        },
        fetchExtraData() {
            axios
                .get('/api/classifiers/extra-data')
                .then(res => {
                    this.extra_data = res.data;
                })
                .catch(error => console.error(error));
        },
        fetchBusinessActivites() {
            axios
                .get('/api/classifiers/business-activities')
                .then(res => {
                    this.business_activites = res.data;
                })
                .catch(error => console.error(error));
        },
        groupChange(obj) {
            this.group_has_error = false;
            if (obj == null) {
                this.group_has_error = true;
                this.form.groups = null;
                return null;
            }
            let fg = [];
            obj.forEach(foodGroups => {
                fg.push(foodGroups.id);
            });
            return (this.form.groups = fg);
        },
        extraDataChange(obj) {
            return (this.form.extra_data = obj.id);
        },
        businessActivityChange(obj) {
            this.business_activity_has_error = false;
            if (obj == null) {
                this.business_activity_has_error = true;
                this.form.business_activity = null;
                return null;
            }
            return (this.form.business_activity = obj.id);
        }
    }
};
</script>
