<template>
    <div class="row">
        <div class="col">
            <h6>Search</h6>
            <input
                v-model="search.search"
                class="form-control"
                @change="select"
            />
        </div>
        <div class="col">
            <h6>Entities per pages</h6>
            <v-select
                v-model="search.perPage"
                :options="perPageOptions"
                placeholder="- choose -"
                @input="select"
            >
            </v-select>
        </div>
        <div class="col">
            <h6>Navigate</h6>
            <b-pagination
                v-model="search.page"
                :total-rows="totalRows || 0"
                :per-page="search.perPage"
                first-number
                last-number
                @change="select"
            ></b-pagination>
        </div>
        <div v-for="(_, name) in $slots" :key="'slot_' + name" class="col">
            <h6 v-if="name && name[0] !== 'x'">{{ name }}</h6>
            <slot :name="name">
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PaginationPanel',
    props: {
        totalRows: {
            type: Number,
            default: 0,
        },
        searchTerm: {
            type: String,
            default: 'search',
        },
        perPage: {
            type: Number,
            default: 25,
        },
    },
    data() {
        return {
            search: {
                search: '',
                page: 1,
                perPage: this.perPage,
                changeCurrentPageNumber: null,
            }
        };
    },
    computed: {
        perPageOptions() {
            return [5, 10, 25, 50, 75, 100, 150, 200, this.totalRows || 1000];
        },
    },
    created() {
        this.select();
    },
    methods: {
        select() {
            if(this.searchTerm !== 'search'){
                this.$emit('search', {
                    page: this.search.page,
                    perPage: this.search.perPage,
                    changeCurrentPageNumber: this.search.changeCurrentPageNumber,
                    [this.searchTerm]: this.search.search,
                });
                return;
            }
            this.$emit('search', this.search);
        },
    },
};
</script>
