<template>
    <b-modal id="user-logins-modal" title="User Logins" :hide-footer="!rows">
        <b-table
            v-if="rows"
            id="user-logins-table"
            hover
            :items="items"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
        ></b-table>
        <h4 v-else>Not user logins information yet</h4>

        <template slot="modal-footer">
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="user-logins-table"
            ></b-pagination>
        </template>
    </b-modal>
</template>

<script>
export default {
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            items: [],
            fields: ['login_time']
        };
    },
    computed: {
        rows() {
            return this.items.length;
        }
    },
    methods: {
        open(logins) {
            this.items = logins;
            this.currentPage = 1;
            this.$bvModal.show('user-logins-modal');
        }
    }
};
</script>
