const state = {
    access_token: '',
    expires_in: 0
};

const mutations = {
    SET_ACCESS_TOKEN(state, res) {
        state.access_token = res.access_token;
        state.expires_in = res.expires_in;
        localStorage.setItem('access_token', res.access_token);
        localStorage.setItem('expires_in', res.expires_in);
    },
    removeToken(state) {
        state.access_token = '';
        state.expires_in = 0;
    },
};

const actions = {
    resetToken({ state }) {
        state.access_token = localStorage.getItem('access_token');
        state.expires_in = localStorage.getItem('expires_in');
    }
};

const getters = {
    getAccessToken: state => {
        return state.access_token;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
