<template>
    <div class="page-wrapper">
        <div class="container-fluid">
            <div class="card mt-3">
                <ul class="nav nav-tabs profile-tab" role="tablist">
                    <li class="nav-item">
                        <router-link :to="{ name: 'companies' }" tag="a" class="nav-link" exact>
                            <span class="hidden-sm-up">
                                <i class="fa fa-building-o"></i>
                            </span>
                            <span class="hidden-xs-down">
                                Companies
                            </span>
                        </router-link>
                    </li>
                    <li v-if="userRole == 'admin' || userRole == 'superadmin'" class="nav-item">
                        <router-link :to="{ name: 'place.groups' }" tag="a" class="nav-link" exact>
                            <span class="hidden-sm-up">
                                <i class="icon-note"></i>
                            </span>
                            <span class="hidden-xs-down">
                                Groups for places
                            </span>
                        </router-link>
                    </li>
                </ul>
                <router-view />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        userRole() {
            return this.$store.getters.getUserRole;
        }
    }
};
</script>
