<template>
    <div class="card">
        <app-loader v-if="loading" line-fg-color="#5ec58c" message="Loading..." size="small" />
        <div v-else class="card-body">
            <h4 class="card-title hidden-xs-down">{{ `${selectedSubscriptionId ? 'Edit' : 'New'} Subscription ${selectedSubscriptionId || ''}` }}</h4>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Started at</label>
                        <datepicker
                            v-model="form.started_at"
                            :monday-first="true"
                            input-class="form-control bg-white"
                            format="dd.MM.yyyy"
                        >
                        </datepicker>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Renewal started at</label>
                        <datepicker
                            v-model="form.renewal_started_at"
                            :monday-first="true"
                            input-class="form-control bg-white"
                            format="dd.MM.yyyy"
                        >
                        </datepicker>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Renewal valid to</label>
                        <datepicker
                            v-model="form.renewal_valid_to"
                            :monday-first="true"
                            input-class="form-control bg-white"
                            format="dd.MM.yyyy"
                        >
                        </datepicker>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col m-auto">
                    <b-form-checkbox v-model="isTrial">Trial</b-form-checkbox>
                </div>
                <div v-if="!isTrial" class="col">
                    <label>Renewal period</label>
                    <v-select v-model="form.renewalPeriod" index="operation_id" :options="periods" label="name" placeholder="- choose -">
                        <span slot="no-options">Nothing found</span>
                    </v-select>
                </div>
            </div>
            <template v-if="!isTrial">
                <div v-for="place in places" :key="place.id" class="row mb-3">
                    <div class="col-6">
                        <label>Place name</label>
                        <div>
                            <b>{{ place.name }}</b>
                        </div>
                    </div>
                    <div class="col-6">
                        <label>Price package</label>
                        <v-select v-model="place.package" :options="pricePackages" index="id" label="name" placeholder="- choose -">
                            <span slot="no-options">Nothing found</span>
                        </v-select>
                    </div>
                </div>
            </template>
            <div v-if="!dateValid" class="row mt-2 mb-2">
                Dates do not match! Started at <= Renewal started at < Renewal Valid to
            </div>
            <button :disabled="!dateValid" type="button" class="btn btn-success" @click="store">{{ selectedSubscriptionId ? 'Edit' : 'Save' }}</button>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

const TRIAL_PACKAGE = 1;
const MONTHS = 6;
const DEFAULT_NUMBER_OF_MONTHS = 1;
const NUMBER_OF_TRIAL_DAYS = 14;
const DAYS = 4;

import moment from 'moment';
import axios from 'axios';

export default {
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if(to.params && to.params.subscription_id){
                vm.selectedSubscriptionId = to.params.subscription_id;
            }
            if(to.params && to.params.subscription){
                vm.form = to.params.subscription;
                vm.places = to.params.subscription.price_packages.map(pp => {
                    return {...pp.place, package: pp.price_package.id};
                });
                if(vm.places.length === 1 && !vm.places[0].id){
                    vm.places = [];
                    vm.isTrial = true;
                }
                vm.form.renewalPeriod = `${vm.form.renewal_time_unit_cnt}_${vm.form.renewal_time_unit_id}`;
                try {
                    vm.form.started_at = moment(vm.form.started_at, 'YYYY-MM-DD').toDate();
                    vm.form.renewal_started_at = moment(vm.form.renewal_started_at, 'YYYY-MM-DD').toDate();
                    vm.form.renewal_valid_to = moment(vm.form.renewal_valid_to, 'YYYY-MM-DD').toDate();
                } catch {
                    vm.form.started_at = null;
                    vm.form.renewal_started_at = null;
                    vm.form.renewal_valid_to = null;
                }

            }
            vm.getPricePackages();
            vm.getCompanyPlaces();
            vm.getCompanyPeriods();
        });
    },
    data() {
        return {
            pricePackages: [],
            selectedSubscriptionId: null,
            places: [],
            periods: [],
            isTrial: false,
            loading: true,
            systemLastPaidAmt: null, //Numeric
            form: {
                started_at: null, //Date
                renewal_started_at: null, //Date
                renewal_valid_to: null, //Date
                renewal_time_unit_cnt: null, //Numeric
                renewal_time_unit_id: null, //Numeric
                last_paid_amt: null, //Numeric
                price_packages: [], //Object
                renewalPeriod: `${DEFAULT_NUMBER_OF_MONTHS}_${MONTHS}`, //String
            },
        };
    },
    computed: {
        ...mapGetters(['isAdmin']),
        dateValid() {
            return  this.form.started_at <= this.form.renewal_started_at && this.form.renewal_started_at < this.form.renewal_valid_to;
        }
    },
    watch: {
        isTrial: function(val) {
            if(!val){
                this.form.renewalPeriod = `${DEFAULT_NUMBER_OF_MONTHS}_${MONTHS}`;
                this.form.renewal_time_unit_cnt = DEFAULT_NUMBER_OF_MONTHS;
                this.form.renewal_time_unit_id = MONTHS;
            }
        }
    },
    methods: {
        store() {
            this.loading = true;
            let form = {...this.form};
            if(this.isTrial){
                form.price_packages = [{price_package: {id: TRIAL_PACKAGE}}];
            } else {
                form.price_packages = [];
                this.places.forEach(place => {
                    if(place.package){
                        form.price_packages.push({price_package: {id: place.package}, place: {id: place.id}});
                    }
                });
            }
            const renewalPeriods = this.form.renewalPeriod.split('_');
            form.renewal_time_unit_cnt = this.isTrial ? NUMBER_OF_TRIAL_DAYS : parseInt(renewalPeriods[0], 0);
            form.renewal_time_unit_id = this.isTrial ? DAYS : parseInt(renewalPeriods[1], 0);
            form.started_at = this.dateTOAPIString(form.started_at);
            form.renewal_started_at = this.dateTOAPIString(form.renewal_started_at);
            form.renewal_valid_to = this.dateTOAPIString(form.renewal_valid_to);
            this.form.last_paid_amt = parseInt(form.last_paid_amt);
            if(this.selectedSubscriptionId){
                axios.put(`/api/superadmin/companies/${this.$route.params.company_id}/subscriptions/${this.selectedSubscriptionId}`, form)
                    .then(() => {
                        this.$router.push({name: 'company.subscription'});
                    })
                    .catch(error => this.$toastr.e(error.response.data));
            } else {
                axios.post(`/api/superadmin/companies/${this.$route.params.company_id}/subscriptions`, form)
                    .then(() => {
                        this.$router.push({name: 'company.subscription'});
                    })
                    .catch(error => this.$toastr.e(error.response.data));
            }
            this.loading = true;
        },
        getPricePackages() {
            axios.get('/api/classifiers/price-packages')
                .then(({data}) => this.pricePackages = data)
                .catch(error => console.error(error));
        },
        addCompanyPlaces(data) {
            const places = [];
            data.places.forEach(place => {
                if(!this.places.some(p => p.id === place.id)){
                    places.push(place);
                }
            });
            this.places = [...this.places, ...places];

        },
        getCompanyPlaces() {
            axios.get(`/api/superadmin/companies/${this.$route.params.company_id}`)
                .then(({data}) => this.addCompanyPlaces(data))
                .catch(error => console.error(error));
        },
        getCompanyPeriods() {
            axios.get(`/api/superadmin/companies/${this.$route.params.company_id}/subscriptions/periods`)
                .then(({data}) => this.periods = data.map(d => ({...d, operation_id: `${d.time_unit_cnt}_${d.time_unit_id}`})))
                .catch(error => console.error(error))
                .finally(() => {
                    this.loading = false;
                });
        },
        dateTOAPIString(date) {
            try {
                const timeZoneOffset = (new Date()).getTimezoneOffset() * 60000;
                const splitedDate = (new Date(date - timeZoneOffset)).toISOString().split('T');
                return `${splitedDate[0]} ${splitedDate[1].substr(0, 8)}`;
            } catch {
                return date;
            }

        },
    },
};
</script>
