import Vue from 'vue';
import moment from 'moment';

let formatDate = Vue.filter('formatDate', function(date) {
    if (date !== null) {
        return moment(String(date)).format('DD.MM.YYYY');
    }
    return null;
});

let formatDateTime = Vue.filter('formatDateTime', function(date) {
    if (date !== null) {
        return moment(String(date)).format('DD.MM.YYYY HH:mm');
    }

    return date;
});

let formatServerDateTime = Vue.filter('formatServerDateTime', function(date) {
    if (date !== null) {
        let date = moment(date);

        if (!date.isValid()) {
            date = moment(String(date), 'DD.MM.YYYY');
        }

        return date.format('YYYY-MM-DD HH:mm:ss');
    }
    return null;
});

let truncate = Vue.filter('truncate', function(value, length) {
    length = length || 15;
    if (!value || typeof value !== 'string') {
        return '';
    }
    if (value.length <= length) {
        return value;
    }
    return value.substring(0, length) + '...';
});

let round = Vue.filter('round', function(value, decimals) {
    if (value > 0 && value !== null) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }
    return 0;
});

let toUpper = Vue.filter('toUpper', function(value) {
    return String(value).toUpperCase();
});

let striphtml = Vue.filter('striphtml', function(value) {
    let div = document.createElement('div');
    div.innerHTML = value;
    let text = div.textContent || div.innerText || '';
    return text;
});

export const filters = {
    formatDate,
    formatDateTime,
    formatServerDateTime,
    truncate,
    round,
    toUpper,
    striphtml
};
