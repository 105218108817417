<template>
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <div class="row page-titles hidden-sm-up">
                <div class="col-md-5 align-self-center">
                    <h4 class="text-themecolor">New Ingredient</h4>
                </div>
            </div>
            <div class="card-body">
                <h4 class="card-title hidden-xs-down">New Ingredient</h4>
                <ul class="nav nav-tabs">
                    <li v-for="(lang, index) in langs" class="nav-item">
                        <a
                            class="nav-link"
                            :class="{ active: lang.code == 'et' }"
                            data-toggle="tab"
                            :href="'#' + lang.code"
                            :key="index"
                        >{{ lang.name }}</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div
                        v-for="(lang, index) in langs"
                        :id="lang.code"
                        class="tab-pane"
                        :class="{ active: lang.code == 'et' }"
                        :key="index"
                    >
                        <div class="row mt-2">
                            <div class="col">
                                <div class="form-group">
                                    <label>
                                        Name
                                        <span v-if="lang.code == 'et' || lang.code == 'en'">*</span>
                                    </label>
                                    <input
                                        v-model="name[lang.code]"
                                        v-validate="lang.code == 'et' || lang.code == 'en' ? 'required' : ''"
                                        type="text"
                                        :data-vv-name="'name_' + lang.code"
                                        :data-vv-as="'Name in ' + lang.code"
                                        :class="{
                                            'form-control': true,
                                            'is-invalid': errors.has('name_' + lang.code)
                                        }"
                                    />
                                    <span
                                        v-show="errors.has('name_' + lang.code)"
                                        class="invalid-feedback"
                                        role="alert"
                                    >
                                        <strong>{{ errors.first('name_' + lang.code) }}</strong>
                                    </span>
                                </div>
                            </div>
                            <div class="col">
                                <label>
                                    Alt Names
                                    <span v-if="lang.code == 'et' || lang.code == 'en'">*</span>
                                </label>
                                <input
                                    v-model="alt_names[lang.code]"
                                    v-validate="lang.code == 'et' || lang.code == 'en' ? 'required' : ''"
                                    type="text"
                                    :data-vv-name="'alt_names_' + lang.code"
                                    :data-vv-as="'Alt Name in ' + lang.code"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': errors.has('alt_names_' + lang.code)
                                    }"
                                />
                                <span
                                    v-show="errors.has('alt_names_' + lang.code)"
                                    class="invalid-feedback"
                                    role="alert"
                                >
                                    <strong>{{ errors.first('alt_names_' + lang.code) }}</strong>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Food Group *</label>
                        <input
                            v-model="form.food_group"
                            v-validate="'required'"
                            type="text"
                            :class="{
                                'form-control form-control-sm': true,
                                'is-invalid': errors.has('food_group')
                            }"
                            data-vv-as="Food Group"
                            data-vv-name="food_group"
                        />
                        <span v-show="errors.has('food_group')" class="invalid-feedback" role="alert">
                            <strong>{{ errors.first('food_group') }}</strong>
                        </span>
                    </div>
                    <div class="col-1">
                        <label>Code *</label>
                        <input
                            v-model="form.code"
                            v-validate="'required'"
                            type="text"
                            :class="{
                                'form-control form-control-sm': true,
                                'is-invalid': errors.has('code')
                            }"
                            data-vv-as="Code"
                            data-vv-name="code"
                        />
                        <span v-show="errors.has('code')" class="invalid-feedback" role="alert">
                            <strong>{{ errors.first('code') }}</strong>
                        </span>
                    </div>
                    <div class="col-1">
                        <label>Is allergen</label><br />
                        <toggle-button
                            v-model="form.is_allergen"
                            color="#5ec58c"
                            :labels="{ checked: 'yes', unchecked: 'no' }"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>kJ *</label>
                        <input
                            v-model="form.energy_kj"
                            v-validate="'required'"
                            type="text"
                            :class="{
                                'form-control form-control-sm': true,
                                'is-invalid': errors.has('energy_kj')
                            }"
                            data-vv-as="kJ"
                            data-vv-name="energy_kj"
                        />
                        <span v-show="errors.has('energy_kj')" class="invalid-feedback" role="alert">
                            <strong>{{ errors.first('energy_kj') }}</strong>
                        </span>
                    </div>
                    <div class="col">
                        <label>Kcal *</label>
                        <input
                            v-model="form.energy_kcal"
                            v-validate="'required'"
                            type="text"
                            :class="{
                                'form-control form-control-sm': true,
                                'is-invalid': errors.has('energy_kcal')
                            }"
                            data-vv-as="Kcal"
                            data-vv-name="energy_kcal"
                        />
                        <span v-show="errors.has('energy_kcal')" class="invalid-feedback" role="alert">
                            <strong>{{ errors.first('energy_kcal') }}</strong>
                        </span>
                    </div>
                    <div class="col">
                        <label>Fat *</label>
                        <input
                            v-model="form.fat"
                            v-validate="'required'"
                            type="text"
                            :class="{
                                'form-control form-control-sm': true,
                                'is-invalid': errors.has('fat')
                            }"
                            data-vv-as="Fat"
                            data-vv-name="fat"
                        />
                        <span v-show="errors.has('fat')" class="invalid-feedback" role="alert">
                            <strong>{{ errors.first('fat') }}</strong>
                        </span>
                    </div>
                    <div class="col">
                        <label>Sat. fatty acids *</label>
                        <input
                            v-model="form.saturated_fatty_acids"
                            v-validate="'required'"
                            type="text"
                            :class="{
                                'form-control form-control-sm': true,
                                'is-invalid': errors.has('saturated_fatty_acids')
                            }"
                            data-vv-as="Sat. Fat"
                            data-vv-name="saturated_fatty_acids"
                        />
                        <span v-show="errors.has('saturated_fatty_acids')" class="invalid-feedback" role="alert">
                            <strong>{{ errors.first('saturated_fatty_acids') }}</strong>
                        </span>
                    </div>
                    <div class="col">
                        <label>Carbohydrates *</label>
                        <input
                            v-model="form.carbs"
                            v-validate="'required'"
                            type="text"
                            :class="{
                                'form-control form-control-sm': true,
                                'is-invalid': errors.has('carbs')
                            }"
                            data-vv-as="Carbs"
                            data-vv-name="carbs"
                        />
                        <span v-show="errors.has('carbs')" class="invalid-feedback" role="alert">
                            <strong>{{ errors.first('carbs') }}</strong>
                        </span>
                    </div>
                    <div class="col">
                        <label>Sugars *</label>
                        <input
                            v-model="form.sugars"
                            v-validate="'required'"
                            type="text"
                            :class="{
                                'form-control form-control-sm': true,
                                'is-invalid': errors.has('sugars')
                            }"
                            data-vv-as="Sugars"
                            data-vv-name="sugars"
                        />
                        <span v-show="errors.has('sugars')" class="invalid-feedback" role="alert">
                            <strong>{{ errors.first('sugars') }}</strong>
                        </span>
                    </div>
                    <div class="col">
                        <label>Protein *</label>
                        <input
                            v-model="form.protein"
                            v-validate="'required'"
                            type="text"
                            :class="{
                                'form-control form-control-sm': true,
                                'is-invalid': errors.has('protein')
                            }"
                            data-vv-as="Protein"
                            data-vv-name="protein"
                        />
                        <span v-show="errors.has('protein')" class="invalid-feedback" role="alert">
                            <strong>{{ errors.first('protein') }}</strong>
                        </span>
                    </div>
                    <div class="col">
                        <label>Salt *</label>
                        <input
                            v-model="form.salt"
                            v-validate="'required'"
                            type="text"
                            :class="{
                                'form-control form-control-sm': true,
                                'is-invalid': errors.has('salt')
                            }"
                            data-vv-as="Salt"
                            data-vv-name="salt"
                        />
                        <span v-show="errors.has('salt')" class="invalid-feedback" role="alert">
                            <strong>{{ errors.first('salt') }}</strong>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Alcohol</label>
                        <input v-model="form.alcohol" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Water</label>
                        <input v-model="form.water" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Ash</label>
                        <input v-model="form.ash" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Absobrable carbs</label>
                        <input v-model="form.carbs_absorbable" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Fiber</label>
                        <input v-model="form.fibers" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Starch</label>
                        <input v-model="form.starch" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Sucrose</label>
                        <input v-model="form.sucrose" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Lactose</label>
                        <input v-model="form.lactose" type="text" class="form-control form-control-sm" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Maltose</label>
                        <input v-model="form.maltose" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Glucose</label>
                        <input v-model="form.glucose" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Fructose</label>
                        <input v-model="form.fructose" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Galactose</label>
                        <input v-model="form.galactose" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Fatty acids</label>
                        <input v-model="form.fatty_acids" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Mono sat. acid</label>
                        <input
                            v-model="form.monounsaturated_fatty_acids"
                            type="text"
                            class="form-control form-control-sm"
                        />
                    </div>
                    <div class="col">
                        <label>Poly sat. acids</label>
                        <input
                            v-model="form.polyunsaturated_fatty_acids"
                            type="text"
                            class="form-control form-control-sm"
                        />
                    </div>
                    <div class="col">
                        <label>Trans fat acids</label>
                        <input v-model="form.trans_fatty_acids" type="text" class="form-control form-control-sm" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Palmic acid</label>
                        <input v-model="form.palmic_acid" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Stearic acid</label>
                        <input v-model="form.stearic_acid" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Linoleic acid</label>
                        <input v-model="form.linoleic_acid" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Linolenic acid</label>
                        <input v-model="form.linolenic_acid" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Cholestorol</label>
                        <input v-model="form.cholesterol" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Potassium</label>
                        <input v-model="form.potassium" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Calcium</label>
                        <input v-model="form.calcium" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Magnesium</label>
                        <input v-model="form.magnesium" type="text" class="form-control form-control-sm" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Phosphorus</label>
                        <input v-model="form.phosphorus" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Iron</label>
                        <input v-model="form.iron" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Zinc</label>
                        <input v-model="form.zinc" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Copper</label>
                        <input v-model="form.copper" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Manganese</label>
                        <input v-model="form.manganese" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Iodine</label>
                        <input v-model="form.iodine" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Selenium</label>
                        <input v-model="form.selenium" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Chrome</label>
                        <input v-model="form.chrome" type="text" class="form-control form-control-sm" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Nickel</label>
                        <input v-model="form.nickel" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Vitamin A</label>
                        <input v-model="form.vit_a" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Retinol</label>
                        <input v-model="form.retinol" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Beta carotene</label>
                        <input v-model="form.beta_carotene" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Vitamin D</label>
                        <input v-model="form.vit_d" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Vitamin D3</label>
                        <input v-model="form.vit_d3" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Vitamine E</label>
                        <input v-model="form.vit_e" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Vitamin K</label>
                        <input v-model="form.vit_k" type="text" class="form-control form-control-sm" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Vitamin B1</label>
                        <input v-model="form.vit_b1" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Vitamin B2</label>
                        <input v-model="form.vit_b2" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Niacin eq. total</label>
                        <input
                            v-model="form.niacin_equivalent_total"
                            type="text"
                            class="form-control form-control-sm"
                        />
                    </div>
                    <div class="col">
                        <label>Niacin</label>
                        <input v-model="form.niacin" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Niacin eq. tryptophan</label>
                        <input
                            v-model="form.niacin_equivalent_tryptophan"
                            type="text"
                            class="form-control form-control-sm"
                        />
                    </div>
                    <div class="col">
                        <label>Pantothenic acid</label>
                        <input v-model="form.pantothenic_acid" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Vitamine B6</label>
                        <input v-model="form.vit_b6" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Biotin</label>
                        <input v-model="form.biotin" type="text" class="form-control form-control-sm" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Folates</label>
                        <input v-model="form.folates" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Vitamin B12</label>
                        <input v-model="form.vit_b12" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Vitamin C</label>
                        <input v-model="form.vit_c" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Sodium</label>
                        <input v-model="form.sodium" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col">
                        <label>Caffeine</label>
                        <input v-model="form.caffeine" type="text" class="form-control form-control-sm" />
                    </div>
                </div>
                <hr />
                <button type="button" class="btn btn-success" @click="store">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            langs: [],
            name: {},
            alt_names: {},
            form: {
                is_allergen: false,
                energy_kj: 0,
                energy_kcal: 0,
                fat: 0,
                saturated_fatty_acids: 0,
                carbs: 0,
                sugars: 0,
                protein: 0,
                salt: 0,
                alcohol: 0,
                water: 0,
                ash: 0,
                carbs_absorbable: 0,
                fibers: 0,
                starch: 0,
                sucrose: 0,
                lactose: 0,
                maltose: 0,
                glucose: 0,
                fructose: 0,
                galactose: 0,
                fatty_acids: 0,
                monounsaturated_fatty_acids: 0,
                polyunsaturated_fatty_acids: 0,
                trans_fatty_acids: 0,
                palmic_acid: 0,
                stearic_acid: 0,
                linoleic_acid: 0,
                linolenic_acid: 0,
                cholesterol: 0,
                potassium: 0,
                calcium: 0,
                magnesium: 0,
                phosphorus: 0,
                iron: 0,
                zinc: 0,
                copper: 0,
                manganese: 0,
                iodine: 0,
                selenium: 0,
                chrome: 0,
                nickel: 0,
                vit_a: 0,
                retinol: 0,
                beta_carotene: 0,
                vit_d: 0,
                vit_d3: 0,
                vit_e: 0,
                vit_k: 0,
                vit_b1: 0,
                vit_b2: 0,
                niacin_equivalent_total: 0,
                niacin: 0,
                niacin_equivalent_tryptophan: 0,
                pantothenic_acid: 0,
                vit_b6: 0,
                biotin: 0,
                folates: 0,
                vit_b12: 0,
                vit_c: 0,
                sodium: 0,
                caffeine: 0
            }
        };
    },
    created() {
        this.getLanguages();
    },
    methods: {
        getLanguages() {
            axios
                .get('/api/superadmin/food-safety-plan-conditions/languages')
                .then(res => {
                    this.langs = res.data;
                })
                .catch(error => console.error(error));
        },
        store() {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    this.form.name = this.name;
                    this.form.alt_names = this.alt_names;
                    axios
                        .post('/api/superadmin/config/ingredient-datas/store', this.form)
                        .then(res => {
                            this.$toastr.i(res.data.message);
                            this.$router.push({ name: 'config.ingred.datas' });
                        })
                        .catch(error => console.error(error));
                }
            });
        }
    }
};
</script>
