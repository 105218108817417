<template>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title hidden-xs-down">New Production Process</h4>
            <div class="row">
                <div class="col-3">
                    <div class="form-group">
                        <label for="name">Name *</label>
                        <input
                            id="name"
                            v-model="form.name"
                            v-validate="'required'"
                            type="text"
                            data-vv-name="name"
                            data-vv-as="Name"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.has('name')
                            }"
                        />
                        <span v-show="errors.has('name')" class="invalid-feedback" role="alert">
                            <strong>{{ errors.first('name') }}</strong>
                        </span>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label for="tooltip">Tooltip</label>
                        <input id="tooltip" v-model="form.tooltip" type="text" class="form-control" />
                    </div>
                </div>
            </div>
            <button type="button" class="btn btn-success" @click="store">Save</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            form: {}
        };
    },
    methods: {
        store() {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    axios
                        .post('/api/superadmin/production-process/store', this.form)
                        .then(() => {
                            this.$toastr.i('New process added successfully!');
                            this.$router.push({ name: 'smartfilter.pp' });
                        })
                        .catch(error => console.error(error));
                }
            });
        }
    }
};
</script>
