<template>
    <div class="page-wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-9 col-xlg-9 col-md-8">
                    <div class="card">
                        <ul class="nav nav-tabs profile-tab" role="tablist">
                            <li class="nav-item">
                                <router-link
                                    :to="{
                                        name: 'company.invoices',
                                        params: { company_id: $route.params.company_id }
                                    }"
                                    tag="a"
                                    class="nav-link"
                                >
                                    <span class="hidden-sm-up">
                                        <i class="fa fa-file-pdf-o"></i>
                                    </span>
                                    <span class="hidden-xs-down">
                                        Invoices
                                    </span>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link
                                    :to="{
                                        name: 'company.places',
                                        params: { company_id: $route.params.company_id }
                                    }"
                                    tag="a"
                                    class="nav-link"
                                >
                                    <span class="hidden-sm-up">
                                        <i class="fa fa-home"></i>
                                    </span>
                                    <span class="hidden-xs-down">
                                        Places
                                    </span>
                                </router-link>
                            </li>
                            <li v-if="user_role == 'superadmin'" class="nav-item">
                                <router-link
                                    :to="{
                                        name: 'company.subscription',
                                        params: { company_id: $route.params.company_id }
                                    }"
                                    tag="a"
                                    class="nav-link"
                                >
                                    <span class="hidden-sm-up">
                                        <i class="fa fa-money"></i>
                                    </span>
                                    <span class="hidden-xs-down">
                                        Subscriptions
                                    </span>
                                </router-link>
                            </li>
                            <li v-if="user_role == 'superadmin'" class="nav-item">
                                <router-link
                                    :to="{
                                        name: 'company.products.import',
                                        params: { company_id: $route.params.company_id }
                                    }"
                                    tag="a"
                                    class="nav-link"
                                >
                                    <span class="hidden-sm-up">
                                        <i class="fas fa-upload"></i>
                                    </span>
                                    <span class="hidden-xs-down">
                                        Products Import
                                    </span>
                                </router-link>
                            </li>
                        </ul>
                        <router-view
                            :user_id="user_id"
                            :user_role="user_role"
                            :initial_company_status="company.status"
                        ></router-view>
                    </div>
                </div>

                <div class="col-lg-3 col-xlg-3 col-md-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="m-t-30 text-center">
                                <img
                                    src="/assets/img/fooddocs_logo_green_icon_40px.png"
                                    class="img-circle"
                                    width="150"
                                />
                                <h4 class="card-title m-t-10">{{ company.name }}</h4>
                            </div>
                            <div>
                                <hr />
                            </div>
                            <small class="p-t-30 db edit-title" @click="toggleEditBillingCompany">
                                Billing company
                                <i class="ml-1" :class="`fa fa-${editingBillingCompany ? 'close' : 'pencil'}`"></i>
                            </small>
                            <h5 v-if="company.billing_company && company.billing_company.name">
                                {{ company.billing_company.name }}
                                <small v-if="editingBillingCompany" class="text-danger edit-title ml-1" @click="removeBillingCompanyModal = true">remove</small>
                            </h5>
                            <template v-if="editingBillingCompany">
                                <input :value="companySearchQuery" class="form-control" type="text" @input="searchCompanies">
                                <ul>
                                    <li v-for="comp in companies">
                                        <a href="#" @click="selectBillingCompany(comp)">{{ comp.name }}</a>
                                    </li>
                                </ul>
                            </template>
                            <div>
                                <hr />
                            </div>
                            <small class="p-t-30 db">Pay Period</small>
                            <h5>{{ company.active_period }}</h5>
                            <div>
                                <hr />
                            </div>
                            <small class="text-muted">Email</small>
                            <h5>{{ company.email }}</h5>
                            <small class="text-muted p-t-30 db">Phone</small>
                            <h5>{{ company.phone }}</h5>
                            <small class="text-muted p-t-30 db">Address</small>
                            <h5>{{ company.address }}</h5>
                            <div class="text-center">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-danger"
                                    @click="showDeleteCompanyConfirmation"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ConfirmDeleteCompanyModal :company="company"></ConfirmDeleteCompanyModal>
        <ConfirmDelete v-model="removeBillingCompanyModal" @confirm="removeBillingCompany">
            <template #body>
                Are you sure you want to remove billing company?
            </template>
        </ConfirmDelete>
    </div>
</template>

<script>
import ConfirmDeleteCompanyModal from '../common/modals/ConfirmDeleteCompany';
import ConfirmDelete from '../modals/ConfirmDelete';
import axios from 'axios';

export default {
    components: {
        ConfirmDelete,
        ConfirmDeleteCompanyModal
    },
    data() {
        return {
            edit_credit_limit: false,
            credit_limit_form: {},
            edit_technolog: false,
            user_id: null,
            user_role: null,
            editingBillingCompany: false,
            companies: [],
            companySearchQuery: '',
            removeBillingCompanyModal: false,
        };
    },
    computed: {
        company() {
            return this.$store.getters.getCompany;
        },
    },
    created() {
        this.$store.dispatch('fetchCurrencies');
        this.getCompanyAction();
        this.getUserId();
    },
    methods: {
        removeBillingCompany() {
            this.selectBillingCompany(null);
            this.removeBillingCompanyModal = false;
        },
        toggleEditBillingCompany() {
            this.editingBillingCompany = !this.editingBillingCompany;
            this.companySearchQuery = '';
            this.companies = [];
        },
        selectBillingCompany(company) {
            const params = {
                billing_company: company && company.id ? {id: company.id} : null,
            };
            axios.put(`/api/superadmin/companies/${this.company.id}`, params).then(() => {
                this.$toastr.s(`Billing company ${company ? 'added' : 'removed'} successfully!`);
                this.$store.commit('editCompany', {billing_company: company});
                this.editingBillingCompany = false;
                this.companySearchQuery = '';
            }).catch(error => {
                this.$toastr.e(error.response.data.message);
            });
        },
        showDeleteCompanyConfirmation() {
            this.$root.$emit('bv::show::modal', 'confirm_admin_delete_company_modal');
        },
        editStatus() {
            this.edit_status = !this.edit_status;
        },
        getCompanyAction() {
            return this.$store.dispatch('fetchCompany', this.$route.params.company_id);
        },
        getUserId() {
            axios.get('/api/profile').then(res => {
                this.user_id = res.data.id;
                this.user_role = res.data.role;
            });
        },
        searchCompanies(e) {
            const query = e.target.value;
            this.companySearchQuery = query;

            if (!query || query === '') {
                this.companies = [];
                return;
            }

            const params = {
                search: query,
                page: 1,
                perPage: 5,
            };
            axios.get('/api/superadmin/companies', {params}).then(({data}) => {
                if(data && data.data) {
                    this.companies = data.data;
                }
            });
        },
    }
};
</script>

<style>
.edit-title {
    cursor: pointer !important;
}
</style>
