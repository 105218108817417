<template>
    <aside class="left-sidebar">
        <div class="nav-text-box align-items-center d-sm-none">
            <span><img src="/assets/img/fooddocs_logo_green_icon_40px.png" /></span>
            <a class="nav-lock waves-effect waves-dark ml-auto hidden-md-down" href="javascript:void(0)">
                <i class="mdi mdi-toggle-switch"></i>
            </a>
            <a class="nav-toggler waves-effect waves-dark ml-auto hidden-sm-up" href="javascript:void(0)">
                <i class="ti-close"></i>
            </a>
        </div>
        <div class="scroll-sidebar">
            <nav class="sidebar-nav">
                <ul id="sidebarnav">
                    <router-link :to="{ name: 'dashboard' }" tag="li" active-class="selected" exact>
                        <a class="waves-effect waves-dark" aria-expanded="false">
                            <i class="icon-home"></i><span class="hide-menu">Dashboard</span>
                        </a>
                    </router-link>
                    <router-link
                        v-if="userRole == 'admin' || userRole == 'superadmin' || technolog"
                        :to="{ name: 'companies' }"
                        tag="li"
                        active-class="selected"
                        exact
                    >
                        <a class="waves-effect waves-dark" aria-expanded="false">
                            <i class="fa fa-building-o"></i><span class="hide-menu">Companies</span>
                        </a>
                    </router-link>
                    <router-link
                        v-if="userRole == 'admin' || userRole == 'superadmin'"
                        :to="{ name: 'smartfilter.ba.pp' }"
                        tag="li"
                        active-class="selected"
                        exact
                    >
                        <a class="waves-effect waves-dark" aria-expanded="false">
                            <i class="icon-settings"></i><span class="hide-menu">Smart Filtering</span>
                        </a>
                    </router-link>
                    <router-link
                        v-if="userRole == 'admin' || userRole == 'superadmin'"
                        :to="{ name: 'fsp_conditions' }"
                        tag="li"
                        active-class="selected"
                        exact
                    >
                        <a class="waves-effect waves-dark" aria-expanded="false">
                            <i class="icon-question"></i><span class="hide-menu">Conditions</span>
                        </a>
                    </router-link>
                    <router-link
                        v-if="userRole == 'admin' || userRole == 'superadmin'"
                        :to="{ name: 'config.config' }"
                        tag="li"
                        active-class="selected"
                        exact
                    >
                        <a class="waves-effect waves-dark" aria-expanded="false">
                            <i class="icon-wrench"></i><span class="hide-menu">Config</span>
                        </a>
                    </router-link>
                    <router-link
                        v-if="userRole == 'admin' || userRole == 'superadmin'"
                        :to="{ name: 'users' }"
                        tag="li"
                        active-class="selected"
                        exact
                    >
                        <a class="waves-effect waves-dark" aria-expanded="false">
                            <i class="icon-people"></i><span class="hide-menu">Users</span>
                        </a>
                    </router-link>
                    <router-link
                        v-if="userRole == 'admin' || userRole == 'superadmin'"
                        :to="{ name: 'pm.changelog' }"
                        tag="li"
                        active-class="selected"
                        exact
                    >
                        <a class="waves-effect waves-dark" aria-expanded="false">
                            <i class="ti-layout"></i><span class="hide-menu">CMS</span>
                        </a>
                    </router-link>
                    <router-link
                        v-if="userRole == 'admin' || userRole == 'superadmin'"
                        :to="{ name: 'product-groups' }"
                        tag="li"
                        active-class="selected"
                        exact
                    >
                        <a class="waves-effect waves-dark" aria-expanded="false">
                            <i class="icon-chemistry"></i><span class="hide-menu">Lab Analysis</span>
                        </a>
                    </router-link>
                    <router-link
                        v-if="userRole == 'admin' || userRole == 'superadmin' || translator"
                        :to="{ name: 'trans.conditions' }"
                        tag="li"
                        active-class="selected"
                        exact
                    >
                        <a class="waves-effect waves-dark" aria-expanded="false">
                            <i class="ti-list-ol"></i><span class="hide-menu">Translations</span>
                        </a>
                    </router-link>
                </ul>
            </nav>
        </div>
    </aside>
</template>

<script>
export default {
    computed: {
        userRole() {
            return this.$store.getters.getUserRole;
        },
        technolog() {
            return !!(this.userRole && this.userRole.includes('technolog'));

        },
        translator() {
            return !!(this.userRole && this.userRole.includes('translator'));

        }
    }
};
</script>

<style>
#sidebarnav {
    display: flex !important;
}
.left-sidebar {
    width: 100% !important;
    height: auto !important;
    position: relative !important;
    padding-top: 0 !important;
    z-index: 45 !important;
}
.sidebar-nav>ul>li.selected>a {
    color: #55ce63 !important;
}
.sidebar-nav {
    padding: 0 !important;
}
</style>