<template>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title hidden-xs-down">Copy Production Process</h4>
            <div class="row">
                <div class="col-3">
                    <div class="form-group">
                        <label for="name">Name *</label>
                        <input
                            id="name"
                            v-model="form.name"
                            v-validate="'required'"
                            type="text"
                            data-vv-name="name"
                            data-vv-as="Name"
                            :disabled="loading"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.has('name')
                            }"
                        />
                        <span v-show="errors.has('name')" class="invalid-feedback" role="alert">
                            <strong>{{ errors.first('name') }}</strong>
                        </span>
                    </div>
                </div>
            </div>
            <button type="button" class="btn btn-success" :disabled="loading" @click="store">Save</button>
            <app-loader v-if="loading" line-fg-color="#5ec58c" message="Loading..." size="small" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            loading: false,
            form: {}
        };
    },
    methods: {
        async store() {
            this.loading = true;

            const isValid = await this.$validator.validate();
            if(!isValid){
                this.loading = false;
                return;
            }
            try {
                await axios.post(`/api/superadmin/production-processes/${this.$route.params.id}/clone`, this.form);
                this.$toastr.i('New process added successfully!');
                this.$router.push({ name: 'smartfilter.pp' });
            } catch (e){
                console.error(e);
                this.$toastr.e(e.response.data.message);
                this.loading = false;
            }
        }
    }
};
</script>
