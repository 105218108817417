<template>
    <div>
        <div>Hazard type</div>
        <v-select
            v-model="hazard_estimation.type"
            :options="hazardEstimationsTypes"
            placeholder="- choose -"
            label="name"
        >
            <span slot="no-options">Nothing found</span>
            <template slot="option" slot-scope="option">
                {{ customLabel(option.name, option.count) }}
            </template>
            <template slot="selected-option" slot-scope="option">
                {{ customLabel(option.name, option.count) }}
            </template>
        </v-select>
        <div>Hazard probability</div>
        <v-select
            v-model="hazard_estimation.probability"
            :options="hazardEstimationsProbabilities"
            label="name"
            placeholder="- choose -"
        >
            <span slot="no-options">Nothing found</span>
            <template slot="option" slot-scope="option">
                {{ customLabel(option.name, option.count) }}
            </template>
            <template slot="selected-option" slot-scope="option">
                {{ customLabel(option.name, option.count) }}
            </template>
        </v-select>
        <div>Hazard severity</div>
        <v-select
            v-model="hazard_estimation.severity"
            :options="hazardEstimationsSeverities"
            label="name"
            placeholder="- choose -"
        >
            <span slot="no-options">Nothing found</span>
            <template slot="option" slot-scope="option">
                {{ customLabel(option.name, option.count) }}
            </template>
            <template slot="selected-option" slot-scope="option">
                {{ customLabel(option.name, option.count) }}
            </template>
        </v-select>
        <div>Hazard prevention</div>
        <v-select
            v-model="hazard_estimation.prevention"
            :options="hazardEstimationsPreventions"
            label="name"
            placeholder="- choose -"
        >
            <span slot="no-options">Nothing found</span>
            <template slot="option" slot-scope="option">
                {{ customLabel(option.name, option.count) }}
            </template>
            <template slot="selected-option" slot-scope="option">
                {{ customLabel(option.name, option.count) }}
            </template>
        </v-select>
        <button class="btn btn-success" @click="saveHazardEstimation">Save</button>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: 'HazardEstimationDropdown',
    props: {
        row: {
            type: Object,
            required: true,
        },
        selectedGroupId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            form: {},
            id: '',
            hazardEstimationsTypes: [],
            hazardEstimationsProbabilities: [],
            hazardEstimationsSeverities: [],
            hazardEstimationsPreventions: [],
            estimations: [],
            hazard_estimation: {
                type: null,
                probability: null,
                severity: null,
                prevention: null,
            },
        };
    },
    created() {
        const currentGroupHazardEstimation = this.row.groups.find(item => item?.id === this.selectedGroupId)?.hazard_estimation;
        axios.get(`/api/classifiers/fsp-hazard-types`, {params: {fsp_section_group_id: this.selectedGroupId}}).then(res => {
            this.hazardEstimationsTypes = res.data;
            if(currentGroupHazardEstimation != null){
                this.hazard_estimation.type = currentGroupHazardEstimation?.type;
                this.hazard_estimation.type.count = this.hazardEstimationsTypes.find(item => item.id === this.hazard_estimation.type.id).count;
            }
        }).catch(error => {
            this.load_error = true;
            console.error(error);
        });
        axios.get(`/api/classifiers/fsp-hazard-probabilities`, {params: {fsp_section_group_id: this.selectedGroupId}}).then(res => {
            this.hazardEstimationsProbabilities = res.data;
            if(currentGroupHazardEstimation != null){
                this.hazard_estimation.probability = currentGroupHazardEstimation?.probability;
                this.hazard_estimation.probability.count = this.hazardEstimationsProbabilities.find(item => item.id === this.hazard_estimation.probability.id).count;
            }
        }).catch(error => {
            this.load_error = true;
            console.error(error);
        });
        axios.get(`/api/classifiers/fsp-hazard-severities`, {params: {fsp_section_group_id: this.selectedGroupId}}).then(res => {
            this.hazardEstimationsSeverities = res.data;
            if(currentGroupHazardEstimation != null){
                this.hazard_estimation.severity = currentGroupHazardEstimation?.severity;
                this.hazard_estimation.severity.count = this.hazardEstimationsSeverities.find(item => item.id === this.hazard_estimation.severity.id).count;
            }
        }).catch(error => {
            this.load_error = true;
            console.error(error);
        });
        axios.get(`/api/classifiers/fsp-hazard-preventions`, {params: {fsp_section_group_id: this.selectedGroupId}}).then(res => {
            this.hazardEstimationsPreventions = res.data;
            if(currentGroupHazardEstimation != null){
                this.hazard_estimation.prevention = currentGroupHazardEstimation?.prevention;
                this.hazard_estimation.prevention.count = this.hazardEstimationsPreventions.find(item => item.id === this.hazard_estimation.prevention.id).count;
            }
        }).catch(error => {
            this.load_error = true;
            console.error(error);
        });
        this.hazard_estimation.probability = currentGroupHazardEstimation?.probability;
        this.hazard_estimation.severity = currentGroupHazardEstimation?.severity;
        this.hazard_estimation.prevention = currentGroupHazardEstimation?.prevention;
    },
    methods: {
        customLabel(name, count) {
            return name + ' (' + count + ')';
        },
        saveHazardEstimation() {
            const rowId = this.row && this.row.row && this.row.row.id;
            axios.put(`/api/superadmin/fsp-table-row-groups/${this.selectedGroupId}`, {
                container_fsp_section_id: rowId,
                hazard_estimation: this.hazard_estimation,
            }
            ).catch(error => console.error(error));
            this.row.groups.find(item => item?.id === this.selectedGroupId).hazard_estimation = this.hazard_estimation;
        },
    }

};
</script>
<style>
.btn {
    float: right;
    margin-top: 1rem;
}
</style>
