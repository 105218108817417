<template>
    <tr>
        <td>{{ index + 1 }}</td>
        <td>{{ table.process_name || '- not stored -' }}</td>
        <td>{{ table.cnt || 'null' }}</td>
        <td>
            <router-link
                :to="{
                    name: 'fsp_conditions.flow.chart.defaults.edit',
                    params: {
                        tableName: table.process_name,
                        countryId,
                        countryName,
                    },
                }"
                tag="a"
            >
                Details
            </router-link>
        </td>
    </tr>
</template>
<script>
export default {
    props: {
        table: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        countryId: {
            type: Number,
            required: false,
        },
        countryName: {
            type: String,
            required: false,
        }
    }
};
</script>
