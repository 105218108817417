<template>
    <div class="page-wrapper">
        <div class="container-fluid">
            <div class="row page-titles hidden-sm-up">
                <div class="col-md-5 align-self-center">
                    <h4 class="text-themecolor">Users</h4>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title hidden-xs-down">Users</h4>
                    <PaginationPanel search-term="searchText" :total-rows="total" @search="search" />
                    <app-loader v-if="loading" line-fg-color="#5ec58c" message="Loading..." size="small" />
                    <div v-if="!loading && users.length > 0" class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th class="pointer" @click="orderBy('country.name')">
                                        Country
                                        <span
                                            v-html="order_field == 'country.name' ? active_sort_icon : sort_icon"
                                        ></span>
                                    </th>
                                    <th>Lang</th>
                                    <th class="pointer" @click="orderBy('firstname')">
                                        First Name
                                        <span v-html="order_field == 'firstname' ? active_sort_icon : sort_icon"></span>
                                    </th>
                                    <th class="pointer" @click="orderBy('lastname')">
                                        Last Name
                                        <span v-html="order_field == 'lastname' ? active_sort_icon : sort_icon"></span>
                                    </th>
                                    <th class="pointer" @click="orderBy('username')">
                                        Email
                                        <span v-html="order_field == 'username' ? active_sort_icon : sort_icon"></span>
                                    </th>
                                    <th>Phone</th>
                                    <th class="pointer" @click="orderBy('usedCoupons')">
                                        Used Coupons
                                        <span
                                            v-html="order_field == 'usedCoupons' ? active_sort_icon : sort_icon"
                                        ></span>
                                    </th>
                                    <th class="pointer" @click="orderBy('superadmin')">
                                        Role
                                        <span
                                            v-html="order_field == 'superadmin' ? active_sort_icon : sort_icon"
                                        ></span>
                                    </th>
                                    <th class="pointer" @click="orderBy('account_type.name')">
                                        Account Type
                                        <span
                                            v-html="order_field == 'account_type.name' ? active_sort_icon : sort_icon"
                                        ></span>
                                    </th>
                                    <th class="pointer" @click="orderBy('created_at')">
                                        Created
                                        <span
                                            v-html="order_field == 'created_at' ? active_sort_icon : sort_icon"
                                        ></span>
                                    </th>
                                    <th>
                                        Admin access countries
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <app-user
                                    v-for="(user, index) in orderedUsers"
                                    :key="user.id"
                                    :index="index"
                                    :user="user"
                                    :country-list="countries"
                                    :account-types="accountTypes"
                                    @showUserLogins="showUserLogins"
                                    @deleteUser="(id) => openDeleteUserModal(id)"
                                    @openPermissionsModal="(id) => openPermissionsModal(id)"
                                    @openPasswordModal="(id) => openPasswordModal(user)"
                                ></app-user>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <UserLoginsModal ref="userLoginsModal"></UserLoginsModal>

        <ConfirmDelete v-model="deleteUserModalOpen" @confirm="deleteUser">
            <template #header>
                Delete user
            </template>
            <template #body>
                Are you sure you want to delete user?
            </template>
        </ConfirmDelete>
        <UserPermissionsModal v-model="editUserId"></UserPermissionsModal>
        <UserPasswordModal v-model="changePasswordUser"></UserPasswordModal>
    </div>
</template>

<script>
import User from './UserTableRow';
import UserPermissionsModal from './UserPermissionsModal';
import PaginationPanel from '../common/PaginationPanel';
import ConfirmDelete from '../modals/ConfirmDelete';
import UserLoginsModal from './UserLoginsModal';
import axios from 'axios';
import _ from 'lodash';
import UserPasswordModal from '@/components/users/UserPasswordModal';

export default {
    components: {
        UserPasswordModal,
        ConfirmDelete,
        PaginationPanel,
        UserPermissionsModal,
        UserLoginsModal,
        appUser: User,
    },
    data() {
        return {
            accountTypes: [],
            users: [],
            filter_term: '',
            order_field: 'created_at',
            order_dir: 'desc',
            sort_icon: '<i class="fa fa-sort"></i>',
            selected_countries: [],
            countries: [],
            total: 0,
            loading: true,
            deleteUserId: null,
            editUserId: null,
            changePasswordUser: null,
        };
    },
    computed: {
        deleteUserModalOpen: {
            get() {
                return !!this.deleteUserId;
            },
            set(val) {
                if (val === false) {
                    this.deleteUserId = null;
                }
            }
        },
        orderedUsers() {
            if (this.order_field == 'created_at') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                return this.users.sort((a, b) => {
                    let d1 = new Date(a.created_at);
                    let d2 = new Date(b.created_at);
                    if (this.order_dir == 'asc') {
                        return d1 - d2;
                    } else {
                        return d2 - d1;
                    }
                });
            } else {
                return _.orderBy(this.users, this.order_field, this.order_dir);
            }
        },
        active_sort_icon() {
            if (this.order_dir == 'desc') {
                return '<i class="fa fa-sort-down"></i>';
            } else {
                return '<i class="fa fa-sort-up"></i>';
            }
        },
    },
    created() {
        this.getCountries();
        this.getAccountTypes();
    },
    methods: {
        deleteUser() {
            if(this.deleteUserId == null) {
                this.$toastr.e('Error: user id not found!');
            } else {
                axios.delete(`/api/superadmin/users/${this.deleteUserId}`).then(() => {
                    this.$toastr.i('User deleted successfully!');
                }).catch(() => {
                    this.$toastr.e('Something went wrong with deleting user!');
                });
            }
            this.users = this.users.filter(user => user.id !== +this.deleteUserId);
            this.deleteUserId = null;
        },
        openDeleteUserModal(id) {
            this.deleteUserId = id;
        },
        openPermissionsModal(id) {
            this.editUserId = id;
        },
        openPasswordModal(user) {
            this.changePasswordUser = user;
        },
        search(params) {
            this.loading = true;
            axios
                .get('/api/superadmin/users', {params})
                .then(({data}) => {
                    if(data && data.data){
                        this.users = data && data.data;
                        this.total = data && data.total || 0;
                    } else {
                        this.users = [];
                        this.total = 0;
                    }
                })
                .catch(error => {
                    if (error.response.status === 403) {
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('expires_in');
                        window.location.replace('/sisemine');
                    }
                }).finally(() => {
                    this.loading = false;
                });
        },
        orderBy(field) {
            this.order_field = field;
            if (this.order_dir == 'desc') {
                this.order_dir = 'asc';
            } else {
                this.order_dir = 'desc';
            }
        },
        getCountries() {
            axios
                .get('/api/classifiers/countries?simple-format=true')
                .then(res => {
                    let arr = [];
                    for (const item of res.data) {
                        arr = arr.concat(item.countries);
                    }
                    arr.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
                    this.countries = arr;
                })
                .catch(error => console.error(error));
        },
        getAccountTypes() {
            axios.get('/api/classifiers/account-types').then(({data}) => {
                this.accountTypes = data;
            });
        },
        showUserLogins(logins) {
            this.$refs['userLoginsModal'].open(logins);
        }
    }
};
</script>
