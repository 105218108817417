<template>
    <div>
        <div class="row page-titles hidden-sm-up">
            <div class="col-md-5 align-self-center">
                <h4 class="text-themecolor">
                    New Business Activity Production Process
                </h4>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title hidden-xs-down">
                    New Business Activity Production Process
                </h4>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Business Activity *</label>
                            <v-select
                                v-model="form.selected_business_activity"
                                :options="business_activites"
                                label="name"
                                :on-change="businessActivityChange"
                                :class="{ 'custom-is-invalid': business_activity_has_error }"
                                placeholder="- choose -"
                            >
                                <span slot="no-options">Nothing found</span>
                            </v-select>
                            <input type="hidden" class="form-control is-invalid" />
                            <span v-if="business_activity_has_error" class="invalid-feedback" role="alert">
                                <strong>Business Activity required</strong>
                            </span>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Processes *</label>
                            <v-select
                                v-model="form.selected_processes"
                                :options="processes"
                                :close-on-select="false"
                                label="name"
                                :on-change="processChange"
                                :class="{ 'custom-is-invalid': process_has_error }"
                                placeholder="- choose -"
                                multiple
                            >
                                <span slot="no-options">Nothing found</span>
                            </v-select>
                            <input type="hidden" class="form-control is-invalid" />
                            <span v-if="process_has_error" class="invalid-feedback" role="alert">
                                <strong>Process required</strong>
                            </span>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Extra data</label>
                            <v-select
                                v-model="form.selected_extra_datas"
                                :options="extra_data"
                                label="name"
                                :on-change="extraDataChange"
                                placeholder="- choose -"
                            >
                                <span slot="no-options">Nothing found</span>
                            </v-select>
                        </div>
                    </div>
                </div>
                <button type="button" class="btn btn-success" @click="store">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            processes: [],
            extra_data: [],
            business_activites: [],
            business_activity_has_error: true,
            process_has_error: true,
            form: {
                processes: [],
                extra_data: []
            }
        };
    },
    created() {
        this.fetchProcesses();
        this.fetchExtraData();
        this.fetchBusinessActivites();
    },
    methods: {
        store() {
            axios
                .post('/api/superadmin/business-activity/production-process/create', this.form)
                .then(res => {
                    this.$toastr.i(res.data.message);
                    this.$router.push({ name: 'smartfilter.ba.pp' });
                })
                .catch(error => console.error(error));
        },
        fetchProcesses() {
            axios
                .get('/api/classifiers/production-processes')
                .then(res => {
                    this.processes = res.data;
                })
                .catch(error => console.error(error));
        },
        fetchExtraData() {
            axios
                .get('/api/classifiers/extra-data')
                .then(res => {
                    this.extra_data = res.data;
                })
                .catch(error => console.error(error));
        },
        fetchBusinessActivites() {
            axios
                .get('/api/classifiers/business-activities')
                .then(res => {
                    this.business_activites = res.data;
                })
                .catch(error => console.error(error));
        },
        processChange(obj) {
            this.process_has_error = false;
            if (obj == null) {
                this.process_has_error = true;
                this.form.processes = null;
                return null;
            }
            let pp = [];
            obj.forEach(prodProcess => {
                pp.push(prodProcess.id);
            });
            return (this.form.processes = pp);
        },
        extraDataChange(obj) {
            return (this.form.extra_data = obj.id);
        },
        businessActivityChange(obj) {
            this.business_activity_has_error = false;
            if (obj == null) {
                this.business_activity_has_error = true;
                this.form.business_activity = null;
                return null;
            }
            return (this.form.business_activity = obj.id);
        }
    }
};
</script>
