<template>
    <tr>
        <td>{{ condition.id }}</td>
        <td v-if="businessActivities.length > 0">
            <span v-for="(val, i) in businessActivities" :key="i">
                {{ val.name }}<span v-if="i !== businessActivities.length - 1">,</span>
            </span>
        </td>
        <td>
            <router-link
                :to="{
                    name: 'fsp_conditions.show',
                    params: { fsp_condition_id: condition.id }
                }"
                tag="a"
            >
                <span v-html="decipherAPIString(condition)"></span>
            </router-link>
        </td>
        <td>
            {{ condition.fsp_table_name }}
        </td>
        <td>
            <span v-for="(tag, i) in condition.tags" :key="i">
                <span :key="i" v-html="tag && tag.tag && tag.tag.name ? `${tag.tag.name}${i !== condition.tags.length - 1 ? ',' : ''}`: ''"></span>
            </span>
        </td>
        <td>{{ condition.fsp_document_type && condition.fsp_document_type.name ? condition.fsp_document_type.name : 'not found' }}</td>
        <td>
            <span class="pull-right">
                <router-link
                    :to="{
                        name: 'fsp_conditions.edit',
                        params: { fsp_condition_id: condition.id }
                    }"
                    tag="a"
                >
                    Edit
                </router-link>
                |
                <a href="javascript:void(0)" @click="destroy(condition.id)">Delete</a>
            </span>
        </td>
    </tr>
</template>

<script>
import axios from 'axios';

export default {
    props: ['condition', 'index', 'business_activities', 'langs', 'decipherAPIString'],
    data() {
        return {
            needs_translation: false
        };
    },
    computed: {
        businessActivities() {
            return this.condition && this.condition.business_activities ? this.condition.business_activities.filter(activity => activity.business_activity).map(activity => activity.business_activity) : [];
        },
        conditionName() {
            return this.decipherAPIString(this.condition);
        }
    },
    methods: {
        destroy(id) {
            if (!window.confirm('Delete?')) {
                return false;
            }

            axios
                .delete('/api/superadmin/food-safety-plan-conditions/ ' + id)
                .then(res => {
                    if(res && res.data && res.data.message){
                        this.$toastr.i(res.data.message);
                    } else {
                        this.$toastr.i('Element ' + id + ' deleted successfully!');
                    }
                    this.$emit('removeCondition', this.index);
                })
                .catch(error => {
                    this.$toastr.e(error.response.data.message);
                });
        }
    }
};
</script>
